import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import type {
  FeedbackType,
  ProgressStatus,
} from '~/models/types/components/chat/Chat'

export const getFeedbackIcon = (type: FeedbackType) => {
  switch (type) {
    case 'info':
      return <InfoIcon color="info" />
    case 'success':
      return <CheckCircleIcon color="success" />
    case 'warning':
      return <WarningIcon color="warning" />
    case 'error':
      return <ErrorIcon color="error" />
  }
}

export const getProgressColor = (status: ProgressStatus) => {
  switch (status) {
    case 'completed':
      return 'success'
    case 'failed':
      return 'error'
    case 'in_progress':
      return 'primary'
    default:
      return 'inherit'
  }
}
