import type {
  FileStructure,
  FolderOrFileStructure,
} from '~/services/GenerationStrategy.types'

/**
 * Find a tree node within the "data" based on the
 * provided "target ID".
 * @param data The raw tree data.
 * @param targetId The target ID.
 */
export const findTreeNodeById = (
  data?: FolderOrFileStructure[],
  targetId?: GUID,
): FolderOrFileStructure | null => {
  if (!Array.isArray(data) || !targetId) return null

  return data.reduce(
    (found: FolderOrFileStructure | null, item: FolderOrFileStructure) => {
      // If we already found the item, return it.
      if (found) return found

      // If current item matches, return it.
      if (item.id === targetId) return item

      // If item has children, recursively search them.
      if (item.children) return findTreeNodeById(item.children, targetId)

      return null
    },
    null,
  )
}

/**
 * Find a tree node within the "data" based on the
 * provided "file path".
 * @param data The raw tree data.
 * @param filePath The target file path.
 */
export const findTreeNodeByFilePath = (
  data?: FolderOrFileStructure[],
  filePath?: string,
): FolderOrFileStructure | null => {
  if (!Array.isArray(data) || !filePath) return null

  return data.reduce(
    (found: FolderOrFileStructure | null, item: FolderOrFileStructure) => {
      // If we already found the item, return it.
      if (found) return found

      // If current item matches, return it.
      if ((item as FileStructure)?.file?.filePath === filePath) return item

      // If item has children, recursively search them.
      if (item.children) return findTreeNodeByFilePath(item.children, filePath)

      return null
    },
    null,
  )
}
