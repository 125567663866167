import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

export type UseRepositoryGetBranchesProps = {
  /** The ID of the aggregate to get the branches. */
  aggregateId: GUID | null
}

/**
 * Hook that implements react query
 * to get repository branches.
 */
export const useRepositoryGetBranches = (
  props: UseRepositoryGetBranchesProps,
) => {
  const { aggregateId } = props

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    queryKey: ['repositoryBranches', { aggregateId }],
    queryFn: async () => {
      invariant(aggregateId, ERROR_MESSAGES.aggregateId)

      const url = DevelopmentAggregateAPI.RepositoryGetBranches
      return serviceDevelopmentAggregate.get(`${url}/${aggregateId}`)
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
