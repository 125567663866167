/**
 * Provides the proper text indicating what is going
 * to be deleted:
 * - folder
 * - file
 * - files
 *
 * @param itemsCount The number of items to be deleted.
 * @param isFile Indicates if the item(s) to delete is a file.
 * @param isFolder Indicates if the item to delete is a folder.
 * @returns A text indicating the items to be deleted.
 */
export const getFolderOrFileTxt = (
  itemsCount?: number,
  isFile?: boolean,
  isFolder?: boolean,
) => {
  if (isFolder) return 'folder'

  if (isFile) {
    if (typeof itemsCount === 'number' && itemsCount > 1) return 'files'
    return 'file'
  }

  return ''
}
