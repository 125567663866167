import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BusinessIcon from '@mui/icons-material/Business'
import { Avatar, Box } from '@mui/material'
import { format } from 'date-fns'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage as ChatMessageType } from '~/models/types/components/chat/Chat'
import type { ChatBaseProps } from '~/models/types/components/chat/ChatBaseProps'
import { BotResponse } from '../BotResponse/BotResponse'
import { MessagePaper } from './ChatMessage.styles'

type ChatMessageProps = Pick<ChatBaseProps, 'onOptionSelect'> & {
  message: ChatMessageType
}

/**
 *
 */
export const ChatMessage = (props: ChatMessageProps) => {
  const { message, onOptionSelect } = props

  const isAssistant = message.type === MessageTypeEnum.ASSISTANT

  return (
    <div
      className="flex w-full max-w-full gap-3"
      style={{ justifyContent: isAssistant ? 'flex-start' : 'flex-end' }}
    >
      {isAssistant && (
        <Avatar className="h-10 w-10 bg-transparent text-primary">
          <BusinessIcon fontSize="large" />
        </Avatar>
      )}

      <div
        className="flex max-w-[85%] flex-col gap-1"
        style={{ alignItems: isAssistant ? 'flex-start' : 'flex-end' }}
      >
        <MessagePaper
          className="rounded-md p-4"
          elevation={0}
          messageType={message.type}
        >
          {typeof message.content === 'string' ? (
            <Box
              sx={{
                fontSize: '0.875rem',
                whiteSpace: 'pre-wrap',
                lineHeight: 1.6,
              }}
            >
              {message.content}
            </Box>
          ) : (
            <BotResponse
              content={message.content}
              onOptionSelect={onOptionSelect}
            />
          )}
        </MessagePaper>

        <div className="mt-2 text-xs text-secondary">
          {format(message.timestamp, 'HH:mm')}
        </div>
      </div>

      {!isAssistant && (
        <Avatar className="h-10 w-10 bg-transparent text-primary">
          <AccountCircleIcon fontSize="large" />
        </Avatar>
      )}
    </div>
  )
}
