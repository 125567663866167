import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import { LoadRepositoryFormEnum } from '~/models/enums/forms/LoadRepositoryFormEnum'
import type { CodeGenerationStrategyApiProps } from '~/models/types/api/codeGenerationStrategy/CodeGenerationStrategyApiProps'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadLoadGitRepository } from '~/services/GenerationStrategy.types'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to load a git repository.
 */
export const useLoadGitRepository = (
  props: Pick<CodeGenerationStrategyApiProps, 'generationStrategyId'>,
) => {
  const { generationStrategyId } = props

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationKey: [QueryKeysEnum.LOAD_GIT_REPOSITORY],
    mutationFn: (fieldValues: FieldValues) => {
      const repositoryUrl = fieldValues[
        LoadRepositoryFormEnum.REPOSITORY_URL
      ] as string | undefined
      const authenticationToken = fieldValues[
        LoadRepositoryFormEnum.AUTHENTICATION_TOKEN
      ] as string | undefined

      invariant(repositoryUrl, ERROR_MESSAGES.repositoryUrl)
      invariant(authenticationToken, ERROR_MESSAGES.authenticationToken)
      invariant(generationStrategyId, ERROR_MESSAGES.generationStrategyId)

      const url = GenerationStrategyAPI.LoadGitRepository

      const postData: PayloadLoadGitRepository = {
        repositoryUrl,
        authenticationToken,
        generationStrategyId,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeysEnum.GENERATION_STRATEGY_BY_ID,
          generationStrategyId,
        ],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
