import { useMutation } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { PublishArcMapResourceFormEnum } from '~/models/enums/forms/PublishArcMapResourceFormEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import type { PayloadPublishArcMapResource } from '~/services/Development.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'
import {
  getFormResourceIdParam,
  getPublishEndpoint,
} from './usePublishArcMapResource.utils'

/**
 * Hook that implements react query `mutation`
 * to publish an ArcMap resource.
 * The resource could be:
 * - Aggregate, Command, Reaction, Type, or Query.
 *
 * Note: the "read model" is not supported yet,
 * just add it in case it is necessary.
 */
export const usePublishArcMapResource = () => {
  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const {
        aggregateId,
        branch,
        commitMessage,
        developerId,
        ecosystemName,
        resourceId,
        resourceType,
        strategyType,
      } = fieldValues

      invariant(aggregateId, ERROR_MESSAGES.aggregateId)

      const postData: PayloadPublishArcMapResource = {
        [PublishArcMapResourceFormEnum.AGGREGATE_ID]: aggregateId,
        [PublishArcMapResourceFormEnum.BRANCH]: branch,
        [PublishArcMapResourceFormEnum.COMMIT_MESSAGE]: commitMessage,
        [PublishArcMapResourceFormEnum.DEVELOPER_ID]: developerId,
        [PublishArcMapResourceFormEnum.ECOSYSTEM_NAME]: ecosystemName,
        [PublishArcMapResourceFormEnum.STRATEGY_TYPE]: strategyType,
        ...getFormResourceIdParam(resourceType, resourceId),
      }

      const publishEndpoint = getPublishEndpoint(resourceType)
      const url = publishEndpoint
        ? DevelopmentAggregateAPI[publishEndpoint]
        : ''

      return serviceDevelopmentAggregate.post(url, postData)
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
