import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

export enum ITEM_TYPE {
  file = 'file',
  folder = 'folder',
}

type GetComputedItemsToDeleteReturn = {
  /** The items to be deleted. */
  items: FolderOrFileStructure[]
  /** The type of the item(s) to be deleted. */
  type: ITEM_TYPE
}

/**
 * Computes an array of files or a single folder based on input data.
 * Conditions.
 * - In case files and folders are provided, just files will be returned;
 * - In case multiple folders is provided, only the first one will be returned.
 *
 * @param items - Array of file and/or folder objects.
 * @returns Object with type ('file' or 'folder') and computed items.
 *
 * @example
 * // Files only.
 * computeItems([{ fileId: '1' }, { fileId: '2' }])
 * // Returns { type: 'file', items: [{ fileId: '1' }, { fileId: '2' }] }
 *
 * // Mixed files and folders - returns only files.
 * computeItems([{ fileId: '1' }, { children: [] }])
 * // Returns { type: 'file', items: [{ fileId: '1' }] }
 *
 * // Folder only - returns first folder.
 * computeItems([{ children: ['1', '2'] }, { children: ['3'] }])
 * // Returns { type: 'folder', items: ['1', '2'] }
 */
export const getComputedItemsToDelete = (
  items?: FolderOrFileStructure[] | null,
): GetComputedItemsToDeleteReturn | undefined => {
  if (!items || !items?.length) return

  // Check if there are any files in the array.
  const files = items.filter((item) => 'fileId' in item)

  // If we have files, return them (ignoring any folders).
  if (files.length > 0) {
    return {
      type: ITEM_TYPE.file,
      items: files,
    }
  }

  // If no files found, check for folders
  const folders = items.filter((item) => !('fileId' in item))

  // Return first folder if any exists
  if (!!folders && folders?.length > 0) {
    return {
      type: ITEM_TYPE.folder,
      items: [folders[0] as FolderOrFileStructure],
    }
  }

  // Default return if no files or folders found.
  return
}
