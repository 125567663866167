/**
 * Returns the repository name based on the provided
 * repository URL.
 * @param url The repository URL.
 */
export const getRepoName = (url?: string) => {
  if (!url) return 'Setup Repository'

  // Remove trailing slashes and then split
  const cleanUrl = url.replace(/\/+$/, '')
  return cleanUrl.split('/').pop()
}
