import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type SyntheticEvent,
} from 'react'
import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'
import { getFolderIds } from './utils/getFolderIds'
import { getNodeData } from './utils/getNodeData'
import { getParentNodePath } from './utils/getParentNodePath'

export type UseArcMapTreeViewProps = {
  /**
   * The default selected node:
   * It can be used to set a initial selected node.
   */
  defaultSelected?: SelectedArcMapNodeInfo
  /** The files tree filtered data. */
  filteredData?: ArcMapTreeViewData[]
  /** Callback handler for  tree view node change. */
  onNodeChange?: (selectedNode: SelectedArcMapNodeInfo) => void
  /**
   * The selected node:
   * Used in case a state control happens at
   * the parent component level.
   */
  selectedNode?: SelectedArcMapNodeInfo
}

/**
 * Provides utils and state management
 * for `ArcMapTreeView` component.
 */
export const useArcMapTreeView = (props: UseArcMapTreeViewProps) => {
  const { defaultSelected, filteredData, onNodeChange, selectedNode } = props

  // States.
  // Track expanded nodes.
  const [expanded, setExpanded] = useState<string[]>([])

  // Internal state for selection.
  const [internalSelected, setInternalSelected] =
    useState<SelectedArcMapNodeInfo | null>(defaultSelected ?? null)

  // If selectedNode is provided (not undefined), we're in controlled mode.
  const isControlled = selectedNode !== undefined

  // Use selectedNode for controlled mode, internalSelected for uncontrolled.
  const selected = isControlled ? selectedNode : internalSelected

  // Lifecycle.
  useEffect(() => {
    if (!!filteredData?.length && !!selectedNode) {
      const parentPath = getParentNodePath(filteredData, selectedNode.id || '')
      if (!!parentPath) setExpanded(parentPath)
    }
  }, [])

  // Constants.
  const isTreeExpanded = useMemo(
    () => !!expanded.length,
    [filteredData, expanded],
  )

  // Methods.
  // Toggle event from `TreeView`.
  const handleTreeViewNodeToggle = (e: SyntheticEvent, nodeIds: string[]) => {
    if (e?.target instanceof SVGElement) setExpanded(nodeIds)
  }

  // Handles a single tree item toggle event.
  const handleTreeItemToggle = (nodeId: GUID) => {
    if (!!nodeId)
      setExpanded((prevState) => {
        const alreadyExpanded = prevState?.find((item) => item === nodeId)
        if (alreadyExpanded) {
          return prevState.filter((item) => item !== nodeId)
        } else {
          return [...prevState, nodeId]
        }
      })
  }

  // Toggle all folders.
  const handleToggleFoldersClick = useCallback(() => {
    setExpanded((prevState) => {
      if (prevState.length > 0) return []

      return getFolderIds(filteredData)
    })
  }, [filteredData])

  // Handles a tree item click event.
  const handleTreeItemClick = (node: ArcMapTreeViewData) => {
    const { aggregateId, gitRepositoryUrl, id, label, type } = node

    const data = getNodeData(id, label, type!, aggregateId!, gitRepositoryUrl!)

    // Update internal state only if uncontrolled.
    if (!isControlled) setInternalSelected(data)

    onNodeChange?.(data)
  }

  return {
    expanded,
    handleToggleFoldersClick,
    handleTreeItemClick,
    handleTreeItemToggle,
    handleTreeViewNodeToggle,
    isTreeExpanded,
    selected,
  }
}
