import { PanelGroup } from 'react-resizable-panels'
import type { CodePreviewBaseProps } from '~/models/types/components/codePreview/CodePreviewBaseProps'
import ResizeHandle from '../ResizeHandle'
import { FileDetailsPanel } from './FileDetailsPanel/FileDetailsPanel'
import {
  FilesTreePanel,
  type FilesTreePanelProps,
} from './FilesTreePanel/FilesTreePanel'
import { useCodePreview } from './useCodePreview'

type CodePreviewProps = Pick<
  CodePreviewBaseProps,
  'filesTreeData' | 'isLoading'
> &
  Pick<FilesTreePanelProps, 'highlightTreeItemCondition'> & {
    /** Placeholder message for file details section. */
    fileDetailsPlaceholderMsg?: CodePreviewBaseProps['placeholderMsg']
    /** Placeholder message for files tree section. */
    filesTreePlaceholderMsg?: CodePreviewBaseProps['placeholderMsg']
    /** Any content to be rendered before the files tree. */
    filesTreePreContent?: JSX.Element
  }

/**
 * This is a code preview component:
 * it renders a panel with the code files tree
 * and another panel to show the code content.
 *
 * For this component, the code edition is disable,
 * in case of need just set as enabled.
 */
const CodePreview = (props: CodePreviewProps) => {
  const {
    fileDetailsPlaceholderMsg,
    filesTreePlaceholderMsg,
    filesTreeData,
    filesTreePreContent,
    highlightTreeItemCondition,
    isLoading,
  } = props

  const { handleFileClick, selectedFile } = useCodePreview({ filesTreeData })

  return (
    <PanelGroup direction="horizontal">
      <FilesTreePanel
        defaultSelected={selectedFile}
        filesTreeData={filesTreeData}
        highlightTreeItemCondition={highlightTreeItemCondition}
        isLoading={isLoading}
        onFileClick={handleFileClick}
        placeholderMsg={filesTreePlaceholderMsg}
        preContent={filesTreePreContent}
      />

      <ResizeHandle />

      <FileDetailsPanel
        selectedFile={selectedFile}
        isLoading={isLoading}
        placeholderMsg={fileDetailsPlaceholderMsg}
      />
    </PanelGroup>
  )
}

export default CodePreview
