import { useEffect, useMemo } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useDeleteFilePath } from '~/hooks/api/codeGenerationStrategy/useDeleteFilePath'
import { useDeleteFiles } from '~/hooks/api/codeGenerationStrategy/useDeleteFiles'
import { DeleteFolderFilesFormEnum } from '~/models/enums/forms/DeleteFolderFilesFormEnum'
import type { DialogDeleteFolderFileProps } from '~/models/types/components/codeGenerationStrategy/DialogDeleteFolderFileProps'
import type { CodeGenerationStrategyDialogHook } from '~/models/types/hooks/codeGenerationStrategy/CodeGenerationStrategyDialogHook'
import {
  ITEM_TYPE,
  getComputedItemsToDelete,
} from './utils/getComputedItemsToDelete'
import { getFolderOrFileTxt } from './utils/getFolderOrFileTxt'

/**
 * Hook to support the `DialogDeleteFolderFile` component.
 */
export const useDialogDeleteFolderFile = (
  props: DialogDeleteFolderFileProps,
): CodeGenerationStrategyDialogHook => {
  const { handleCloseDialog, isDialogOpen, selectedItems } = props

  // React Router Dom.
  let { generationStrategyId } = useParams()

  // Constants.
  const computedItemsToDelete = useMemo(
    () => getComputedItemsToDelete(selectedItems),
    [selectedItems],
  )
  const { items: computedItems, type } = computedItemsToDelete || {}

  const isFile = type === ITEM_TYPE.file
  const isFolder = type === ITEM_TYPE.folder

  const folderOrFileTxt = getFolderOrFileTxt(
    computedItems?.length,
    isFile,
    isFolder,
  )

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    values: {
      ...(isFile && {
        [DeleteFolderFilesFormEnum.FILE_IDS]: selectedItems
          ?.map((items) => items.fileId)
          .filter(Boolean),
      }),
      ...(isFolder && {
        [DeleteFolderFilesFormEnum.FILE_PATH]: selectedItems?.[0]?.path,
      }),
    },
  })

  // Form submit mutation.
  const {
    error: deleteFilesError,
    isPending: isDeleteFilesPending,
    isSuccess: isDeleteFilesSuccess,
    mutate: deleteFilesMutate,
    reset: resetDeleteFilesMutation,
  } = useDeleteFiles({
    generationStrategyId,
  })

  const {
    error: deleteFilePathError,
    isPending: isDeleteFilePathPending,
    isSuccess: isDeleteFilePathSuccess,
    mutate: deleteFilePathMutate,
    reset: resetDeleteFilePathMutation,
  } = useDeleteFilePath({
    generationStrategyId,
  })

  const error = deleteFilesError || deleteFilePathError
  const isPending = isDeleteFilesPending || isDeleteFilePathPending
  const isSuccess = isDeleteFilesSuccess || isDeleteFilePathSuccess

  // Reset form states when the dialog is closed.
  useEffect(() => {
    if (!isDialogOpen) {
      resetForm?.()
      resetDeleteFilesMutation?.()
      resetDeleteFilePathMutation?.()
    }
  }, [isDialogOpen])

  // Close dialog when mutation is success.
  useEffect(() => {
    if (isSuccess) handleCloseDialog()
  }, [isSuccess])

  // Handles the form submit.
  const handleFormSubmit = (data: FieldValues) => {
    if (isFile) return deleteFilesMutate(data)
    if (isFolder) return deleteFilePathMutate(data)
  }

  // Handle the close dialog.
  const handleClose = () => {
    if (!isPending) handleCloseDialog()
  }

  return {
    computedItems,
    control,
    error,
    folderOrFileTxt,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  }
}
