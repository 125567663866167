/**
 * Default config options for a Monaco Editor.
 */
export const EDITOR_DEFAULT_OPTIONS = {
  renderSideBySide: true,
  minimap: { enabled: true },
  fontSize: 13,
  selectOnLineNumbers: true,
  roundedSelection: false,
  readOnly: true,
  cursorStyle: 'line',
  automaticLayout: true,
}
