import { useCallback, useRef, useState, type PropsWithChildren } from 'react'
import ChatBotContext, {
  type GetResponseFunction,
} from '~/contexts/ChatBotContext'
import { useToast } from '~/hooks/ui/useToast'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provider for the Chat Bot context.
 */
const ChatBotContextProvider = (props: PropsWithChildren) => {
  const { children } = props

  // States.
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)

  // Add a ref to track if handleSend is in progress
  const isHandlingSend = useRef(false)

  // Toast hook.
  const { toast } = useToast()

  // Methods.
  // This method just add a message to the messages state.
  const addMessage = useCallback((message: ChatMessage) => {
    setMessages((prev) => [...prev, message])
  }, [])

  // Resets the message state.
  const resetMessages = useCallback((initialMessage?: ChatMessage) => {
    setMessages(initialMessage ? [initialMessage] : [])
  }, [])

  // Send message to the chat bot:
  // This can be used to await for a response.
  const handleSend = async ({
    getResponse,
    message,
    type,
  }: {
    getResponse: GetResponseFunction
    message?: string
    type?: MessageTypeEnum
  }) => {
    isHandlingSend.current = true
    setIsLoading(true)

    // Add user message.
    if (type === MessageTypeEnum.USER && message) {
      const userMessage: ChatMessage = {
        id: Date.now().toString(),
        type: MessageTypeEnum.USER,
        content: { text: message },
        timestamp: new Date(),
      }
      addMessage(userMessage)
    }

    try {
      const response = await getResponse(message)

      let assistantResponse: ChatMessage

      // Generate bot response.
      if (typeof response === 'string') {
        assistantResponse = {
          id: (Date.now() + 1).toString(),
          type: MessageTypeEnum.ASSISTANT,
          content: response,
          timestamp: new Date(),
        } as ChatMessage
      } else {
        assistantResponse = response
      }

      addMessage(assistantResponse)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to process message. Please try again.',
        variant: 'destructive',
      })
    } finally {
      isHandlingSend.current = false
      setIsLoading(false)
    }
  }

  // Set loading state whenever necessary.
  const setLoadingState = useCallback((loading: boolean) => {
    setIsLoading((current) => {
      // If handleSend is in progress, don't change the state.
      if (isHandlingSend.current) return current

      // Otherwise, allow the external change.
      return loading
    })
  }, [])

  const value = {
    addMessage,
    handleSend,
    isLoading,
    messages,
    resetMessages,
    setLoadingState,
  }

  return (
    <ChatBotContext.Provider value={value}>{children}</ChatBotContext.Provider>
  )
}

export default ChatBotContextProvider
