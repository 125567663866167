import { createContext } from 'react'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

export type GetResponseFunction = (
  message?: string,
) => Promise<string | ChatMessage>

type ChatContextProps = {
  /** Add a message to the array of messages. */
  addMessage: (message: ChatMessage) => void
  /**
   * Handler to send message to the bot.
   * @param getResponse The response method (eg: reach api).
   * @param message The message sent to the bot.
   * @param type The message type (user or assistant).
   */
  handleSend: ({
    getResponse,
    message,
    type,
  }: {
    getResponse: GetResponseFunction
    message?: string
    type?: MessageTypeEnum
  }) => Promise<void>
  /** Indicates if there is a loading in progress. */
  isLoading: boolean
  /** The chat messages. */
  messages: ChatMessage[]
  /**
   * Reset the message states.
   * It will empty or reset to the provided initial messages.
   */
  resetMessages: (initialMessages: ChatMessage) => void
  /**
   * This will force the internal loading state to change
   * It can be used to show a loading in the chat
   * based on an external source (fetching etc).
   */
  setLoadingState: (loading: boolean) => void
}

/**
 * Context for the Chat bot.
 */
const ChatBotContext = createContext<ChatContextProps | undefined>(undefined)

export default ChatBotContext
