import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot generic error message.
 */
export const getChatGenericErrorMessage = (): ChatMessage => ({
  id: ChatBotMessageIds.GENERIC_ERROR + new Date(),
  type: MessageTypeEnum.ASSISTANT,
  content: {
    text: ERROR_MESSAGES.generic,
  },
  timestamp: new Date(),
})
