import { getNodeData } from '~/components/treeView/ArcMapTreeView/utils/getNodeData'
import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'

// Get the full ArcMap node.
export const getFullArcMapNode = (
  nodeId: string,
  nodes?: ArcMapTreeViewData[],
): SelectedArcMapNodeInfo | undefined => {
  if (!nodes) return

  const findNode = (items: ArcMapTreeViewData[]): ArcMapTreeViewData | null => {
    for (const item of items) {
      if (item.id === nodeId) return item
      if (item.children) {
        const found = findNode(item.children)
        if (found) return found
      }
    }
    return null
  }

  const node = findNode(nodes)
  if (node) {
    const { aggregateId, gitRepositoryUrl, id, label, type } = node
    const data = getNodeData(id, label, type!, aggregateId!, gitRepositoryUrl!)
    return data
  }

  return
}
