import { CodeSimple } from '@phosphor-icons/react'
import { Panel, PanelGroup } from 'react-resizable-panels'
import CodePreview from '~/components/CodePreview/CodePreview'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { ArcPilotPanel } from './components/ArcPilotPanel/ArcPilotPanel'
import { ButtonArcPilot } from './components/ButtonArcPilot/ButtonArcPilot'
import { ButtonPreview } from './components/ButtonPreview/ButtonPreview'
import { Repository } from './components/Repository/Repository'
import { SelectAiBlueprint } from './components/SelectAiBlueprint/SelectAiBlueprint'
import { SelectArcMap } from './components/SelectArcMap/SelectArcMap'
import { useAiBlueprintPreview } from './hooks/useAiBlueprintPreview'
import { useManageChatBot } from './hooks/useManageChatBot'

/**
 * AI Blueprint Preview page/component.
 */
export const AiBlueprintPreview = () => {
  // Hooks.
  const {
    control,
    disabledPreviewBtn,
    gitRepoUrlBtnRef,
    handleSubmit,
    hasPreviewChanges,
    highlightTreeItem,
    isPreviewCodePending,
    onSubmitPreviewCode,
    placeholderMsg,
    previewData,
    publishBtnRef,
    selectAiBlueprintBtnRef,
    selectArcMapBtnRef,
    selectBranchBtnRef,
    setFormValue,
    wasEverSubmitted,
    watch,
  } = useAiBlueprintPreview()

  const {
    handleChatOptionSelect,
    handleChatSend,
    handleCloseChat,
    handleToggleChat,
    openChat,
  } = useManageChatBot({
    gitRepoUrlBtnRef,
    hasPreviewChanges,
    isOuterLoading: isPreviewCodePending,
    previewData,
    publishBtnRef,
    selectAiBlueprintBtnRef,
    selectArcMapBtnRef,
    selectBranchBtnRef,
    watch,
  })

  return (
    <div className="h-[calc(100vh-65px)]">
      <PanelGroup direction="horizontal">
        <Panel className="relative grid grid-rows-[auto_1fr] bg-white p-4">
          <header className="mb-4 flex items-center gap-2">
            <CodeSimple size={24} />

            <h1 className="m-0 text-2xl font-bold text-highlight-200">
              CODE PREVIEW
            </h1>

            <form
              className="flex flex-1 items-center gap-2"
              onSubmit={handleSubmit(onSubmitPreviewCode)}
            >
              <SelectAiBlueprint
                buttonRef={selectAiBlueprintBtnRef}
                control={control}
                watch={watch}
              />

              <SelectArcMap
                buttonRef={selectArcMapBtnRef}
                control={control}
                watch={watch}
              />

              <ButtonPreview
                disabled={disabledPreviewBtn || isPreviewCodePending}
                wasEverSubmitted={wasEverSubmitted}
              />
            </form>

            <Repository
              gitRepoUrlBtnRef={gitRepoUrlBtnRef}
              hasPreviewChanges={hasPreviewChanges}
              publishBtnRef={publishBtnRef}
              selectBranchBtnRef={selectBranchBtnRef}
              setPreviewFormValue={setFormValue}
              watchPreviewForm={watch}
            />
          </header>

          <CodePreview
            fileDetailsPlaceholderMsg={placeholderMsg}
            filesTreePlaceholderMsg={placeholderMsg}
            filesTreeData={previewData as unknown as FolderOrFileStructure[]}
            highlightTreeItemCondition={highlightTreeItem}
            isLoading={isPreviewCodePending}
          />

          <ButtonArcPilot onClick={handleToggleChat} />
        </Panel>

        {openChat && (
          <ArcPilotPanel
            onChatOptionSelect={handleChatOptionSelect}
            onChatSend={handleChatSend}
            onCloseClick={handleCloseChat}
          />
        )}
      </PanelGroup>
    </div>
  )
}
