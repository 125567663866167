import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Text } from '~/components/Text'
import DialogForm from '~/components/dialogs/DialogForm/DialogForm'
import { DeleteFolderFilesFormEnum } from '~/models/enums/forms/DeleteFolderFilesFormEnum'
import type { DialogDeleteFolderFileProps } from '~/models/types/components/codeGenerationStrategy/DialogDeleteFolderFileProps'
import { useDialogDeleteFolderFile } from './useDialogDeleteFolderFile'

/**
 * Confirmation dialog to delete a folder or file.
 * It supports the deletion of multiple files,
 * but only 1 folder (delete multiple folders is no supported).
 * In case a folder is provided with an array of files,
 * only the files will be deleted.
 */
export const DialogDeleteFolderFile = (props: DialogDeleteFolderFileProps) => {
  const { isDialogOpen } = props

  const {
    computedItems,
    control,
    error,
    folderOrFileTxt,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  } = useDialogDeleteFolderFile(props)

  return (
    <DialogForm
      actions={
        <>
          <Button
            color="secondary"
            disabled={isPending}
            onClick={handleClose}
            variant="outlined"
          >
            No
          </Button>

          <LoadingButton
            autoFocus
            color="secondary"
            loading={isPending}
            type="submit"
            variant="contained"
          >
            Yes
          </LoadingButton>
        </>
      }
      content={
        <>
          Do you really want to delete the selected {folderOrFileTxt}?
          <div className="mt-2">
            {computedItems?.map((item) => (
              <Text className="text-slate-700" key={item.id}>
                <strong>{item.name}</strong>
              </Text>
            ))}
          </div>
          <Controller
            control={control}
            name={DeleteFolderFilesFormEnum.FILE_IDS}
            render={({ field }) => <input type="hidden" {...field} />}
          />
          <Controller
            control={control}
            name={DeleteFolderFilesFormEnum.FILE_PATH}
            render={({ field }) => <input type="hidden" {...field} />}
          />
        </>
      }
      contentId="delete-dialog-description"
      disableEscapeKeyDown={isPending}
      error={error as string}
      formProps={{ onSubmit: handleSubmit?.(handleFormSubmit) }}
      onClose={handleClose}
      open={isDialogOpen}
      title="Delete File / Path"
      titleId="delete-dialog-title"
    />
  )
}
