import { useMutation } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import type { PayloadPromptAiAssistant } from '~/services/Development.types'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to prompt AI assistant.
 */
export const usePromptAiAssistant = () => {
  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const {
        aggregateId,
        codeGenerationContext,
        commitSha,
        contextItemId,
        branch,
        message,
        previousMessages,
      } = fieldValues || {}

      invariant(aggregateId, ERROR_MESSAGES.aggregateId)
      invariant(codeGenerationContext, ERROR_MESSAGES.codeGenerationContext)
      invariant(commitSha, ERROR_MESSAGES.commitSha)
      invariant(contextItemId, ERROR_MESSAGES.contextItemId)
      invariant(branch, ERROR_MESSAGES.branch)
      invariant(message, ERROR_MESSAGES.message)

      const url = DevelopmentAggregateAPI.PromptAiAssistant

      const postData: PayloadPromptAiAssistant = {
        aggregateId,
        codeGenerationContext,
        commitSha,
        contextItemId,
        branch,
        message,
        previousMessages,
      }

      return serviceDevelopmentAggregate.post(url, postData)
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
