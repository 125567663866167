/**
 * Enum with the form fields names
 * from `configure git repo` form.
 */
export enum ConfigureGitRepoFormEnum {
  /** The ID of the aggregate */
  AGGREGATE_ID = 'aggregateId',
  /** The repository URL to be loaded. */
  REPOSITORY_URL = 'repositoryUrl',
  /** The auth token related the `repository url`. */
  TOKEN = 'token',
}
