import {
  useEffect,
  useState,
  type BaseSyntheticEvent,
  type MouseEvent,
} from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useConfigureGitRepo } from '~/hooks/api/developer/useConfigureGitRepo'
import { ConfigureGitRepoFormEnum } from '~/models/enums/forms/ConfigureGitRepoFormEnum'

export type UseRepositoryConnectionProps = {
  /** The ID of the aggregate to configure the git repo. */
  aggregateId?: GUID | null
  /** Callback handler when a repo is configured. */
  onRepoConfigured?: (repoUrl: string) => void
}

/**
 * Provides utilities for handling:
 * - Menu states;
 * - Form states;
 * - Mutation.
 */
export const useRepositoryConnection = (
  props: UseRepositoryConnectionProps,
) => {
  const { aggregateId, onRepoConfigured } = props

  // States.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Open state.
  const open = Boolean(anchorEl)

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    values: {
      [ConfigureGitRepoFormEnum.AGGREGATE_ID]: aggregateId,
      [ConfigureGitRepoFormEnum.REPOSITORY_URL]: '',
      [ConfigureGitRepoFormEnum.TOKEN]: '',
    },
  })

  // Configure git repo mutation.
  const {
    error: errorMutation,
    isPending: isMutationPending,
    isSuccess,
    mutateAsync,
    reset: resetMutation,
  } = useConfigureGitRepo()

  // Menu open/close.
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Reset.
  useEffect(() => {
    if (isSuccess && !!handleClose) handleClose()
  }, [isSuccess, handleClose])

  useEffect(() => {
    if (!anchorEl) {
      resetForm()
      resetMutation()
    }
  }, [anchorEl, resetForm, resetMutation])

  // Handles the configure git repo form submit.
  const handleFormSubmit = async (
    data: FieldValues,
    e?: BaseSyntheticEvent,
  ) => {
    e?.preventDefault()
    const result = await mutateAsync({
      ...data,
      [ConfigureGitRepoFormEnum.AGGREGATE_ID]: aggregateId,
    })

    const repositoryUrl = result?.data?.value?.repositoryUrl
    if (!!repositoryUrl) onRepoConfigured?.(repositoryUrl)
  }

  return {
    anchorEl,
    control,
    error: errorMutation,
    handleClick,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isLoading: isMutationPending,
    open,
  }
}
