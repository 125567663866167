import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Helper to check if a message exists in chat.
 * @param messages The array of messages.
 * @param messageId The ID of the message to be checked if it is in the chat.
 */
export const isMessageInChat = (
  messages: ChatMessage[],
  messageId: string,
): boolean => messages.some((msg) => msg.id?.includes(messageId))

export const isLastMsgInChat = (messages: ChatMessage[], messageId: string) =>
  messages?.[messages?.length - 1]?.id?.includes(messageId)
