/**
 * The IDs for the chat bot messages.
 * These are specific IDs for specific messages
 * that are not going to be repeated by the chat bot.
 */
export enum ChatBotMessageIds {
  // The message ID.
  NO_BLUEPRINT = 'noBlueprint',
  NO_ARC_MAP = 'noArcMap',
  NO_GIT_REPO_URL = 'noGitRepoUrl',
  NO_BRANCH = 'noBranch',
  NOT_PUBLISHED = 'notPublished',
  FIRST_MSG_TO_AI = 'firstMsgToAi',
  GENERIC_ERROR = 'genericError',

  // The message button ID.
  NO_BLUEPRINT_BTN = 'noBlueprintBtn',
  NO_ARC_MAP_BTN = 'noArcMapBtn',
  NO_GIT_REPO_URL_BTN = 'noGitRepoUrlBtn',
  NO_BRANCH_BTN = 'noBranchBtn',
  NOT_PUBLISHED_BTN = 'notPublishedBtn',
}
