import { Paper } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { useChatBotContext } from '~/hooks/contexts/useChatBotContext'
import { ChatBaseProps } from '~/models/types/components/chat/ChatBaseProps'
import { ChatContainer } from './ChatContainer/ChatContainer'

type ChatProps = ChatBaseProps & {
  /** The class name applied to the containing node. */
  className?: string
}

/**
 * The chat component:
 * This will allow the user to chat with an AI.
 */
export const Chat = (props: ChatProps) => {
  const { className, onOptionSelect, onSend } = props

  const { isLoading, messages } = useChatBotContext()

  return (
    <Paper
      className={twMerge('overflow-hidden rounded-lg', className)}
      elevation={0}
    >
      <ChatContainer
        isLoading={isLoading}
        messages={messages}
        onOptionSelect={onOptionSelect}
        onSend={onSend}
      />
    </Paper>
  )
}
