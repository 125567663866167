import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined'
import { Button, Menu } from '@mui/material'
import { CaretDown } from '@phosphor-icons/react'
import { useState, type MouseEvent, type RefObject } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import ArcMapTreeView from '~/components/treeView/ArcMapTreeView/ArcMapTreeView'
import { AiBlueprintPreviewFormEnum } from '~/models/enums/forms/AiBlueprintPreviewFormEnum'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'

type SelectArcMapProps = Pick<UseFormReturn, 'control' | 'watch'> & {
  /** Ref for the select button. */
  buttonRef?: RefObject<HTMLButtonElement>
}

/**
 * The component opens the ArcMap Tree View inside
 * a menu/select.
 */
export const SelectArcMap = (props: SelectArcMapProps) => {
  const { buttonRef, control, watch } = props

  // States.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Constants.
  const open = Boolean(anchorEl)

  const selectedArcMapNode = watch(AiBlueprintPreviewFormEnum.ARC_MAP_NODE)

  // Methods.
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNodeChange = (node: SelectedArcMapNodeInfo) => {
    if (!!node && !!node.id) handleClose()
  }

  return (
    <>
      <Button
        aria-controls={open ? 'arcmap-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="enabled:border-secondary-200"
        color="secondary"
        endIcon={<CaretDown size={12} weight="fill" />}
        id="arcmap-button"
        onClick={handleClick}
        ref={buttonRef}
        startIcon={<PolylineOutlinedIcon fontSize="medium" />}
        variant="outlined"
      >
        {selectedArcMapNode?.label || 'Click to select'}
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="arcmap-menu"
        MenuListProps={{
          'aria-labelledby': 'arcmap-button',
          className: 'w-[339px] p-0',
        }}
        onClose={handleClose}
        open={open}
      >
        <Controller
          control={control}
          name={AiBlueprintPreviewFormEnum.ARC_MAP_NODE}
          render={({ field: { onChange, value } }) => (
            <ArcMapTreeView
              onNodeChange={(node) => {
                handleNodeChange(node)
                onChange(node)
              }}
              selectedNode={value}
            />
          )}
        />
      </Menu>
    </>
  )
}
