import { Rocket, X } from '@phosphor-icons/react'
import { MouseEventHandler } from 'react'
import { Panel } from 'react-resizable-panels'
import ActionIconButton from '~/components/buttons/ActionIconButton/ActionIconButton'
import { Chat } from '~/components/Chat/Chat'
import ResizeHandle from '~/components/ResizeHandle'

type ArcPilotPanelProps = {
  /** Callback handler for close button click. */
  onChatOptionSelect?: (optionId: string, optionLabel: string) => void
  /** Callback handler for chat send. */
  onChatSend?: (message: string) => void
  /** Callback handler for chat option select. */
  onCloseClick?: MouseEventHandler<HTMLButtonElement>
}

/**
 * Panel with chat component and a resize handle.
 */
export const ArcPilotPanel = (props: ArcPilotPanelProps) => {
  const { onChatOptionSelect, onChatSend, onCloseClick } = props

  return (
    <>
      <ResizeHandle className="basis-1 border-b-0 border-l border-r-0 border-t-0 border-solid border-l-neutral-100" />

      <Panel className="overflow flex flex-col" defaultSize={30} minSize={20}>
        <div className="flex items-center gap-2 p-4">
          <Rocket size={24} />
          <h4 className="m-0 flex-1">ArcPilot</h4>

          <ActionIconButton
            className="h-[24px] w-[24px] min-w-[auto]"
            color="secondary"
            Icon={X}
            iconProps={{ size: 16 }}
            onClick={onCloseClick}
            tooltipPlacement="top"
            tooltipTitle="Close ArcPilot"
          />
        </div>

        <Chat
          className="flex-1"
          onOptionSelect={onChatOptionSelect}
          onSend={onChatSend}
        />
      </Panel>
    </>
  )
}
