import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot feedback message for blueprint.
 */
export const getChatFeedbackForArcMapMsg = async (): Promise<ChatMessage> => {
  // Simulate network delay.
  await new Promise((resolve) => setTimeout(resolve, 100))

  return {
    id: `${new Date()}`,
    type: MessageTypeEnum.ASSISTANT,
    content: {
      text: "Perfect! Your ArcMap entity is set. Let's move forward! 🚀",
    },
    timestamp: new Date(),
  }
}
