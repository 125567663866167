import { Editor as MonacoEditor, type EditorProps } from '@monaco-editor/react'
import { EDITOR_DEFAULT_OPTIONS } from '../CodeEditor.constants'

/**
 * The code editor component:
 * based on the "monaco editor".
 */
export const Editor = (props: Partial<EditorProps>) => {
  const {
    value,
    language = 'javascript',
    options = {
      ...(EDITOR_DEFAULT_OPTIONS as EditorProps['options']),
    },
    ...restProps
  } = props

  return (
    <div className="h-full min-h-[200px]">
      <MonacoEditor
        height="100%"
        language={language}
        value={value}
        options={options}
        {...restProps}
      />
    </div>
  )
}
