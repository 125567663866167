import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot message when there is no Blueprint selected.
 */
export const getChatNoBlueprintMessage = async (): Promise<ChatMessage> => {
  // Simulate network delay.
  await new Promise((resolve) => setTimeout(resolve, 500))

  return {
    id: ChatBotMessageIds.NO_BLUEPRINT + new Date(),
    type: MessageTypeEnum.ASSISTANT,
    content: {
      text: '# Select Your Blueprint\n\nTo get started, select one or more Blueprints to generate your code. 💡',
      options: [
        {
          id: ChatBotMessageIds.NO_BLUEPRINT_BTN,
          type: 'button',
          label: 'Select Blueprint',
        },
      ],
    },
    timestamp: new Date(),
  }
}
