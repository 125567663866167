import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import invariant from 'tiny-invariant'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to prompt AI assistant.
 */
export const useMoveFiles = (generationStrategyId: string) => {
  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const { fromPath, toPath } = fieldValues || {}

      invariant(generationStrategyId, 'Blue Print ID is required')

      const url = GenerationStrategyAPI.MoveFiles

      const postData: MoveFilesPayload = {
        generationStrategyId,
        fromPath,
        toPath,
      }

      return serviceGenerationStrategy.post(url, postData)
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeysEnum.GENERATION_STRATEGY_BY_ID,
          generationStrategyId,
        ],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}

export type MoveFilesPayload = {
  /** The generation strategy ID. */
  generationStrategyId: string
  /** The path from which the files will be moved. */
  fromPath: string
  /** The path to which the files will be moved. */
  toPath: string
}
