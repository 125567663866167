import { Button, type ButtonProps } from '@mui/material'
import { ArrowClockwise } from '@phosphor-icons/react'

type ButtonPreviewProps = ButtonProps & {
  /** Indicates if the form was ever submitted (submit at least once). */
  wasEverSubmitted?: boolean
}

/**
 * The preview button.
 */
export const ButtonPreview = (props: ButtonPreviewProps) => {
  const { disabled, wasEverSubmitted, ...rest } = props

  if (!wasEverSubmitted) return null

  return (
    <Button
      {...rest}
      color="secondary"
      disabled={disabled}
      startIcon={<ArrowClockwise size={25} />}
      type="submit"
      variant="text"
    >
      Refresh Preview
    </Button>
  )
}
