/**
 * Error messages related to the "Code Generation Strategy" (AI Blueprint).
 */
export const ERROR_MESSAGES = {
  aggregateId: 'Aggregate ID is required',
  fileId: 'File ID is required.',
  generationStrategyId: 'Generation Strategy ID is required.',
  organisationId: 'Organisation ID is required.',
  platformId: 'Platform ID is required',
  propertyBindingId: 'Property Binding ID is required.',

  // Mark a step as feature.
  eventId: 'Event ID is required.',
  processId: 'Process ID is required.',

  // Load git repository.
  authenticationToken: 'Authentication Token is required.',
  repositoryUrl: 'Repository URL is required.',

  // Delete files.
  filePath: 'The file path is required.',
  fileIds: 'The file ids field is required.',

  // Submit value property binding.
  value: 'The value is required.',

  // Prompt AI Assistant.
  codeGenerationContext: 'A context is required.',
  commitSha: 'A commit sha is required.',
  contextItemId: 'A context ID is required.',
  branch: 'A branch is required.',
  message: 'A message is required.',

  // Generic error.
  generic:
    'Something went wrong, please try again later or contact IT support.',

  folderName: 'Folder name is required',
}
