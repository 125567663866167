import type { DiffEditorProps, EditorProps } from '@monaco-editor/react'
import { DiffEditor } from './DiffEditor/DiffEditor'
import { Editor } from './Editor/Editor'

// type CodeEditorProps = Partial<EditorProps | DiffEditorProps> & {
//   isDiffView?: boolean
// }

type BaseEditorProps = {
  isDiffView?: boolean
}

type RegularCodeEditorProps = BaseEditorProps & {
  isDiffView?: false
} & Partial<EditorProps>

type DiffCodeEditorProps = BaseEditorProps & {
  isDiffView: true
} & Partial<DiffEditorProps>

type CodeEditorProps = RegularCodeEditorProps | DiffCodeEditorProps

/**
 * Code editor component:
 * based on the "monaco editor" lib.
 */
export const CodeEditor = (props: CodeEditorProps) => {
  const { isDiffView, ...restProps } = props

  return (
    <div className="h-full overflow-hidden shadow-sm">
      {isDiffView ? (
        <DiffEditor {...(restProps as Partial<DiffEditorProps>)} />
      ) : (
        <Editor {...(restProps as Partial<EditorProps>)} />
      )}
    </div>
  )
}
