import { PublicClientApplication } from '@azure/msal-browser'
import axios, {
  type AxiosRequestConfig,
  type InternalAxiosRequestConfig,
} from 'axios'
import { constants } from '~/config'
import { msalConfig } from '~/config/auth'
import { IS_DEV } from '~/config/constants'
import { getToken } from '~/utils/getToken'

export const CUSTOM_DELAY = 2000

/**
 * A config with support for custom delay,
 * which can be set to delay the response,
 * to await, for example, for another microservice.
 */
export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  customDelay?: number
}

const msalInstance = new PublicClientApplication(msalConfig)

export const interceptor = async (config: InternalAxiosRequestConfig) => {
  // get access token
  const requestToken = await getToken(msalInstance)
  // set the access token on the request
  config.headers.Authorization = `Bearer ${requestToken?.accessToken}`
  return config
}

const handleInterceptorError = (error: unknown) => {
  if (IS_DEV) {
    console.log(error)
  }

  return Promise.reject(error)
}

export const baseService = axios.create({
  baseURL: constants.API.DISCOVERYORGANISATION,
})
baseService.interceptors.request.use(interceptor, handleInterceptorError)

export const serviceDiscoveryOrganisation = axios.create({
  baseURL: constants.API.DISCOVERYORGANISATION,
})
serviceDiscoveryOrganisation.interceptors.request.use(interceptor)

export const serviceDevelopmentAggregate = axios.create({
  baseURL: constants.API.DEVELOPMENTAGGREGATE,
})

/**
 * Add an option to delay the request response:
 * This is a workaround made because another microservice may be called
 * and since we can't await for it, we can add a timeout
 * (not the perfect solution, but helps for most of the cases).
 */
serviceDevelopmentAggregate.interceptors.response.use(async (response) => {
  const config = response.config as CustomAxiosRequestConfig
  if (config.customDelay) {
    await new Promise((resolve) => setTimeout(resolve, config.customDelay))
  }
  return response
})

serviceDevelopmentAggregate.interceptors.request.use(interceptor)

export const serviceDiscoveryProcess = axios.create({
  baseURL: constants.API.DISCOVERYPROCESS,
})
serviceDiscoveryProcess.interceptors.request.use(interceptor)

export const servicePlatformTeam = axios.create({
  baseURL: constants.API.PLATFORMTEAM,
})
servicePlatformTeam.interceptors.request.use(interceptor)

export const serviceGenerationStrategy = axios.create({
  baseURL: constants.API.GENERATIONSTRATEGY,
})
serviceGenerationStrategy.interceptors.request.use(interceptor)

export const DiscoveryOrganisationAPI = {
  // GET
  getByOwnerIdOrEmail: ({ email, ownerId }: { email: string; ownerId: GUID }) =>
    `organisation/getbyowneridoremail/${email}/${ownerId}`,
  getByOwnerId: (ownerId?: GUID) => `organisation/getbyownerid/${ownerId}`,
  getUserProfileByOrganisationId: (organisationId: GUID) =>
    `organisation/${organisationId}/userprofile`,
  getUsersByOrganisationId: (organisationId: GUID) =>
    `organisation/${organisationId}/users`,

  // POST
  CreateOrganisation: `CreateOrganisation`,
  AddEcosystem: `AddEcosystem`,
  AddCoreDomain: 'AddCoreDomain', // unused
  AddSubDomain: 'AddSubDomain', // unused
  RemoveUser: 'RemoveUser',
  AddUser: 'AddUser',
  UpdateUserProfile: 'UpdateUserProfile',
}

export const PlatformTeamAPI = {
  // GET
  getByOrganisationId: (organisationId?: GUID) =>
    `platform/getbyorganisationid/${organisationId}`,
  getById: (platformId: GUID) => `platform/getbyid/${platformId}`,

  // POST
  CreatePlatform: 'CreatePlatform',
  SelectCloudPlatform: 'SelectCloudPlatform',
  SelectRoutingStrategy: 'SelectRoutingStrategy',
  SelectFrontEndHostingStrategy: 'SelectFrontEndHostingStrategy',
  SubmitGitCredentials: 'SubmitGitCredentials',
  Publish: 'Publish',
}

export const DevelopmentAggregateAPI = {
  // GET
  getAggregateById: (aggregateId: GUID) =>
    `domainreadmodel/getaggregatebyid/${aggregateId}`,
  getByPlatformId: (platformId: GUID) =>
    `domainreadmodel/getdomainbyplatformid/${platformId}`,
  getDomainReadModelTypes: (aggregateId: GUID) =>
    `domainreadmodel/gettypes/${aggregateId}`,
  getDomainsForCommands: (platformId: GUID) =>
    `domaineventsreadmodel/getdomaineventsbyplatformid/${platformId}`,
  GeneratePropertiesForCommand: ({
    eventType,
    aggregateId,
    eventId,
  }: {
    eventType: string
    aggregateId: GUID
    eventId: GUID
  }) => `aggregate/${aggregateId}/${eventType}/${eventId}/attributes`,
  GetPropertySuggestions: ({
    aggregateId,
    eventName,
    eventType,
  }: {
    aggregateId: string
    eventType: string
    eventName: string
  }) =>
    `aggregate/${aggregateId}/${eventType}/property-suggestions?EntityName=${eventName}`,

  // POST
  AddCommand: 'AddCommand',
  AddReaction: 'AddReaction',
  AddReadModel: 'AddReadModel',
  AddTypeProperty: 'AddTypeProperty',
  EditCommand: 'EditCommand',
  EditReaction: 'EditReaction',
  EditReadModel: 'EditReadModel',
  DeleteCommand: 'DeleteCommand',
  DeleteReaction: 'DeleteReaction',
  DeleteReadModel: 'DeleteReadModel',
  DeleteAggregate: 'DeleteAggregate',
  AddType: 'AddType',
  EditType: 'EditType',
  DeleteType: 'DeleteType',
  AddQuery: 'AddQuery',
  EditQuery: 'EditQuery',
  DeleteQuery: 'DeleteQuery',
  PublishQuery: 'PublishQuery',
  ConfigureGitRepo: 'ConfigureGitRepo',
  CreateAggregate: 'CreateAggregate',
  EditAggregate: 'EditAggregate',
  Initialise: 'Initialise',
  PromptAiAssistant: 'PromptAiAssistant',
  PublishCommand: 'PublishCommand',
  PublishReaction: 'PublishReaction',
  PublishType: 'PublishType',
  PublishAggregate: 'PublishAggregate',
  PublishReadModel: 'PublishReadModel',
  RepositoryGetBranches: 'repository/getbranches',
  SelectHostingStrategy: 'SelectHostingStrategy',
  SelectPersistenceStrategy: 'SelectPersistenceStrategy',
  GenerateAttributes: 'GenerateAttributes',
  SelectGenerationStrategies: 'SelectGenerationStrategies',
  GetLatestCommitSha: 'aggregate/:aggregateId/getlatestcommitsha',
}

export const DiscoveryProcessAPI = {
  // GET
  getByProcessId: (processId?: GUID) => `process/${processId}`,
  getFilesByProcessId: (processId?: GUID) => `process/${processId}/files`,
  getSasToken: (fileName: string) =>
    `process/get-sas-token?blobName=${fileName}`,
  getProcessGetRaisedDomainEventsByOrganisationId: (organisationId: GUID) =>
    `process/getraiseddomaineventsbyorganisationid/${organisationId}`,
  GetProcessListByOrganisationId: ({
    organisationId,
  }: {
    organisationId: string
  }) => `process/getbyorganisationid/${organisationId}`,
  GetProcessListByOwnerId: ({ ownerId }: { ownerId: string }) =>
    `process/getbyownerid/${ownerId}`,

  // POST
  CreateProcess: 'CreateProcess',
  AddStatement: 'AddStatement',
  DeleteStatement: 'DeleteStatement',
  InsertStatement: 'InsertStatement',
  SwapStatements: 'SwapStatements',
  UpdateStatementParsingInfo: 'UpdateStatementParsingInfo',
  MarkAStepAsFeature: 'MarkAStepAsFeature',
  MoveStatement: 'MoveStatement',
  DeleteProcess: 'DeleteProcess',
  UploadFiles: 'UploadFiles',
}

export const GenerationStrategyAPI = {
  // GET
  listStrategiesByOrganisationId: (organisationId: GUID) =>
    `ListGenerationStrategiesByOrganisation?organisationId=${organisationId}`,

  listStrategiesForPublish: (organisationId: GUID) =>
    `ListGenerationStrategiesForPublish?organisationId=${organisationId}`,

  ListBindingParameters: (id: GUID) =>
    `ListBindingParameters?generationStrategyId=${id}`,
  getPropertyBindings: (id: GUID) =>
    `GetPropertyBindings?generationStrategyId=${id}`,
  GetBindingContextOptions: 'GetBindingContextOptions',

  // POST
  ConvertFileToTemplate: 'ConvertFileToTemplate',
  CreateCollectionItem: 'CreateCollectionItem',
  CreateStrategy: 'CreateGenerationStrategy',
  CreateTextSectionGroup: 'CreateTextSectionGroup',
  DeleteFilePath: 'DeleteFilePath',
  DeleteFiles: 'DeleteFiles',
  DeletePropertyBinding: 'DeletePropertyBinding',
  DeleteStrategy: 'DeleteGenerationStrategy',
  GenerateBoilerplate: 'GenerateBoilerplate',
  GetConditionalMappings: 'GetConditionalMappings',
  GetGenerationStrategyById: 'GetGenerationStrategyById',
  GetGenerationStrategyForTreeView: 'GetGenerationStrategyForTreeView',
  GetGenerationStrategyItemForTreeView: 'GetGenerationStrategyItemForTreeView',
  ListGenerationStrategiesForPublish: 'ListGenerationStrategiesForPublish',
  LoadGitRepository: 'LoadGitRepository',
  MergeTextSections: 'MergeTextSections',
  PreviewCodeGeneration: 'PreviewCodeGeneration',
  ReduceFileContent: 'ReduceFileContent',
  MoveFiles: 'MoveFiles',
  ReplaceTokens: 'ReplaceTokens',
  UpdatePropertyMappingCondition: 'UpdatePropertyMappingCondition',
  UpdatePropertyBindingContext: 'UpdatePropertyBindingContext',
  UpdatePropertyValue: 'UpdatePropertyValue',
  UpdateStrategyFilePaths: 'UpdateStrategyFilePaths',
  UpdateStrategyTokens: 'UpdateStrategyTokens',
  UpdateTextSection: 'UpdateTextSection',
  UploadCode: 'UploadCode',
  AddFolder: 'AddFolder',
  CloneStrategy: 'Clone',
}
