import { Breadcrumbs, Button, Menu, MenuItem, Skeleton } from '@mui/material'
import {
  useGridApiRef,
  type GridCallbackDetails,
  type GridRowSelectionModel,
} from '@mui/x-data-grid'
import { TreeView } from '@mui/x-tree-view'
import { CaretDown, CaretRight } from '@phosphor-icons/react'
import { useIsFetching, useIsMutating, useQuery } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import { isEqual } from 'lodash'
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
  type SyntheticEvent,
} from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'
import {
  Link,
  useLoaderData,
  useNavigation,
  useParams,
  useSubmit,
} from 'react-router-dom'
import { Column } from '~/components/Column'
import ResizeHandle from '~/components/ResizeHandle'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import UploadFilesButton from '~/components/buttons/UploadFilesButton/UploadFilesButton'
import TreeViewToolbar from '~/components/treeView/TreeViewToolbar/TreeViewToolbar'
import { useGetGenerationStrategyForTreeView } from '~/hooks/api/codeGenerationStrategy/useGetGenerationStrategyForTreeView'
import { useAppPopperContext } from '~/hooks/contexts/useAppPopperContext'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'
import type { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { queryListBindingParameters } from '~/services/GenerationStrategy'
import type {
  FileStructure,
  FolderOrFileStructure,
  PropertyBinding,
  ResponseGetGenerationStrategy,
  ResponseListParametersByGenerationId,
} from '~/services/GenerationStrategy.types'
import { useCodeGenerationStore } from '~/store'
import { UploadFiles } from '../components/UploadFiles/UploadFiles'
import { useFilterFileFolderByName } from '../hooks/useFilterFileFolderByName'
import type { CodeGenerationStrategyEditSubmitIntent } from './action'
import { DialogDeleteFolderFile } from './components/DialogDeleteFolderFile/DialogDeleteFolderFile'
import { DialogNewFolder } from './components/DialogNewFolder/DialogNewFolder'
import { DialogRename } from './components/DialogRename/DialogRename'
import { FileDetailsPanel } from './components/FileDetailsPanel/FileDetailsPanel'
import { PreviewButton } from './components/FileDetailsPanel/PreviewButton/PreviewButton'
import {
  LoadRepositoryData,
  type LoadRepositoryDataHandle,
} from './components/LoadRepositoryData/LoadRepositoryData'
import { PanelAsideDetails } from './components/PanelAsideDetails/PanelAsideDetails'
import { PanelAsideDetailsButtons } from './components/PanelAsideDetails/PanelAsideDetailsButtons'
import { useManagePanelAsideDetails } from './components/PanelAsideDetails/useManagePanelAsideDetails'
import { DraggableTree } from './components/RenderTreeFilePath/DraggableTree'
import { useManageTreeViewContextMenu } from './hooks/useManageTreeViewContextMenu'
import { getDomainDictionaryPayloadData } from './utils/getDomainDictionaryPayloadData'
import { getNodesById } from './utils/getNodesById'
import {
  getAllPropertyIdsByFileId,
  getFolderIds,
  getNodeById,
} from './utils/utils'

function getDirectoryPath(fullPath: string | undefined): string {
  if (!fullPath) return ''

  // If the path ends with a slash, return the path without the trailing slash
  if (fullPath.endsWith('/')) {
    return fullPath.slice(0, -1)
  }

  // Check if the path ends with a pattern that looks like a file extension
  // Example: .txt, .jsx, .html, .java, etc.
  if (/\.[a-zA-Z0-9]{2,4}$/.test(fullPath)) {
    const pathParts = fullPath.split('/')
    pathParts.pop()
    return pathParts.join('/')
  }

  return fullPath
}

export function CodeGenerationStrategiesEdit() {
  // Ref.
  const loadRepositoryDataRef = useRef<LoadRepositoryDataHandle>(null)

  // React Router Dom.
  const navigation = useNavigation()

  const params = useParams()
  const { generationStrategyId } = params

  const submit = useSubmit()

  // Data from the server
  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>['data']
    >
    strategy: Awaited<
      AxiosResponse<ResponseGetGenerationStrategy | null, unknown>
    >
    parameters: Awaited<
      AxiosResponse<ResponseListParametersByGenerationId[], unknown>
    >
  }

  // Storage states.
  const selectedTreeItem = useCodeGenerationStore(
    (state) => state.selectedTreeItem,
  )
  const setSelectedTreeItem = useCodeGenerationStore(
    (state) => state.setSelectedTreeItem,
  )

  // Uses local storage to save the expanded properties so that when the user navigates back and forth, it will keep the expanded properties

  const setExpandedProperties = useCodeGenerationStore(
    (state) => state.setExpandedProperties,
  )

  // Queries.
  const { data: dataStrategy } = useGetGenerationStrategyForTreeView({
    generationStrategyId,
  })

  const dataDirectoryItems = useMemo(() => {
    if (!dataStrategy?.directoryItems?.length) return null

    return {
      children: [...dataStrategy.directoryItems],
      id: 'root',
      name: 'root',
      path: '',
    }
  }, [dataStrategy]) as FolderOrFileStructure

  // Query fetch state.
  const isFetchingStrategy = Boolean(
    useIsFetching({
      queryKey: [QueryKeysEnum.GENERATION_STRATEGY_BY_ID, generationStrategyId],
    }),
  )

  const { data: dataDomainDictionary } = useQuery({
    ...queryListBindingParameters(generationStrategyId),
    initialData: initialData.parameters,
    select: (response) => response.data,
  })

  const isSubmittingGitRepository = Boolean(
    useIsMutating({
      mutationKey: [QueryKeysEnum.LOAD_GIT_REPOSITORY],
    }),
  )

  // States.
  const [treeExpanded, setTreeExpanded] = useState<string[]>([])

  const [paramSelected, setParamsSelected] = useState<string>('')

  const [selectedPropertyBindingId, setSelectedPropertyBindingId] =
    useState<GUID>('')

  const [selectedPropertyBinding, setSelectedPropertyBinding] = useState<
    FolderOrFileStructure | PropertyBinding
  >({} as FolderOrFileStructure)

  const [selectedFileId, setSelectedFileId] = useState<GUID>(
    getNodeById(selectedTreeItem?.[0], dataDirectoryItems)?.fileId ?? '',
  )

  const [isDeleteDialogFilePathOpen, setIsDeleteDialogFilePathOpen] =
    useState(false)

  const [isDeleteDialogPropertyOpen, setIsDeleteDialogPropertyOpen] =
    useState(false)

  const [isNewFolderDialogOpen, setIsNewFolderDialogOpen] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')

  // Add state for rename dialog
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false)

  // Add this new state to track the item being operated on
  const [contextMenuNode, setContextMenuNode] = useState<{
    id: string
    path?: string
    name?: string
  } | null>(null)

  // Constants and Memoized data.
  const domainDictionaryParams = useMemo(
    () => dataDomainDictionary.map((item) => item.key).filter(Boolean),
    [dataDomainDictionary],
  )

  // Queries states.
  const isWaitingAutoMapDictionary =
    navigation.state === 'submitting' &&
    navigation.formData &&
    Object.fromEntries(navigation.formData).intent ===
      ('auto-map-bindings-for-properties' satisfies CodeGenerationStrategyEditSubmitIntent)

  const isSubmittingDictionary =
    navigation.state === 'submitting' &&
    navigation.formData &&
    Object.fromEntries(navigation.formData).intent ===
      ('parameter-map-update' satisfies CodeGenerationStrategyEditSubmitIntent)

  // A combination of `submit git repo` mutation and
  // `data directory items` fetch.
  const isPendingSubmitGitRepository =
    isSubmittingGitRepository || isFetchingStrategy

  // Hooks.
  const {
    showNodePropertiesPanel,
    showParametersPanel,
    handleToggleNodePropertiesClick,
    handleToggleParametersClick,
  } = useManagePanelAsideDetails()

  const {
    filteredData,
    filterInputValue,
    handleClearFilterInputClick,
    handleFilterInputChange,
  } = useFilterFileFolderByName({
    fileFolderData: dataDirectoryItems as FolderOrFileStructure,
  })

  // Indicates if the data has only the `root` folder - no nested folders.
  const rootFolderOnly = isEqual(
    getFolderIds((filteredData as FolderOrFileStructure)?.children),
    [filteredData],
  )

  // DataGrid
  const gridApiRef = useGridApiRef()

  // Selected text sections state.
  const { selectedTextSections, setSelectedTextSections } =
    useSelectedTextSectionsContext()

  // Popper Context.
  const { closeAppPopper } = useAppPopperContext()

  const {
    handleTreeViewContextMenu,
    setTreeViewContextMenu,
    treeViewContextMenu,
  } = useManageTreeViewContextMenu({ selectedTreeItem, setSelectedTreeItem })

  // Context state refs: this is necessary to call the functions
  // when the component unmounts.
  const resetRef = useRef<{
    closeAppPopper?: () => void
    setSelectedTextSections?: Dispatch<
      SetStateAction<SelectedTextSectionsType | null>
    >
  }>()

  // Set reset methods to ref.
  resetRef.current = {
    closeAppPopper,
    setSelectedTextSections,
  }

  // Lifecycle.
  useEffect(
    () => () => {
      resetRef.current?.closeAppPopper?.()
      resetRef.current?.setSelectedTextSections?.(null)
    },
    [],
  )

  useEffect(() => {
    if (!!dataDirectoryItems && !!selectedTreeItem) {
      setSelectedFileId(
        getNodeById(selectedTreeItem?.[0], dataDirectoryItems)?.fileId ?? '',
      )
    }
    if (!dataDirectoryItems && !!selectedFileId) setSelectedFileId('')
  }, [dataDirectoryItems, selectedFileId, selectedTreeItem, setSelectedFileId])

  useEffect(() => {
    if (dataDirectoryItems?.children) {
      setTreeExpanded(getFolderIds(dataDirectoryItems?.children))
    }
  }, [dataDirectoryItems?.children])

  // Methods.
  // Deletes a property binding node.
  const handleDeletePropertyBinding = useCallback(
    (propertyBinding?: FolderOrFileStructure | PropertyBinding) => {
      // Selects the node
      if (propertyBinding?.id) {
        setSelectedPropertyBinding(propertyBinding)
        setSelectedPropertyBindingId(propertyBinding?.id)
      }

      handleMenuClickDeleteObjectProperty()
    },
    [setSelectedPropertyBindingId],
  )

  function handleToggleTree(_: SyntheticEvent, nodeIds: string[]) {
    setTreeExpanded(nodeIds)
  }

  const handleTogglePropertyTree = useCallback(
    (_: SyntheticEvent, nodeIds: string[]) => {
      setExpandedProperties(nodeIds)
    },
    [setExpandedProperties],
  )

  const handleSelectTree = useCallback(
    (_: SyntheticEvent, nodeIds: string[]) => {
      // Reset selected text sections: this is to avoid
      // popper from the previous file to remain opened.
      if (!!selectedTextSections?.selected?.length) {
        setSelectedTextSections?.(null)
      }

      setSelectedTreeItem(nodeIds)

      const nodeInfo = getNodeById(
        nodeIds?.[0],
        dataDirectoryItems,
      ) as FileStructure | null

      setSelectedFileId(nodeInfo?.fileId ?? '')

      // Sets the expanded nodes for properties
      const allIds = getAllPropertyIdsByFileId(nodeInfo?.propertyBindings)
      setExpandedProperties(allIds)
    },
    [
      selectedTextSections?.selected?.length,
      setSelectedTreeItem,
      dataDirectoryItems,
      setExpandedProperties,
      setSelectedTextSections,
    ],
  )

  const handleSelectPropertyTree = useCallback(
    (_: SyntheticEvent, nodeId: string | string[]) => {
      if (nodeId && typeof nodeId === 'string') {
        setSelectedPropertyBindingId(nodeId)
      }
    },
    [setSelectedPropertyBindingId],
  )

  function handleMenuClickDeleteFilePath(event: EventFor<'button', 'onClick'>) {
    handleMenuClickCommonAction(event)
    setIsDeleteDialogFilePathOpen(true)
  }

  function handleMenuClickDeleteObjectProperty() {
    setIsDeleteDialogPropertyOpen(true)
  }

  function handleMenuClickCommonAction(event: EventFor<'button', 'onClick'>) {
    event.stopPropagation()
    handleCloseContextMenuFilePathTree()
  }

  const handleCloseDeleteDialogs = useCallback(() => {
    setIsDeleteDialogFilePathOpen(false)
    setIsDeleteDialogPropertyOpen(false)
  }, [setIsDeleteDialogFilePathOpen, setIsDeleteDialogPropertyOpen])

  function handleCloseContextMenuFilePathTree() {
    setTreeViewContextMenu(null)
  }

  function handleUpdateParametersMap() {
    const rowModels = gridApiRef.current?.getRowModels()
    const mappedRowModels = [
      ...rowModels.values(),
    ] as unknown as ResponseListParametersByGenerationId[]

    const domainDictionaryEntries =
      getDomainDictionaryPayloadData(mappedRowModels)

    const fileId = selectedFileId

    const data = {
      fileId,
      rows: JSON.stringify(domainDictionaryEntries),
      intent:
        'parameter-map-update' satisfies CodeGenerationStrategyEditSubmitIntent,
    }

    submit(data, { method: 'POST' })
  }

  function handleRowSelectionModelChange(
    rowSelectionModel: GridRowSelectionModel,
    _: GridCallbackDetails,
  ) {
    if (rowSelectionModel.length) {
      const first = rowSelectionModel[0]

      if (first) {
        const param = dataDomainDictionary.find(
          (item) => item.key === String(first),
        )
        setParamsSelected(param?.key || '')
      }
    }
  }

  const handleLoadRepositoryClick = () => {
    loadRepositoryDataRef.current?.handleOpenDialog()
  }

  const handleToggleTreeExpanded = () => {
    setTreeExpanded((prevState) => {
      if (prevState.length > 0 && !isEqual(prevState, [dataDirectoryItems?.id]))
        return [dataDirectoryItems?.id as string]

      return getFolderIds(dataDirectoryItems?.children)
    })
  }

  const isTreeExpanded = useMemo(() => {
    if (
      !Array.isArray(filteredData) ||
      (filteredData as FolderOrFileStructure[]).length === 1
    )
      return (
        !!treeExpanded.length &&
        !isEqual(treeExpanded, [dataDirectoryItems?.id])
      )

    return !!treeExpanded.length
  }, [filteredData, dataDirectoryItems?.id, treeExpanded])

  function handleMenuClickNewFolder(event: EventFor<'button', 'onClick'>) {
    handleMenuClickCommonAction(event)
    setIsNewFolderDialogOpen(true)
  }

  function handleCloseNewFolderDialog() {
    setIsNewFolderDialogOpen(false)
    setNewFolderName('')
  }

  function handleCreateNewFolder() {
    // TODO: Implement folder creation logic here
    // You'll need to:
    // 1. Get the parent path from contextMenuFilePathTree
    // 2. Create the new folder using the API
    // 3. Refresh the tree view data
    handleCloseNewFolderDialog()
  }

  function handleCloseRenameDialog() {
    setIsRenameDialogOpen(false)
    setContextMenuNode(null)
  }

  function handleMenuClickRename(event: EventFor<'button', 'onClick'>) {
    handleMenuClickCommonAction(event)
    setIsRenameDialogOpen(true)
  }

  if (dataStrategy && dataDomainDictionary) {
    return (
      <div className="grid h-[calc(100vh-65px)] grid-rows-[auto_1fr] bg-white p-4">
        <div className="mb-4 ml-2">
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            className="mb-2 text-xs"
          >
            <Link
              to="../../"
              relative="path"
              className="border-0 border-b border-solid border-b-highlight-200 text-highlight-200 no-underline hover:border-b-highlight-300 hover:text-highlight-300"
            >
              AI Blueprints
            </Link>

            <Text className="text-highlight-200">{dataStrategy.name}</Text>
          </Breadcrumbs>

          <div className="flex items-center justify-between">
            <Text className="text-2xl font-bold">
              AI Blueprint: {dataStrategy.name}
            </Text>

            <PreviewButton />
          </div>
        </div>

        <Row className="gap-2 overflow-hidden">
          <PanelGroup direction="horizontal">
            <Panel
              className="flex flex-col"
              collapsible={false}
              defaultSize={30}
              id="1"
              order={1}
            >
              <div className="flex-grow overflow-auto rounded-lg bg-slate-100 p-4 shadow-md">
                {!dataDirectoryItems && !isPendingSubmitGitRepository && (
                  <div className="flex flex-col gap-2">
                    <Text>Load files:</Text>

                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={handleLoadRepositoryClick}
                    >
                      From repository
                    </Button>

                    <LoadRepositoryData ref={loadRepositoryDataRef} />

                    <UploadFilesButton className="flex flex-col">
                      <Button color="secondary" variant="outlined">
                        From computer
                      </Button>
                    </UploadFilesButton>

                    <div className="my-2">
                      <UploadFiles />
                    </div>
                  </div>
                )}

                {dataDirectoryItems && !isPendingSubmitGitRepository && (
                  <>
                    <TreeViewToolbar
                      filterInputValue={filterInputValue}
                      isTreeExpanded={isTreeExpanded}
                      noToggle={!filteredData || rootFolderOnly}
                      onClearFilterInputClick={handleClearFilterInputClick}
                      onFilterInputChange={handleFilterInputChange}
                      onToggleClick={handleToggleTreeExpanded}
                    />

                    {!(filteredData as FolderOrFileStructure)?.id ? (
                      <p>No results found</p>
                    ) : (
                      <>
                        <TreeView
                          aria-label="repository tree view"
                          className="rounded border border-slate-200 p-2"
                          defaultCollapseIcon={<CaretDown />}
                          defaultExpandIcon={<CaretRight />}
                          expanded={treeExpanded}
                          multiSelect
                          onContextMenu={handleTreeViewContextMenu}
                          onNodeSelect={handleSelectTree}
                          onNodeToggle={handleToggleTree}
                          selected={selectedTreeItem}
                          draggable
                        >
                          <DraggableTree
                            domainDictionaryParams={
                              domainDictionaryParams as string[]
                            }
                            filteredValue={filterInputValue}
                            node={filteredData as FolderOrFileStructure}
                            generationStrategyId={generationStrategyId}
                            selectedTreeItem={selectedTreeItem}
                            setSelectedTreeItem={setSelectedTreeItem}
                            dataDirectoryItems={dataDirectoryItems}
                          />
                        </TreeView>
                        <Menu
                          open={treeViewContextMenu !== null}
                          onClose={handleCloseContextMenuFilePathTree}
                          anchorReference="anchorPosition"
                          anchorPosition={
                            treeViewContextMenu !== null
                              ? {
                                  top: treeViewContextMenu.mouseY,
                                  left: treeViewContextMenu.mouseX,
                                }
                              : undefined
                          }
                        >
                          <MenuItem className="p-0">
                            <button
                              onClick={handleMenuClickRename}
                              className="w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left"
                              type="button"
                            >
                              Rename
                            </button>
                          </MenuItem>
                          <MenuItem className="p-0">
                            <button
                              onClick={handleMenuClickDeleteFilePath}
                              className="w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left"
                              type="button"
                            >
                              Delete
                            </button>
                          </MenuItem>
                          <MenuItem className="p-0">
                            <button
                              onClick={handleMenuClickNewFolder}
                              className="w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left"
                              type="button"
                            >
                              New Folder
                            </button>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </>
                )}

                {isPendingSubmitGitRepository && (
                  <Column>
                    {Array.from({ length: 8 }, (_, i) => (
                      <Skeleton key={i} />
                    ))}
                  </Column>
                )}
              </div>
            </Panel>

            <DialogDeleteFolderFile
              handleCloseDialog={handleCloseDeleteDialogs}
              isDialogOpen={isDeleteDialogFilePathOpen}
              selectedItems={getNodesById(selectedTreeItem, dataDirectoryItems)}
            />

            <ResizeHandle />

            <FileDetailsPanel
              dataDomainDictionary={dataDomainDictionary}
              fileId={selectedFileId}
              isDeleteDialogPropertyOpen={isDeleteDialogPropertyOpen}
              onCloseDeleteDialog={handleCloseDeleteDialogs}
              onDeletePropertyBindingClick={handleDeletePropertyBinding}
              onPropertyBindingNodeToggle={handleTogglePropertyTree}
              onPropertyBindingNodeSelect={handleSelectPropertyTree}
              selectedDomainDictionaryItem={paramSelected}
              selectedPropertyBinding={selectedPropertyBinding}
              selectedPropertyBindingId={selectedPropertyBindingId}
              setSelectedPropertyBinding={setSelectedPropertyBinding}
            />

            <PanelAsideDetails
              dataDomainDictionary={dataDomainDictionary}
              fileId={selectedFileId}
              generationStrategyId={generationStrategyId}
              gridApiRef={gridApiRef}
              isSubmittingDictionary={isSubmittingDictionary}
              isWaitingAutoMapDictionary={isWaitingAutoMapDictionary}
              onDeletePropertyBindingClick={handleDeletePropertyBinding}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              onUpdateParametersMap={handleUpdateParametersMap}
              selectedDomainDictionaryItem={paramSelected}
              showNodePropertiesPanel={showNodePropertiesPanel}
              showParametersPanel={showParametersPanel}
            />
          </PanelGroup>

          <PanelAsideDetailsButtons
            onToggleNodePropertiesClick={handleToggleNodePropertiesClick}
            onToggleParametersClick={handleToggleParametersClick}
            showNodePropertiesPanel={showNodePropertiesPanel}
            showParametersPanel={showParametersPanel}
          />
        </Row>

        <DialogNewFolder
          generationStrategyId={generationStrategyId}
          filePath={getDirectoryPath(
            getNodeById(selectedTreeItem?.[0], dataDirectoryItems)?.path,
          )}
          isDialogOpen={isNewFolderDialogOpen}
          handleCloseDialog={handleCloseNewFolderDialog}
        />

        <DialogRename
          isDialogOpen={isRenameDialogOpen}
          handleCloseDialog={handleCloseRenameDialog}
          generationStrategyId={generationStrategyId}
          currentPath={
            getNodeById(selectedTreeItem?.[0], dataDirectoryItems)?.path
          }
          currentName={
            getNodeById(selectedTreeItem?.[0], dataDirectoryItems)?.name
          }
        />
      </div>
    )
  }
}
