import { Box, Paper, Typography } from '@mui/material'
import { useMemo } from 'react'

interface Match {
  itemId: string
  index: number
  length: number
  value: string
}

interface SearchResultPreviewProps {
  match: Match | undefined
  contextLines?: number
  isActive?: boolean
}

export default function SearchResultPreview({
  match,
  contextLines = 1,
  isActive = false,
}: SearchResultPreviewProps) {
  const contextContent = useMemo(() => {
    if (!match) return null
    if (!match.value) return null

    const lines = match.value.split('\n')
    const matchLineIndex =
      match.value.substring(0, match.index).split('\n').length - 1

    const startLine = Math.max(0, matchLineIndex - contextLines)
    const endLine = Math.min(lines.length - 1, matchLineIndex + contextLines)

    return lines.slice(startLine, endLine + 1).map((line, idx) => {
      const currentLineIndex = startLine + idx
      const isMatchLine = currentLineIndex === matchLineIndex

      if (!isMatchLine) {
        return {
          content: line,
          isMatch: false,
          lineNumber: currentLineIndex + 1,
        }
      }

      // Calculate the match position within this line
      const lineStart =
        lines.slice(0, matchLineIndex).join('\n').length +
        (matchLineIndex > 0 ? 1 : 0)
      const relativeMatchStart = match.index - lineStart

      return {
        content: line,
        isMatch: true,
        matchStart: relativeMatchStart,
        matchLength: match.length,
        lineNumber: currentLineIndex + 1,
      }
    })
  }, [match, contextLines])

  if (!match || !contextContent) return null

  return (
    <Paper
      sx={{
        p: 2,
        border: '1px solid',
        borderColor: isActive ? 'primary.main' : 'divider',
        backgroundColor: isActive ? 'action.hover' : 'background.paper',
      }}
    >
      {contextContent.map((line, idx) => (
        <Box
          key={idx}
          sx={{
            display: 'flex',
            gap: 2,
            fontFamily: 'monospace',
            fontSize: '0.875rem',
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              minWidth: '3ch',
              textAlign: 'right',
              userSelect: 'none',
            }}
          >
            {line.lineNumber}
          </Typography>
          <Typography
            component="span"
            sx={{
              flex: 1,
              fontFamily: 'inherit',
              fontSize: 'inherit',
            }}
          >
            {line.isMatch ? (
              <>
                {line.content.slice(0, line.matchStart)}
                <Box
                  component="span"
                  sx={{
                    bgcolor: isActive ? 'primary.main' : 'warning.light',
                    color: isActive
                      ? 'primary.contrastText'
                      : 'warning.contrastText',
                    px: 0.5,
                    borderRadius: 0.5,
                  }}
                >
                  {line.content.slice(
                    line.matchStart,
                    line.matchStart! + line.matchLength!,
                  )}
                </Box>
                {line.content.slice(line.matchStart! + line.matchLength!)}
              </>
            ) : (
              line.content
            )}
          </Typography>
        </Box>
      ))}
    </Paper>
  )
}
