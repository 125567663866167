import SendIcon from '@mui/icons-material/Send'
import { Button, TextField } from '@mui/material'
import { useRef, useState, type KeyboardEvent } from 'react'
import { InputContainer } from './ChatInput.styles'

interface ChatInputProps {
  disabled?: boolean
  onSend?: (message: string) => void
}

/**
 *
 */
export const ChatInput = (props: ChatInputProps) => {
  const { onSend, disabled } = props

  const [message, setMessage] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleSend = () => {
    if (message.trim() && !disabled) {
      onSend?.(message)
      setMessage('')
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  return (
    <InputContainer className="flex gap-2 p-4">
      <TextField
        inputRef={textareaRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Type your message..."
        fullWidth
        multiline
        maxRows={1}
        disabled={disabled}
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'background.paper',
          },
        }}
      />

      <Button
        onClick={handleSend}
        disabled={!message.trim() || disabled}
        variant="contained"
        size="small"
        sx={{ minWidth: 40, width: 40, height: 40, padding: 0 }}
      >
        <SendIcon fontSize="small" />
      </Button>
    </InputContainer>
  )
}
