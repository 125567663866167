import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot message when there is no ArcMap item selected.
 */
export const getChatNoArcMapMessage = async (): Promise<ChatMessage> => {
  // Simulate network delay.
  await new Promise((resolve) => setTimeout(resolve, 500))

  return {
    id: ChatBotMessageIds.NO_ARC_MAP + new Date(),
    type: MessageTypeEnum.ASSISTANT,
    content: {
      text: '# What you would like to build?\n\nSelect the business element from ArcMap that you would like to build. We will generate code for this using your blueprint.. 🎯',
      options: [
        {
          id: ChatBotMessageIds.NO_ARC_MAP_BTN,
          type: 'button',
          label: 'Select from ArcMap',
        },
      ],
    },
    timestamp: new Date(),
  }
}
