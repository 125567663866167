import { Paper, styled } from '@mui/material'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'

export const MessagePaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'messageType',
})<{ messageType: MessageTypeEnum }>(({ theme, messageType }) => ({
  backgroundColor:
    messageType === MessageTypeEnum.ASSISTANT
      ? theme.palette.grey[50]
      : theme.palette.primary.main,
  color:
    messageType === MessageTypeEnum.ASSISTANT
      ? theme.palette.text.primary
      : theme.palette.primary.contrastText,
  boxShadow:
    messageType === MessageTypeEnum.ASSISTANT
      ? '0 2px 4px rgba(0,0,0,0.05)'
      : '0 2px 4px rgba(0,0,0,0.1)',
}))
