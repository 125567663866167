import { Button } from '@mui/material'
import { CodeSimple } from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'
import { generatePath, useParams } from 'react-router-dom'
import { ActionButtonClasses } from '~/components/buttons/ActionIconButton/ActionIconButton'
import { ConfigurationRoutesEnum } from '~/models/enums/routes/ConfigurationRoutesEnum'

/**
 * Preview button component.
 */
export const PreviewButton = () => {
  // React Router Dom.
  const params = useParams()

  const {
    generationStrategyId = '',
    organisationId = '',
    platformId = '',
  } = params

  const handlePreviewClick = () => {
    // Get the base URL
    const baseUrl = window.location.origin

    const path = generatePath(ConfigurationRoutesEnum.AI_BLUEPRINT_PREVIEW, {
      generationStrategyId,
      organisationId,
      platformId,
    })

    // Open in new tab
    window.open(
      `${baseUrl}${path}`,
      'ArcPilotBlueprint',
      'location=1, status=1, scrollbars=1, resizable=1, directories=1, toolbar=1, titlebar=1',
    )
  }

  return (
    <Button
      className={twMerge(ActionButtonClasses, 'w-auto p-3')}
      color="secondary"
      onClick={handlePreviewClick}
      startIcon={<CodeSimple />}
      variant="outlined"
    >
      Code Preview
    </Button>
  )
}
