import { useEffect, useRef, useState } from 'react'
import type { ChatMessage as ChatMessageType } from '~/models/types/components/chat/Chat'
import type { ChatBaseProps } from '~/models/types/components/chat/ChatBaseProps'
import { ChatInput } from '../ChatInput/ChatInput'
import { ChatMessage } from '../ChatMessage/ChatMessage'
import { LoadingDots, ScrollableContainer } from './ChatContainer.styles'

type ChatContainerProps = ChatBaseProps & {
  isLoading?: boolean
  messages: ChatMessageType[]
}

/**
 *
 */
export const ChatContainer = (props: ChatContainerProps) => {
  const { isLoading, messages, onOptionSelect, onSend } = props

  const scrollRef = useRef<HTMLDivElement>(null)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  // Handle initial render and mount.
  useEffect(() => {
    // Wait for next frame to ensure container is properly rendered.
    requestAnimationFrame(() => {
      // Additional timeout to account for any transitions or dynamic content.
      setTimeout(() => {
        scrollToBottom()
        setIsInitialized(true)
      }, 100)
    })
  }, [])

  // Handle message updates after initial render.
  useEffect(() => {
    if (isInitialized) {
      scrollToBottom()
    }
  }, [messages, isInitialized])

  // Dedicated scroll handler.
  const scrollToBottom = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current
      // In case the scroll doesn't work the first time due to image loading or other dynamic content.
      const retryScroll = () => {
        scrollElement.scrollTo({
          top: scrollElement.scrollHeight,
          behavior: 'smooth',
        })
      }

      retryScroll()
      // Additional retry after a short delay.
      setTimeout(retryScroll, 50)
    }
  }

  return (
    <div className="flex h-full flex-col">
      <ScrollableContainer
        className="flex-grow overflow-y-auto p-4"
        ref={scrollRef}
      >
        <div className="flex flex-col gap-4">
          {messages.map((message) => (
            <ChatMessage
              key={message.id}
              message={message}
              onOptionSelect={onOptionSelect}
            />
          ))}
        </div>
      </ScrollableContainer>

      {isLoading && (
        <div className="flex items-center gap-2 px-4 py-2">
          <LoadingDots className="flex items-center gap-2">
            <div>●</div>
            <div style={{ animationDelay: '0.2s' }}>●</div>
            <div style={{ animationDelay: '0.4s' }}>●</div>
          </LoadingDots>
        </div>
      )}

      <ChatInput onSend={onSend} disabled={isLoading} />
    </div>
  )
}
