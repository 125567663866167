import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import {
  ResponseListParametersByGenerationId,
  TextSection,
} from '~/services/GenerationStrategy.types'
import FindReplaceComponent from './find-replace'

interface FindReplaceModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  items: TextSection[]
  filePathData?: TextSection
  onUpdate: (
    updatedItem: TextSection,
    callbacks?: {
      onSuccess?: (item?: any) => void
      onError?: (error: any) => void
    },
  ) => void
  onFilePathUpdate?: (
    updatedItem: TextSection,
    callbacks?: {
      onSuccess?: (item?: any) => void
      onError?: (error: any) => void
    },
  ) => void
  dataDomainDictionary: ResponseListParametersByGenerationId[]
}

export default function FindReplaceModal({
  open,
  onOpenChange,
  items,
  filePathData,
  onUpdate,
  onFilePathUpdate,
  dataDomainDictionary,
}: FindReplaceModalProps) {
  return (
    <Dialog
      open={open}
      onClose={() => onOpenChange(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Find and Replace</DialogTitle>
      <DialogContent>
        <FindReplaceComponent
          dataDomainDictionary={dataDomainDictionary}
          items={filePathData ? [filePathData, ...items] : items}
          onUpdate={(updatedItem, callbacks) => {
            if (filePathData && updatedItem.id === filePathData.id) {
              return onFilePathUpdate?.(updatedItem, callbacks)
            }
            return onUpdate(updatedItem, callbacks)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
