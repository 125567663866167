// components/DraggableTreeItem.tsx
import { useDraggable, useDroppable } from '@dnd-kit/core'
import { twMerge } from 'tailwind-merge'
import { TreeItem } from '~/components/treeView/TreeView.styles'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { TreeItemLabel } from './TreeItemLabel'

interface DraggableTreeItemProps {
  node?: FolderOrFileStructure
  children?: React.ReactNode
  /** Array of domain dictionary keys. */
  domainDictionaryParams?: string[]
  /**
   * Any filtered value by the user:
   * used to highlight the matching strings.
   */
  filteredValue?: string
  /** A callback handler condition that will be used to highlight the label. */
  highlightCondition?: (node: FolderOrFileStructure) => string
}

export function DraggableTreeItem({
  node,
  children,
  domainDictionaryParams,
  filteredValue,
  highlightCondition,
  ...props
}: DraggableTreeItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableRef,
  } = useDraggable({
    id: node?.id ?? '',
    data: {
      node,
    },
  })

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: node?.id ?? '',
    data: {
      node,
    },
  })

  const setNodeRef = (element: HTMLDivElement) => {
    setDraggableRef(element)
    setDroppableRef(element)
  }

  const treeItemLabel = (
    <TreeItemLabel
      domainDictionaryParams={domainDictionaryParams as string[]}
      filteredValue={filteredValue as string}
      highlightCondition={highlightCondition}
      node={node as FolderOrFileStructure}
    />
  )

  return (
    <TreeItem
      {...props}
      nodeId={node?.id ?? ''}
      size="small"
      label={
        <div
          ref={node?.id === 'root' ? setDroppableRef : setNodeRef}
          className={twMerge(
            isOver && node?.children && 'rounded-md bg-primary',
          )}
          {...attributes}
          {...listeners}
        >
          {treeItemLabel}
        </div>
      }
    >
      {children}
    </TreeItem>
  )
}
