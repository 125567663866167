import PolylineIcon from '@mui/icons-material/Polyline'
import {
  BracketsSquare,
  Folder,
  Lightning,
  ListMagnifyingGlass,
  ListNumbers,
  Play,
} from '@phosphor-icons/react'
import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'

/**
 * Provides the proper icon
 * based on the node type.
 * @param nodeType The node type.
 */
export const getNodeIcon = (nodeType?: ArcMapTreeNodeType) => {
  // Resource item icons.
  switch (true) {
    case nodeType === ArcMapTreeNodeType.FOLDER:
      return <Folder />
    case nodeType === ArcMapTreeNodeType.AGGREGATE:
      return <PolylineIcon className="w-[16px]" />
    case nodeType === ArcMapTreeNodeType.COMMAND:
      return <Play />
    case nodeType === ArcMapTreeNodeType.REACTION:
      return <Lightning />
    case nodeType === ArcMapTreeNodeType.QUERY:
      return <ListMagnifyingGlass />
    case nodeType === ArcMapTreeNodeType.TYPE:
      return <ListNumbers />
    case nodeType === ArcMapTreeNodeType.BOUNDED_CONTEXT:
    default:
      return <BracketsSquare />
  }
}
