import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot message when there is no configured git repo url.
 */
export const getChatNoGitRepoUrlMessage = async (): Promise<ChatMessage> => {
  // Simulate network delay.
  await new Promise((resolve) => setTimeout(resolve, 500))

  return {
    id: ChatBotMessageIds.NO_GIT_REPO_URL + new Date(),
    type: MessageTypeEnum.ASSISTANT,
    content: {
      text: '# Connect a Git Repository \n\nConnect a git repository to preview your changes. When you are happy, we can push your changes to your selected branch 🔄',
      options: [
        {
          id: ChatBotMessageIds.NO_GIT_REPO_URL_BTN,
          type: 'button',
          label: 'Configure Git Repository',
        },
      ],
    },
    timestamp: new Date(),
  }
}
