import {
  Box,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { ConfigForMenuToReplace } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'

const DROPDOWN_HEIGHT_BASE = 48
const MAX_MENU_HEIGHT = DROPDOWN_HEIGHT_BASE * 4.5 + 80
const DROPDOWN_WIDTH_BASE = '40ch'
const GROUP_TYPES = ['Commands', 'Reactions', 'Queries', 'Types'] as const

type GroupType = (typeof GROUP_TYPES)[number] | null

export type MenuToReplaceTextProps = Pick<
  CodeGenerationStrategyBaseProps,
  'dataDomainDictionary'
> & {
  onMenuClose: () => void
  onMenuItemClick: (event: EventFor<'li', 'onClick'>) => void
  options?: ConfigForMenuToReplace
}

export const MenuToReplaceText = (props: MenuToReplaceTextProps) => {
  const { dataDomainDictionary, onMenuClose, onMenuItemClick, options } = props
  const [selectedGroup, setSelectedGroup] = useState<GroupType>(null)

  const filteredItems = useMemo(() => {
    if (!dataDomainDictionary) return []

    if (!selectedGroup) return dataDomainDictionary

    // Find the index and depth of the selected group header
    let groupIndex = dataDomainDictionary.findIndex(
      (item) => item.label.toLowerCase() === selectedGroup.toLowerCase(),
    )

    if (groupIndex === -1) return []

    const groupDepth = dataDomainDictionary[groupIndex].depth

    // Find the end of this group (next item at same or lower depth)
    let groupEndIndex = groupIndex + 1
    while (
      groupEndIndex < dataDomainDictionary.length &&
      dataDomainDictionary[groupEndIndex]!.depth > groupDepth
    ) {
      groupEndIndex++
    }

    // Return items from the group header to the end of its children
    return dataDomainDictionary.slice(groupIndex, groupEndIndex)
  }, [dataDomainDictionary, selectedGroup])

  const handleGroupChange = (
    _: React.MouseEvent<HTMLElement>,
    newGroup: GroupType,
  ) => {
    setSelectedGroup(newGroup)
  }

  return (
    <Menu
      open={!!options}
      onClose={onMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        !!options
          ? {
              top: options?.mouseY,
              left: options?.mouseX,
            }
          : undefined
      }
      PaperProps={{
        sx: {
          maxHeight: MAX_MENU_HEIGHT,
          width: DROPDOWN_WIDTH_BASE,
          overflow: 'hidden',
        },
      }}
      disablePortal
    >
      <Stack
        direction="column"
        sx={{
          height: '100%',
          maxHeight: MAX_MENU_HEIGHT,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            borderBottom: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.paper',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Box sx={{ p: 1 }}>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={selectedGroup}
              onChange={handleGroupChange}
              aria-label="group filter"
              fullWidth
            >
              {GROUP_TYPES.map((group) => (
                <ToggleButton
                  key={group}
                  value={group}
                  aria-label={group}
                  size="small"
                >
                  {group}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        </Paper>

        <Box
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => theme.palette.grey[400],
              borderRadius: '4px',
              '&:hover': {
                background: (theme) => theme.palette.grey[600],
              },
            },
          }}
        >
          <MenuList>
            {filteredItems.map(({ depth, id, label, key }) => {
              const compKey = id || `${depth}-${key || label}`
              const indent = depth * 16

              if (key === null) {
                return (
                  <ListSubheader
                    key={compKey}
                    disableGutters
                    sx={{
                      pr: 2,
                      py: 1,
                      lineHeight: 1.2,
                      fontWeight: 600,
                      color: 'text.primary',
                      fontSize: '0.9rem',
                      bgcolor: 'background.paper',
                      borderLeft: (theme) =>
                        depth <= 1
                          ? `3px solid ${theme.palette.primary.main}`
                          : 'none',
                      pl: (theme) =>
                        depth <= 1 ? `${indent - 3}px` : `${indent}px`,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'inherit',
                        letterSpacing: 'normal',
                      }}
                    >
                      {label}
                    </Typography>
                  </ListSubheader>
                )
              }

              return (
                <MenuItem
                  data-value={key}
                  key={compKey}
                  onClick={onMenuItemClick}
                  sx={{
                    pl: `${indent + 8}px`,
                    py: 0.75,
                    minHeight: 'unset',
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: `${indent}px`,
                      top: '50%',
                      width: '4px',
                      height: '1px',
                      bgcolor: 'primary.main',
                      opacity: 0.6,
                    },
                    '&:hover': {
                      color: 'text.primary',
                      '&::before': {
                        bgcolor: 'primary.main',
                      },
                    },
                  }}
                >
                  {label}
                </MenuItem>
              )
            })}
          </MenuList>
        </Box>
      </Stack>
    </Menu>
  )
}
