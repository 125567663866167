import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot feedback message for published.
 */
export const getChatFeedbackForPublishedMsg =
  async (): Promise<ChatMessage> => {
    // Simulate network delay.
    await new Promise((resolve) => setTimeout(resolve, 100))

    return {
      id: `${new Date()}`,
      type: MessageTypeEnum.ASSISTANT,
      content: {
        text: 'I am analysing your change set and will provide an implementation plan to work on together.',
      },
      timestamp: new Date(),
    }
  }
