import { useEffect } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useAddFolder } from '~/hooks/api/codeGenerationStrategy/useAddFolder'

export interface DialogNewFolderProps {
  filePath?: string
  isDialogOpen: boolean
  handleCloseDialog: () => void
  generationStrategyId?: string
}

export const useDialogNewFolder = (props: DialogNewFolderProps) => {
  const { filePath, handleCloseDialog, isDialogOpen, generationStrategyId } =
    props

  const {
    control,
    handleSubmit,
    reset: resetForm,
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      folderName: '',
    },
  })

  // Reset form when dialog closes
  useEffect(() => {
    if (!isDialogOpen) {
      resetForm()
    }
  }, [isDialogOpen, resetForm])

  const folderName = watch('folderName')

  const {
    mutate: addFolder,
    error,
    isError,
    isPending,
  } = useAddFolder({
    generationStrategyId,
  })

  const handleFormSubmit = (data: FieldValues) => {
    const newFolderPath = filePath
      ? `${filePath}/${data.folderName}`
      : data.folderName

    addFolder(
      { ...data, folderName: newFolderPath },
      {
        onSuccess: () => {
          handleCloseDialog()
        },
      },
    )
  }

  const handleClose = () => {
    handleCloseDialog()
  }

  return {
    control,
    folderName,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    error,
    isError,
    isPending,
  }
}
