import { useContext } from 'react'
import ChatBotContext from '~/contexts/ChatBotContext'

/**
 * Hook to consume the Chat Bot context states.
 */
export const useChatBotContext = () => {
  const context = useContext(ChatBotContext)

  if (!context)
    throw new Error(
      'useChatBotContext must be used within a ChatBotContextProvider',
    )

  return context
}
