import { Button } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import React from 'react'
import { Text } from '~/components/Text'

export function CodeGenerationStrategiesTest() {
  const [isColumnVisible, setIsColumnVisible] = React.useState(false)

  function toggleColumnVisibility() {
    setIsColumnVisible((prev) => !prev)
  }

  return (
    <div
      className={twMerge(
        'grid h-[calc(100svh-161px)] grid-flow-col grid-cols-[repeat(3,1fr)] gap-2 transition-all duration-300 ease-linear',
        isColumnVisible && 'grid-cols-[1fr,30px,1fr]',
      )}
    >
      <div className="bg-slate-100 p-2">
        Column 1<Button onClick={toggleColumnVisibility}>Toggle</Button>
      </div>
      <div className="block overflow-y-auto bg-slate-300 p-2">
        <Text>Column 2</Text>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel
          aliquam ipsum. Mauris vestibulum neque sit amet lacus euismod
          tincidunt. Pellentesque ut nisl elit. Vivamus tempus, libero ac
          tincidunt blandit, orci ante porttitor ex, vel dapibus elit dolor ut
          lorem. Aliquam erat volutpat. Duis ut sapien nibh. Suspendisse
          potenti. Ut dictum leo nisi. Donec egestas congue euismod. Morbi vel
          augue egestas, sagittis quam vitae, sagittis augue. Nullam vel lectus
          elementum, feugiat dolor a, viverra tellus.
        </Text>
        <Text>
          Aliquam vitae sollicitudin erat. Aliquam vel malesuada odio. Maecenas
          sollicitudin arcu elit, a luctus nisi pulvinar ac. Aliquam sed
          pharetra nisi. Nunc quis pretium enim. Pellentesque posuere hendrerit
          nunc ac accumsan. Suspendisse vestibulum massa in nulla lacinia, sit
          amet consequat enim bibendum. Sed mauris mauris, vehicula non porta
          ac, scelerisque in metus. Morbi porttitor a libero a vestibulum.
          Maecenas orci ante, convallis eget condimentum sit amet, finibus
          euismod ex. Aenean rhoncus metus vel ex elementum consectetur. Cras
          dapibus tempor dui, in porttitor elit suscipit eget. Cras efficitur
          sodales risus, vitae vestibulum nisl pretium vel. Curabitur imperdiet
          metus in magna molestie congue. Duis vehicula ante vulputate libero
          laoreet laoreet.
        </Text>
        <Text>
          Ut nec pharetra risus, porttitor placerat erat. Nullam elementum sem
          vitae leo euismod efficitur. Etiam nec ornare nisl, id scelerisque
          enim. Curabitur commodo blandit ligula, at tristique neque fermentum
          vel. Donec at risus facilisis, vestibulum ante non, pharetra magna.
          Fusce vel orci imperdiet, ornare magna nec, cursus purus. Curabitur
          varius nunc id tempor semper.
        </Text>
        <Text>
          Maecenas at dui est. Vestibulum a odio commodo, pharetra sem ut,
          aliquam sem. Aliquam lacus risus, fermentum eget porta sed, auctor in
          dui. Duis non nisl et nunc sollicitudin interdum. Cras augue neque,
          molestie ornare vulputate et, finibus at quam. Donec tincidunt metus
          sed cursus ullamcorper. Suspendisse quis eros non diam bibendum
          pharetra dictum at odio. Maecenas ut efficitur ligula, vel commodo
          justo. Donec maximus dui dolor, ac mattis odio eleifend a. Nam
          imperdiet libero tempor, egestas augue a, rutrum est.
        </Text>
        <Text>
          Pellentesque iaculis ipsum quis turpis imperdiet aliquam. Donec
          molestie fringilla gravida. Curabitur sit amet nunc in mi mattis
          placerat. Duis malesuada purus ut vestibulum malesuada. Pellentesque
          hendrerit diam mi, et vulputate tortor pretium sit amet. Proin iaculis
          pretium tortor eu maximus. Ut fermentum elit nunc, eget sodales elit
          tempus eu. Class aptent taciti sociosqu ad litora torquent per conubia
          nostra, per inceptos himenaeos. Nullam purus ligula, maximus vel
          sodales eu, pellentesque efficitur elit. Vestibulum semper massa in
          imperdiet consectetur.
        </Text>
      </div>
      <div className="bg-slate-600 p-2">Column 3</div>
    </div>
  )
}
