import { LoadingButton } from '@mui/lab'
import { Button, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import DialogForm from '~/components/dialogs/DialogForm/DialogForm'
import type { DialogNewFolderProps } from './useDialogNewFolder'
import { useDialogNewFolder } from './useDialogNewFolder'

export function DialogNewFolder(props: DialogNewFolderProps) {
  const { filePath, isDialogOpen } = props

  const {
    control,
    folderName,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isPending,
  } = useDialogNewFolder({ ...props })

  return (
    <DialogForm
      actions={
        <>
          <Button color="secondary" onClick={handleClose} variant="outlined">
            Cancel
          </Button>

          <LoadingButton
            autoFocus
            color="secondary"
            disabled={!folderName.trim()}
            loading={isPending}
            type="submit"
            variant="contained"
          >
            Create
          </LoadingButton>
        </>
      }
      content={
        <>
          <p className="mb-4 text-gray-600">
            The new folder will be created in:{' '}
            <span className="font-bold">{filePath}</span>
          </p>

          <Controller
            control={control}
            name="folderName"
            render={({ field }) => (
              <TextField
                {...field}
                className="w-full"
                inputProps={{ className: 'py-3' }}
                InputProps={{ className: 'p-0' }}
                placeholder="Folder name"
                autoFocus
              />
            )}
          />
        </>
      }
      contentId="new-folder-dialog-description"
      formProps={{ onSubmit: handleSubmit(handleFormSubmit) }}
      onClose={handleClose}
      open={isDialogOpen}
      title="Create New Folder"
      titleId="new-folder-dialog-title"
    />
  )
}
