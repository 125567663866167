import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryKeyStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'

export const actionConfigurationGenerationStrategyClone =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const { organisationId, platformId } = params
    const formData = await request.formData()
    const formEntries = Object.fromEntries(formData)

    const generationStrategyId = formEntries.generationStrategyId as
      | string
      | undefined
    const newName = formEntries.newName as string | undefined

    invariant(generationStrategyId, 'Generation Strategy Id is required')
    invariant(newName, 'New name is required')
    invariant(organisationId, 'Organisation ID is required')

    const postData = {
      generationStrategyId,
      organisationId,
      newName,
    }

    const url = GenerationStrategyAPI.CloneStrategy
    const req = await serviceGenerationStrategy.post(url, postData)

    if (req.status === 200) {
      await queryClient.invalidateQueries({
        queryKey: queryKeyStrategiesByOrganisationId(organisationId),
      })
    }

    return redirect(
      `/${organisationId}/${platformId}/configuration/code-generation-strategies`,
    )
  }
