import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type RefObject,
} from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { usePromptAiAssistant } from '~/hooks/api/developer/usePromptAiAssistant'
import { useChatBotContext } from '~/hooks/contexts/useChatBotContext'
import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import { AiBlueprintPreviewFormEnum } from '~/models/enums/forms/AiBlueprintPreviewFormEnum'
import { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { getChatFeedbackForArcMapMsg } from '../utils/getChatFeedbackForArcMapMsg'
import { getChatFeedbackForBlueprintMsg } from '../utils/getChatFeedbackForBlueprintMsg'
import { getChatFeedbackForGitRepoUrlMsg } from '../utils/getChatFeedbackForGitRepoUrlMsg'
import { getChatFeedbackForPublishedMsg } from '../utils/getChatFeedbackForPublishedMsg'
import { getChatGenericErrorMessage } from '../utils/getChatGenericErrorMessage'
import { getChatNoArcMapMessage } from '../utils/getChatNoArcMapMessage'
import { getChatNoBlueprintMessage } from '../utils/getChatNoBlueprintMessage'
import { getChatNoGitRepoUrlMessage } from '../utils/getChatNoGitRepoUrlMessage'
import { getChatNoPublishedMessage } from '../utils/getChatNoPublishedMessage'
import { isLastMessageError } from '../utils/isLastMessageError'
import { isLastMsgInChat } from '../utils/isMessageInChat'

const FIRST_MSG_TO_AI =
  'Identify any missing or partial implementation and breakdown the required tasks to complete the implementation'

type UseManageChatBotProps = Pick<UseFormReturn, 'watch'> & {
  /** The ref for the configure Git Repo Url button. */
  gitRepoUrlBtnRef?: RefObject<HTMLButtonElement>
  /** Indicates if there are changes in the preview. */
  hasPreviewChanges: boolean
  /** Any outer loading that will cause the chat */
  isOuterLoading?: boolean
  /** The preview data - array of folder/files. */
  previewData?: FolderOrFileStructure[]
  /** The ref for the Publish button. */
  publishBtnRef?: RefObject<HTMLButtonElement>
  /** The ref for the Select AI Blueprint button. */
  selectAiBlueprintBtnRef?: RefObject<HTMLButtonElement>
  /** The ref for the Select Arc Map button. */
  selectArcMapBtnRef?: RefObject<HTMLButtonElement>
  /** The ref for the Select Branch button. */
  selectBranchBtnRef?: RefObject<HTMLButtonElement>
}

type ChatState = {
  /** Indicates if there is an ArcMap item selected. */
  hasArcMap: boolean
  /** Indicates if there is a Blueprint selected. */
  hasBlueprints: boolean
  /** Indicates if there is a Branch selected. */
  hasBranch?: boolean
  /** Indicates if there is a Commit Sha. */
  hasCommitSha: boolean
  /** Indicates if there is a Git Repository configured.. */
  hasGitRepoUrl: boolean
  /** Indicates if there is a preview data. */
  hasPreview: boolean
  /** Indicates if there are changes in the preview. */
  hasPreviewChanges: boolean
}

type MessageHandler = {
  /** The condition for the message. */
  condition: (state: ChatState) => boolean
  /** Method to get a feedback message. */
  feedback?: () => any
  /** Method to get the expected message. */
  getMessage: () => any
  /** The message ID. */
  id: ChatBotMessageIds | string
}

/**
 * Manages the chat bot utilities.
 */
export const useManageChatBot = (props: UseManageChatBotProps) => {
  const {
    gitRepoUrlBtnRef,
    hasPreviewChanges,
    isOuterLoading,
    previewData,
    publishBtnRef,
    selectAiBlueprintBtnRef,
    selectArcMapBtnRef,
    selectBranchBtnRef,
    watch,
  } = props

  // States.
  const [openChat, setOpenChat] = useState<boolean>(true)
  const [currStep, setCurrStep] = useState<number>(-1)

  // Chat states.
  const { handleSend, isLoading, messages, setLoadingState } =
    useChatBotContext()

  // Change chat loading state based on the outer loading.
  useEffect(() => {
    if (typeof isOuterLoading === 'boolean') setLoadingState(isOuterLoading)
  }, [isOuterLoading])

  // Mutation hook.
  const { mutateAsync } = usePromptAiAssistant()

  // Field values.
  const selectedBlueprints = watch(AiBlueprintPreviewFormEnum.BLUEPRINTS)
  const selectedArcMapNode = watch(AiBlueprintPreviewFormEnum.ARC_MAP_NODE)
  const selectedBranch = watch(AiBlueprintPreviewFormEnum.BRANCH)
  const commitSha = watch(AiBlueprintPreviewFormEnum.COMMIT_SHA)

  // Compute chat state based on field values.
  const chatState = useMemo(
    () => ({
      hasBlueprints: !!selectedBlueprints?.length,
      hasArcMap: !!selectedArcMapNode?.id,
      hasGitRepoUrl: !!selectedArcMapNode?.gitRepositoryUrl,
      hasPreviewChanges,
      hasPreview: !!previewData && !!previewData.length,
      hasCommitSha: !!commitSha,
    }),
    [
      commitSha,
      hasPreviewChanges,
      previewData,
      selectedArcMapNode,
      selectedBlueprints,
      selectedBranch,
    ],
  )

  // Mutation to prompt AI assistant.
  const promptAiAssistant = useCallback(
    async (message: string) => {
      const { aggregateId, id, type } = selectedArcMapNode || {}
      try {
        const result = await mutateAsync({
          aggregateId,
          codeGenerationContext: type,
          commitSha,
          contextItemId: id,
          branch: selectedBranch,
          message,
          previousMessages: messages,
        })
        return result?.data?.value
      } catch (e) {
        return getChatGenericErrorMessage()
      }
    },
    [commitSha, messages, mutateAsync, selectedArcMapNode, selectedBranch],
  )

  // Define message sequence with conditions.
  const messageHandlers: MessageHandler[] = useMemo(
    () => [
      {
        id: ChatBotMessageIds.NO_BLUEPRINT,
        condition: (state) => !state.hasBlueprints,
        getMessage: getChatNoBlueprintMessage,
        feedback: getChatFeedbackForBlueprintMsg,
      },
      {
        id: ChatBotMessageIds.NO_ARC_MAP,
        condition: (state) => state.hasBlueprints && !state.hasArcMap,
        getMessage: getChatNoArcMapMessage,
        feedback: getChatFeedbackForArcMapMsg,
      },
      {
        id: ChatBotMessageIds.NO_GIT_REPO_URL,
        condition: (state) =>
          state.hasBlueprints && state.hasArcMap && !state.hasGitRepoUrl,
        getMessage: getChatNoGitRepoUrlMessage,
        feedback: getChatFeedbackForGitRepoUrlMsg,
      },
      {
        // 'Not published' or 'preview ready' message.
        id: ChatBotMessageIds.NOT_PUBLISHED,
        condition: (state) =>
          state.hasBlueprints &&
          state.hasArcMap &&
          state.hasGitRepoUrl &&
          state.hasPreview &&
          state.hasPreviewChanges &&
          !state.hasCommitSha,
        getMessage: getChatNoPublishedMessage,
        feedback: getChatFeedbackForPublishedMsg,
      },
      {
        id: ChatBotMessageIds.FIRST_MSG_TO_AI,
        condition: (state) =>
          state.hasBlueprints &&
          state.hasArcMap &&
          state.hasGitRepoUrl &&
          state.hasPreview &&
          state.hasCommitSha &&
          !messages.some((msg) =>
            msg.id?.includes(ChatBotMessageIds.FIRST_MSG_TO_AI),
          ),
        getMessage: async () => {
          const result = await promptAiAssistant(FIRST_MSG_TO_AI)
          return {
            ...(!!result && {
              ...result,
              id: result?.id?.includes(ChatBotMessageIds.GENERIC_ERROR)
                ? result.id
                : ChatBotMessageIds.FIRST_MSG_TO_AI + new Date(),
            }),
          }
        },
      },
    ],
    [messages, promptAiAssistant],
  )

  // Check if a required data is missing and provide the proper AI message.
  const getFirstMissingRequirement = useCallback(
    (state: ChatState) => {
      for (let i = 0; i < messageHandlers.length; i++) {
        const handler = messageHandlers[i]
        // If a condition match return the whole handler:
        // Used to check if a required data is missing.
        if (handler?.condition(state)) {
          return { handler, index: i }
        }
      }

      return null
    },
    [messageHandlers],
  )

  // Send chat message check for missing required data.
  const handleChatSend = (message: string) => {
    const { handler: missingRequirement } =
      getFirstMissingRequirement(chatState) || {}

    // Check a missing requirement before sending the user's message.
    if (missingRequirement) {
      // If there's a missing requirement, show that message instead
      handleSend({
        getResponse: missingRequirement.getMessage,
        message,
        type: MessageTypeEnum.USER,
      })
      return
    }

    // If all requirements are met, proceed with the original message.
    handleSend({
      getResponse: () => promptAiAssistant(message),
      message,
      type: MessageTypeEnum.USER,
    })
  }

  // Process the message/action to be displayed.
  useEffect(() => {
    if (!openChat) return

    const result = getFirstMissingRequirement(chatState)
    if (!result) return

    const { handler: missingRequirement, index: nextStepIndex } = result

    // Send feedback message (if any).
    // A feedback msg should be rendered before any other
    // requirement message.
    if (
      currStep > -1 &&
      currStep < nextStepIndex &&
      !!messageHandlers[currStep]?.feedback
    ) {
      handleSend({
        getResponse: messageHandlers[currStep].feedback,
      })
      setCurrStep(nextStepIndex)
      return
    }

    // Handle new requirement message.
    const shouldSendNewMessage =
      !isLastMsgInChat(messages, missingRequirement.id) &&
      !isLoading &&
      !isLastMessageError(messages)

    if (shouldSendNewMessage) {
      handleSend({
        getResponse: missingRequirement.getMessage,
      })

      if (currStep !== nextStepIndex) {
        setCurrStep(nextStepIndex)
      }
    }
  }, [chatState, currStep, isLoading, messages, messageHandlers, openChat])

  // Handler for open the chat.
  const handleOpenChat = () => {
    setOpenChat(true)
  }

  // Handler for close the chat.
  const handleCloseChat = () => {
    setOpenChat(false)
  }

  const handleToggleChat = () => {
    setOpenChat((prevState) => !prevState)
  }

  // Handler for the chat option selection.
  const handleChatOptionSelect = (optionId: string, optionLabel: string) => {
    if (optionId === ChatBotMessageIds.NO_BLUEPRINT_BTN)
      return selectAiBlueprintBtnRef?.current?.click()

    if (optionId === ChatBotMessageIds.NO_ARC_MAP_BTN)
      return selectArcMapBtnRef?.current?.click()

    if (optionId === ChatBotMessageIds.NO_GIT_REPO_URL_BTN)
      return gitRepoUrlBtnRef?.current?.click()

    if (optionId === ChatBotMessageIds.NO_BRANCH_BTN)
      return selectBranchBtnRef?.current?.click()

    if (optionId === ChatBotMessageIds.NOT_PUBLISHED_BTN)
      return publishBtnRef?.current?.click()

    if (!!optionId && !!optionLabel)
      return handleSend({
        getResponse: () => promptAiAssistant(optionLabel),
        message: optionLabel,
        type: MessageTypeEnum.USER,
      })
  }

  return {
    handleChatOptionSelect,
    handleChatSend,
    handleCloseChat,
    handleOpenChat,
    handleToggleChat,
    openChat,
  }
}
