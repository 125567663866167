import { LoadingButton, type LoadingButtonProps } from '@mui/lab'
import { Tooltip, type TooltipProps } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { forwardRef, type ComponentProps, type ComponentType } from 'react'

export const ActionButtonClasses =
  'h-8 w-8 min-w-8 p-0 enabled:border-secondary-200'

export type ActionIconButtonProps<I = ComponentProps<any>> = Pick<
  LoadingButtonProps,
  'className' | 'color' | 'disabled' | 'loading' | 'onClick'
> & {
  /** The `LoadingButton` component props. */
  buttonProps?: LoadingButtonProps
  /** The icon component to be rendered. */
  Icon: ComponentType<I>
  /** The `Icon` component props. */
  iconProps?: I
  /** The tooltip placement prop. */
  tooltipPlacement?: NonNullable<TooltipProps['placement']>
  /** The `Tooltip` component props. */
  tooltipProps?: TooltipProps
  /** The tooltip title prop. */
  tooltipTitle?: NonNullable<TooltipProps['title']>
}

/**
 * The action icon button component.
 */
const ActionIconButton = forwardRef<HTMLButtonElement, ActionIconButtonProps>(
  (props: ActionIconButtonProps, ref) => {
    const {
      buttonProps,
      className,
      color,
      disabled,
      Icon,
      iconProps,
      loading,
      onClick,
      tooltipPlacement,
      tooltipProps,
      tooltipTitle,
    } = props

    if (!Icon) return null

    return (
      <LoadingButton
        className={twMerge(ActionButtonClasses, className)}
        color={color}
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        ref={ref}
        variant="outlined"
        {...buttonProps}
      >
        {!loading && (
          <Tooltip
            arrow
            placement={tooltipPlacement}
            title={tooltipTitle}
            {...tooltipProps}
          >
            <span className="flex">
              <Icon className="m-1" {...iconProps} />
            </span>
          </Tooltip>
        )}
      </LoadingButton>
    )
  },
)

export default ActionIconButton
