import { CodeSimple } from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'
import type { MouseEvent } from 'react'
import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'
import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'
import { highlightFilteredValue } from '~/utils/strings/highlightFilteredValue'
import { TreeItem } from '../../TreeView.styles'
import { getIsSelectableItem } from './utils/getIsSelectableItem'
import { getNodeIcon } from './utils/getNodeIcon'

type ArcMapTreeItemProps = {
  /** The filtered value to check if it has a match with the node label. */
  filterInputValue: string
  /** The current tree view node. */
  node: ArcMapTreeViewData
  /**
   * The callback handler for an item click.
   * It will be only called when the item is a "selectable" one.
   */
  onItemClick: (node: ArcMapTreeViewData) => void
  /** The callback handler for an item toggle (expand/collapse). */
  onItemToggle: (nodeId: GUID) => void
}

/**
 * The tree item component for the
 * ArcMap tree view.
 */
const ArcMapTreeItem = (props: ArcMapTreeItemProps) => {
  const { filterInputValue, node, onItemClick, onItemToggle } = props

  const aggregateId =
    node.type === ArcMapTreeNodeType.AGGREGATE ? node.id : node.aggregateId

  // Indicates if it is a selectable node.
  const isSelectable = getIsSelectableItem(node.type)

  const handleItemToggle = (e: MouseEvent) => {
    e.stopPropagation()
    if (!!node?.children?.length) onItemToggle(node.id)
  }

  const handleItemClick = (e: MouseEvent) => {
    e.stopPropagation()

    if (isSelectable) {
      onItemClick({ ...node, aggregateId })
    } else {
      // Call the toggle callback when the is not selectable.
      handleItemToggle(e)
    }
  }

  return (
    <TreeItem
      key={node.id}
      label={
        <div
          className="flex items-center gap-1 whitespace-nowrap"
          role="button"
        >
          <span className="flex items-center" onClick={handleItemToggle}>
            {getNodeIcon(node.type)}
          </span>

          <span
            className={twMerge(
              'peer',
              'items-center gap-0 italic text-neutral-high-200',
              isSelectable &&
                'not-italic text-neutral-high-300 hover:text-highlight-200 hover:underline',
            )}
            onClick={handleItemClick}
          >
            {highlightFilteredValue(node.label, filterInputValue)}
          </span>

          {isSelectable && (
            <div
              className={twMerge(
                'hidden h-[16px] w-[16px] items-center justify-center rounded bg-highlight-200 p-[2px]',
                'peer-hover:flex',
              )}
            >
              <CodeSimple
                className="text-neutral-100"
                size={11}
                weight="bold"
              />
            </div>
          )}
        </div>
      }
      nodeId={node.id}
      size="small"
    >
      {node.children?.map((child) => (
        <ArcMapTreeItem
          filterInputValue={filterInputValue}
          key={child.id}
          node={child}
          onItemClick={onItemClick}
          onItemToggle={onItemToggle}
        />
      ))}
    </TreeItem>
  )
}

export default ArcMapTreeItem

const isTextClick = (target: HTMLElement) =>
  target.hasAttribute('data-label') || target.closest('[data-label]')

const isIconClick = (target: HTMLElement) =>
  target.hasAttribute('data-toggle-icon') ||
  target.closest('[data-toggle-icon]') ||
  target.hasAttribute('data-node-icon') ||
  target.closest('[data-node-icon]')
