import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { DriveFileMove } from '@mui/icons-material'
import { useState } from 'react'
import { useMoveFiles } from '~/hooks/api/codeGenerationStrategy/useMoveFiles'
import { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { getNodeById } from '../../utils/utils'
import { RenderDraggableTreeFilePath } from './RenderDraggableTreeFilePath'
import { type TreeItemLabelProps } from './TreeItemLabel'

type DraggableTreeProps = Partial<TreeItemLabelProps> & {
  generationStrategyId?: string
  selectedTreeItem: string[]
  setSelectedTreeItem: (selectedTreeItem: string[]) => void
  dataDirectoryItems: FolderOrFileStructure
}

/**
 * Renders the file path `tree items`.
 */
export const DraggableTree = (props: DraggableTreeProps) => {
  const {
    domainDictionaryParams,
    filteredValue,
    highlightCondition,
    node,
    generationStrategyId,
    selectedTreeItem,
    setSelectedTreeItem,
    dataDirectoryItems,
  } = props

  const [activeId, setActiveId] = useState<string | null>(null)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor),
  )

  const handleDragStart = (event: DragStartEvent) => {
    // if dragged itens is part of selection, move all selected items
    if (!selectedTreeItem.includes(event.active.id as string)) {
      setSelectedTreeItem([event.active.id as string])
    }

    setActiveId(event.active.id as string)
  }

  const { mutateAsync: moveFilesAsync } = useMoveFiles(generationStrategyId!)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over) return

    const activeNode = getNodeById(active.id as string, dataDirectoryItems)
    const overNode = getNodeById(over.id as string, dataDirectoryItems)

    if (!activeNode || !overNode || !overNode.children) return

    // If dragged item is part of selection, move all selected items
    const itemsToMove = selectedTreeItem.includes(active.id as string)
      ? selectedTreeItem.map((id) => getNodeById(id, dataDirectoryItems))
      : [activeNode]

    itemsToMove.forEach(async (item) => {
      if (
        item &&
        item.path &&
        item.path !== overNode.path &&
        item.id !== 'root'
      ) {
        await moveFilesAsync({
          fromPath: item.path,
          toPath:
            overNode.path === '' ? item.name : overNode.path + '/' + item.name,
        })
      }
    })

    setActiveId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <RenderDraggableTreeFilePath
        domainDictionaryParams={domainDictionaryParams}
        filteredValue={filteredValue}
        highlightCondition={highlightCondition}
        node={node}
      />
      <DragOverlay>
        {activeId ? (
          <div className="align-center mx-auto flex w-1/2 rounded-lg bg-secondary p-1 text-white opacity-80">
            <DriveFileMove /> {selectedTreeItem.length}{' '}
            {selectedTreeItem.length === 1 ? 'item' : 'items'}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}
