import { ArrowBack, ArrowForward, Autorenew, Search } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'
import { MenuToReplaceText } from '~/routes/configuration/code-generation-strategies/edit/components/MenuToReplaceText/MenuToReplaceText'
import { useMenuReplaceText } from '~/routes/configuration/code-generation-strategies/edit/components/ValueBindingTreeItem/useMenuReplaceText'
import {
  ResponseListParametersByGenerationId,
  TextSection,
} from '~/services/GenerationStrategy.types'
import SearchResultPreview from './search-result-preview'

interface FindReplaceComponentProps {
  items: TextSection[]
  dataDomainDictionary: ResponseListParametersByGenerationId[]
  onUpdate: (
    updatedItem: TextSection,
    callbacks?: {
      onSuccess?: (item: any) => void
      onError?: (error: any) => void
    },
  ) => void
  selectedDomainDictionaryItem?: string
}

interface Match {
  itemId: string
  index: number
  length: number
  value: string
}

export default function FindReplaceComponent({
  items,
  onUpdate,
  dataDomainDictionary = [],
  selectedDomainDictionaryItem,
}: FindReplaceComponentProps) {
  // Original state
  const [findText, setFindText] = useState('')
  const [replaceText, setReplaceText] = useState('')
  const [caseSensitive, setCaseSensitive] = useState(false)
  const [wordBoundary, setWordBoundary] = useState(false)
  const [useRegex, setUseRegex] = useState(false)
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)

  // Context menu state
  const [configForMenuToReplace, setConfigForMenuToReplace] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)

  // Refs for text fields
  const findFieldRef = useRef<HTMLTextAreaElement>(null)
  const replaceFieldRef = useRef<HTMLTextAreaElement>(null)

  // Form value management for the context menu
  const watchForm = useCallback(
    (fieldName: string) => {
      // Simple form watching implementation
      return fieldName === 'parameters' ? '[]' : findText
    },
    [findText],
  )

  const setFormValue = useCallback((fieldName: string, value: string) => {
    if (fieldName === 'value') {
      setReplaceText(value)
    }
  }, [])

  // Initialize the context menu hook
  const { handleContextMenu, handleContextMenuClose, handleMenuItemClick } =
    useMenuReplaceText({
      dataDomainDictionary,
      fieldRef: replaceFieldRef,
      selectedDomainDictionaryItem,
      setConfigForMenuToReplace,
      setFormValue,
      watchForm,
      handleFormSubmit: (data) => {
        // Handle the form submission if needed
      },
    })

  const matches = useMemo(() => {
    if (!findText) return []

    const allMatches: Match[] = []

    const flags = caseSensitive ? 'g' : 'gi'
    let pattern = useRegex
      ? findText
      : findText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    if (wordBoundary && !useRegex) {
      pattern = `\\b${pattern}\\b`
    }

    try {
      const regex = new RegExp(pattern, flags)
      items.forEach((item) => {
        let match
        while ((match = regex.exec(item.value!)) !== null) {
          allMatches.push({
            itemId: item.id,
            index: match.index,
            length: match[0].length,
            value: item.value?.toString()!,
          })
          if (!regex.global) break
        }
      })
      return allMatches
    } catch (e) {
      return []
    }
  }, [items, findText, caseSensitive, wordBoundary, useRegex])

  const handleNext = useCallback(() => {
    if (matches.length === 0) return
    setCurrentMatchIndex((prev) => (prev + 1) % matches.length)
  }, [matches.length])

  const handlePrevious = useCallback(() => {
    if (matches.length === 0) return
    setCurrentMatchIndex((prev) => (prev - 1 + matches.length) % matches.length)
  }, [matches.length])

  const handleReplace = useCallback(() => {
    if (matches.length === 0 || !findText) return

    const match = matches[currentMatchIndex]
    if (!match) return

    const item = items.find((item) => item.id === match.itemId)
    if (!item) return

    const newValue =
      match.value.slice(0, match.index) +
      replaceText +
      match.value.slice(match.index + match.length)

    onUpdate({
      ...item,
      value: newValue,
    })
  }, [matches, currentMatchIndex, findText, replaceText, items, onUpdate])

  const handleReplaceAll = useCallback(async () => {
    if (!findText) return
    setIsProcessing(true)

    try {
      const flags = caseSensitive ? 'g' : 'gi'
      let pattern = useRegex
        ? findText
        : findText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

      if (wordBoundary && !useRegex) {
        pattern = `\\b${pattern}\\b`
      }

      const regex = new RegExp(pattern, flags)
      const itemsToUpdate = new Set(matches.map((match) => match.itemId))

      for (const itemId of Array.from(itemsToUpdate)) {
        const item = items.find((item) => item.id === itemId)
        if (!item) continue

        const newValue = item?.value?.replace(regex, replaceText)

        try {
          await new Promise((resolve, reject) => {
            onUpdate(
              {
                ...item,
                value: newValue,
              },
              {
                onSuccess: (value) => resolve(value),
                onError: reject,
              },
            )
          })
        } catch (updateError) {
          console.error('Failed to update item:', itemId, updateError)
          continue
        }
      }
    } catch (e) {
      // Do nothing if regex is invalid
    } finally {
      setIsProcessing(false)
    }
  }, [
    items,
    findText,
    replaceText,
    caseSensitive,
    wordBoundary,
    useRegex,
    matches,
    onUpdate,
  ])

  const matchesByItem = useMemo(() => {
    const grouped = new Map<string, Match[]>()
    matches.forEach((match) => {
      const existing = grouped.get(match.itemId) || []
      grouped.set(match.itemId, [...existing, match])
    })
    return grouped
  }, [matches])

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Find"
          value={findText}
          onChange={(e) => setFindText(e.target.value)}
          placeholder={useRegex ? 'Regular expression...' : 'Text to find...'}
          size="small"
          inputRef={findFieldRef}
          InputProps={{
            startAdornment: <Search sx={{ color: 'action.active', mr: 1 }} />,
          }}
        />

        <TextField
          fullWidth
          label="Replace"
          value={replaceText}
          onChange={(e) => setReplaceText(e.target.value)}
          placeholder="Replace with..."
          size="small"
          inputRef={replaceFieldRef}
          onContextMenu={(e) => handleContextMenu(e)}
          InputProps={{
            startAdornment: (
              <Autorenew sx={{ color: 'action.active', mr: 1 }} />
            ),
          }}
        />

        <MenuToReplaceText
          dataDomainDictionary={dataDomainDictionary}
          onMenuClose={handleContextMenuClose}
          onMenuItemClick={handleMenuItemClick}
          options={configForMenuToReplace}
        />

        {/*<Menu
          open={configForMenuToReplace !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            configForMenuToReplace !== null
              ? {
                top: configForMenuToReplace.mouseY,
                left: configForMenuToReplace.mouseX,
              }
              : undefined
          }
        >
          {dataDomainDictionary.map((item) => (
            <MenuItem
              key={item.key}
              onClick={handleMenuItemClick}
              data-value={item.key}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>*/}

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={caseSensitive}
                onChange={(e) => setCaseSensitive(e.target.checked)}
              />
            }
            label="Case sensitive"
          />

          <FormControlLabel
            control={
              <Switch
                checked={wordBoundary}
                onChange={(e) => setWordBoundary(e.target.checked)}
                disabled={useRegex}
              />
            }
            label="Whole words"
          />

          <FormControlLabel
            control={
              <Switch
                checked={useRegex}
                onChange={(e) => setUseRegex(e.target.checked)}
              />
            }
            label="Regex"
          />
        </Box>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handlePrevious}
              disabled={matches.length === 0}
            >
              <ArrowBack />
            </Button>

            <Typography
              variant="body2"
              sx={{ minWidth: 80, textAlign: 'center' }}
            >
              {matches.length > 0
                ? `${currentMatchIndex + 1} of ${matches.length}`
                : 'No matches'}
            </Typography>

            <Button
              variant="outlined"
              size="small"
              onClick={handleNext}
              disabled={matches.length === 0}
            >
              <ArrowForward />
            </Button>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="contained"
              onClick={handleReplace}
              disabled={matches.length === 0 || isProcessing}
            >
              Replace
            </Button>
            <Button
              variant="contained"
              onClick={handleReplaceAll}
              disabled={matches.length === 0 || isProcessing}
              startIcon={
                isProcessing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {isProcessing ? 'Processing...' : 'Replace All'}
            </Button>
          </Box>
        </Stack>

        {matches.length > 0 && (
          <>
            <Divider />
            <Typography variant="subtitle2" gutterBottom>
              Matches Preview
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {Array.from(matchesByItem.entries()).map(
                ([itemId, itemMatches]) => {
                  const item = items.find((i) => i.id === itemId)
                  if (!item) return null

                  return (
                    <Box
                      key={itemId}
                      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        {itemMatches.length}{' '}
                        {itemMatches.length === 1 ? 'match' : 'matches'} in item
                      </Typography>
                      {itemMatches.map((match, idx) => (
                        <SearchResultPreview
                          key={`${match.itemId}-${match.index}`}
                          match={match}
                          isActive={matches[currentMatchIndex] === match}
                        />
                      ))}
                    </Box>
                  )
                },
              )}
            </Box>
          </>
        )}
      </Stack>
    </Paper>
  )
}
