import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import { renameFileOrFolder } from '~/services/GenerationStrategy'

interface DialogRenameProps {
  isDialogOpen: boolean
  handleCloseDialog: () => void
  generationStrategyId?: string
  currentPath?: string
  currentName?: string
}

export function DialogRename({
  isDialogOpen,
  handleCloseDialog,
  generationStrategyId,
  currentPath,
  currentName,
}: DialogRenameProps) {
  const [newName, setNewName] = useState(currentName || '')
  const [error, setError] = useState('')
  const queryClient = useQueryClient()

  useEffect(() => {
    setNewName(currentName || '')
  }, [currentName])

  const { mutate: renameItem, isPending } = useMutation({
    mutationFn: renameFileOrFolder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeysEnum.GENERATION_STRATEGY_BY_ID,
          generationStrategyId,
        ],
      })
      handleCloseDialog()
      setNewName('')
      setError('')
    },
    onError: (error) => {
      setError(error.message || 'Failed to rename item')
    },
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!newName.trim()) {
      setError('Name cannot be empty')
      return
    }

    if (!generationStrategyId || !currentName) {
      setError('Missing required data')
      return
    }

    renameItem({
      generationStrategyId,
      currentPath: currentPath as string,
      currentName,
      newName: newName.trim(),
    })
  }

  const handleClose = () => {
    setNewName(currentName || '')
    setError('')
    handleCloseDialog()
  }

  return (
    <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Rename</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            type="text"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isPending || !newName.trim() || newName === currentName}
          >
            Rename
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
