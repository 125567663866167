/**
 * Routes related to `configuration`:
 * these are the sub routes that
 * together will compose the full path.
 */
export enum ConfigurationSubRoutesEnum {
  /** Base configuration route. */
  CONFIGURATION = ':organisationId/:platformId/configuration',
  /** AI Blueprint route. */
  AI_BLUEPRINT = 'code-generation-strategies',
  /** Preview route. */
  PREVIEW = 'preview/:generationStrategyId?',
}

/**
 * Routes related to `configuration`.
 * Full route path.
 */
export enum ConfigurationRoutesEnum {
  /** AI Blueprint Preview. */
  AI_BLUEPRINT_PREVIEW = `/${ConfigurationSubRoutesEnum.CONFIGURATION}/${ConfigurationSubRoutesEnum.AI_BLUEPRINT}/${ConfigurationSubRoutesEnum.PREVIEW}`,
}
