import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material'
import { CaretDown } from '@phosphor-icons/react'
import {
  useEffect,
  useState,
  type ChangeEvent,
  type MouseEvent,
  type RefObject,
} from 'react'
import { useController, type UseFormReturn } from 'react-hook-form'
import { useRepositoryGetBranches } from '~/hooks/api/developer/useRepositoryGetBranches'
import { PublishArcMapResourceFormEnum } from '~/models/enums/forms/PublishArcMapResourceFormEnum'

type SelectBranchProps = Pick<UseFormReturn, 'control'> & {
  /** The ID of the aggregate that the git repo is associate with. */
  aggregateId?: GUID | null
  /** Ref for the select button. */
  buttonRef?: RefObject<HTMLButtonElement>
  /** Indicates if the button should be disabled. */
  disabled?: boolean
  /** Callback handler for branch change. */
  onBranchChange?: (branch: string) => void
}

/**
 * The component opens the repository branches inside
 * a menu/select.
 */
export const SelectBranch = (props: SelectBranchProps) => {
  const {
    aggregateId = '',
    buttonRef,
    control,
    disabled,
    onBranchChange,
  } = props

  // States.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [customBranch, setCustomBranch] = useState<string>()

  // Open state.
  const open = Boolean(anchorEl)

  // React Hook Form.
  const {
    field: { onChange, value },
  } = useController({ control, name: PublishArcMapResourceFormEnum.BRANCH })

  // Hooks.
  const {
    data: allBranches,
    error,
    isLoading,
    refetch,
  } = useRepositoryGetBranches({
    aggregateId,
  })

  // Set default branch (main or master) when branches are loaded
  useEffect(() => {
    if (allBranches?.length && !value) {
      const defaultBranch =
        allBranches.find(
          (branch) => branch === 'main' || branch === 'master',
        ) || allBranches[0]

      onChange(defaultBranch)
      onBranchChange?.(defaultBranch)
    }
  }, [allBranches, value, onChange, onBranchChange])

  // Methods.
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)

    if (!allBranches?.length) refetch()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (branch: string) => {
    onChange(branch)
    onBranchChange?.(branch)
    handleClose()
  }

  const handleCustomBranchChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Replace any whitespace with a dash
    const newValue = event.target.value.replace(/\s+/g, '-')
    setCustomBranch(newValue)
  }

  const handleCustomBranchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' && customBranch?.trim()) {
      event.preventDefault()
      event.stopPropagation()
      handleSelect(customBranch.trim())
    }
  }

  return (
    <>
      <Button
        aria-controls={open ? 'branch-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="min-h-[36.5px] enabled:border-secondary-200"
        color="secondary"
        disabled={disabled}
        endIcon={<CaretDown size={12} weight="fill" />}
        id="branch-button"
        onClick={handleClick}
        ref={buttonRef}
        startIcon={<AccountTreeOutlinedIcon fontSize="small" />}
        variant="outlined"
      >
        {value}
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="branch-menu"
        MenuListProps={{
          'aria-labelledby': 'branch-button',
          className: 'max-w-[450px] p-0',
        }}
        onClose={handleClose}
        open={open}
      >
        <MenuItem className="p-0" onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            fullWidth
            inputProps={{
              className: 'py-2 px-4',
            }}
            InputProps={{
              className: 'bg-transparent',
              disableUnderline: true,
            }}
            onChange={handleCustomBranchChange}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={handleCustomBranchKeyDown}
            placeholder="Add custom branch"
            value={customBranch ?? ''}
            variant="standard"
          />
        </MenuItem>

        {!!isLoading && (
          <MenuItem className="whitespace-normal" onClick={handleClose}>
            <CircularProgress color="secondary" size={30} />
          </MenuItem>
        )}

        {!!error && (
          <MenuItem className="whitespace-normal" onClick={handleClose}>
            {error as string}
          </MenuItem>
        )}

        {allBranches?.map((branch: string) => (
          <MenuItem key={branch} onClick={() => handleSelect(branch)}>
            {branch}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
