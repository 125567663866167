/**
 * Enum with the form fields names
 * from `publish ArcMap resource` form.
 */
export enum PublishArcMapResourceFormEnum {
  /** The ID of the aggregate associated with the publish. */
  AGGREGATE_ID = 'aggregateId',
  /** The name of the branch where the code will be committed. */
  BRANCH = 'branch',
  /** The commit message. */
  COMMIT_MESSAGE = 'commitMessage',
  /** The ID of the developer publishing the code. */
  DEVELOPER_ID = 'developerId',
  /** The ecosystem name. */
  ECOSYSTEM_NAME = 'ecosystemName',
  /** The resource ID. */
  RESOURCE_ID = 'resourceId',
  /** The resource type (command/reaction/type/query). */
  RESOURCE_TYPE = 'resourceType',
  /** The strategy type. */
  STRATEGY_TYPE = 'strategyType',

  // ArcMap Resources.
  /** The command ID. */
  COMMAND_ID = 'commandId',
  /** The reaction ID. */
  REACTION_ID = 'reactionId',
  /** The query ID. */
  QUERY_ID = 'queryId',
  /** The type ID. */
  TYPE_ID = 'typeId',
}
