import {
  useMemo,
  type BaseSyntheticEvent,
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from 'react'
import type { FieldValues } from 'react-hook-form'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { ConfigForMenuToReplace } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import type { FormHook } from '~/models/types/hooks/FormHook'
import {
  getComponentValueKey,
  getSelectionStartEnd,
} from './ValueBindingTreeItem.utils'

type UseMenuReplaceTextProps = Partial<FormHook> &
  Pick<
    CodeGenerationStrategyBaseProps,
    'dataDomainDictionary' | 'selectedDomainDictionaryItem'
  > & {
    /**
     * In case this ref is provided, this is going to
     * replace the default feature of replace based
     * the text selection.
     * For this case, the replaced text is going to be the text
     * inside the clicked element.
     */
    changeByClickRef?: RefObject<HTMLElement>
    /** The name of the parameters field. */
    fieldParametersName?: string
    /** The name of the value field. */
    fieldValueName?: string
    /** The `inputRef` from the value field. */
    fieldRef?: RefObject<HTMLTextAreaElement>
    /** Handler for form submit. */
    handleFormSubmit: (
      data: FieldValues,
      forceSubmit?: BaseSyntheticEvent | boolean,
      bypassMenuOpenCheck?: boolean,
    ) => void
    /** The set state method for `configForMenuToReplace`. */
    setConfigForMenuToReplace: Dispatch<SetStateAction<ConfigForMenuToReplace>>
  }

type UseMenuReplaceTextHook = {
  /** Handler for context menu event. */
  handleContextMenu: (
    e: EventFor<'div', 'onContextMenu'>,
    customAnchor?: RefObject<any>,
  ) => void
  /** Handler for `context menu` close event. */
  handleContextMenuClose: () => void
  /** Handler for menu item click. */
  handleMenuItemClick: (e: EventFor<'li', 'onClick'>) => void
}

export const useMenuReplaceText = (
  props: UseMenuReplaceTextProps,
): UseMenuReplaceTextHook => {
  const {
    changeByClickRef,
    dataDomainDictionary,
    fieldParametersName = ValueBindingTreeItemFormEnum.PARAMETERS,
    fieldValueName = ValueBindingTreeItemFormEnum.VALUE,
    fieldRef,
    getFormValues,
    handleFormSubmit,
    handleSubmit,
    selectedDomainDictionaryItem,
    setConfigForMenuToReplace,
    setFormValue,
    watchForm,
  } = props

  // Get current domain dictionary item data.
  const selectedDomainDictionaryItemData = useMemo(
    () =>
      dataDomainDictionary?.find(
        (item) => item.key === selectedDomainDictionaryItem,
      ),
    [dataDomainDictionary, selectedDomainDictionaryItem],
  )
  // Form field values.
  const paramsFieldValue = watchForm?.(fieldParametersName)
  const parsedParams = JSON.parse(paramsFieldValue || '[]')

  // Handler for `context menu` event.
  const handleContextMenu = (
    event: EventFor<'div', 'onContextMenu'>,
    customAnchor?: RefObject<any>,
  ) => {
    event.preventDefault()
    event.stopPropagation()

    const textVal =
      customAnchor?.current || changeByClickRef?.current || fieldRef?.current

    if (textVal) {
      setConfigForMenuToReplace((prevState) =>
        prevState === null
          ? {
              mouseX: event.clientX + 10,
              mouseY: event.clientY + 20,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      )
    }
  }

  // Handler for `context menu` close event.
  const handleContextMenuClose = () => {
    setConfigForMenuToReplace(null)
  }

  const handleMenuClickCommonAction = (event: EventFor<'li', 'onClick'>) => {
    event.stopPropagation()
    handleContextMenuClose()
  }

  // Handles the text replace feature.
  const handleTextReplace = (text?: string, customAnchor?: RefObject<any>) => {
    const textElement = customAnchor?.current || fieldRef?.current

    // Indicates if the replace approach should be by clicking
    // not selecting a portion with the mouse:
    // In this approach the form should be submitted after the change.
    const shouldReplaceAndSubmit = !!customAnchor?.current

    if (textElement) {
      const paramKeyToReplace = text || selectedDomainDictionaryItemData?.key
      if (!paramKeyToReplace) return

      // Add to the table in case it doesn't exist.
      const indexOfParamKeyInParamsAdded =
        parsedParams.indexOf(paramKeyToReplace)
      if (indexOfParamKeyInParamsAdded === -1) {
        setFormValue?.(
          fieldParametersName,
          JSON.stringify([...parsedParams, paramKeyToReplace]),
          { shouldDirty: true },
        )
      }

      // Get the proper value key: this is necessary when the field
      // is a `content editable` component not an input.
      let valueKey = getComponentValueKey(textElement)

      const targetText = textElement[valueKey] as string

      let replacedText: string

      if (shouldReplaceAndSubmit) {
        // Remove curly braces from target text if present:
        // This is necessary because the text bindings are between curly braces.
        const isWrapped = targetText.startsWith('{') && targetText.endsWith('}')
        const cleanTargetText = isWrapped
          ? targetText.slice(1, -1) // Remove first and last characters
          : targetText

        const currentFormValue = getFormValues?.(fieldValueName) as string

        // Find and replace the part that matches targetText in the form value.
        const startIndex = currentFormValue.indexOf(cleanTargetText)
        if (startIndex !== -1) {
          replacedText =
            currentFormValue.substring(0, startIndex) +
            `${paramKeyToReplace}` +
            currentFormValue.substring(startIndex + cleanTargetText.length)
        } else {
          // If no match found, don't replace anything
          return
        }
      } else {
        // Get the cursor position.
        const { selectionStart: cursorStart, selectionEnd: cursorEnd } =
          getSelectionStartEnd(textElement)

        // Replace the text either way
        const beginText = targetText?.substring(0, cursorStart)
        const endText = targetText?.substring(cursorEnd, targetText.length)
        replacedText = `${beginText}{${paramKeyToReplace}}${endText}`
      }

      // Change the form field value to replaced content.
      setFormValue?.(fieldValueName, replacedText, {
        shouldDirty: true,
      })

      if (shouldReplaceAndSubmit) {
        setTimeout(() => {
          handleSubmit?.((data) => handleFormSubmit?.(data, true, true))()
        }, 0)
      }
    }
  }

  // Handler for menu item click.
  const handleMenuItemClick = (event: EventFor<'li', 'onClick'>) => {
    const buttonValue = event.currentTarget.dataset.value

    if (buttonValue) handleTextReplace(buttonValue, changeByClickRef)

    handleMenuClickCommonAction(event)
  }

  return {
    handleContextMenu,
    handleContextMenuClose,
    handleMenuItemClick,
  }
}
