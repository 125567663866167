import { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

/**
 * Finds multiple nodes in a tree structure by their IDs.
 *
 * @param targetIds - Array of IDs to search for.
 * @param data - The tree structure to search in.
 * @returns Array of found nodes matching the provided IDs.
 *
 * @example
 * const nodes = getNodesById(['id1', 'id2'], treeData)
 */
export const getNodesById = (
  targetIds?: string[],
  data?: FolderOrFileStructure,
): FolderOrFileStructure[] => {
  if (!data || !targetIds?.length) return []

  const results: FolderOrFileStructure[] = []

  // Check current node.
  if (targetIds.includes(data.id)) results.push(data)

  // Check children recursively.
  if (data.children) {
    for (const child of data.children) {
      const childResults = getNodesById(targetIds, child)
      results.push(...childResults)
    }
  }

  return results
}
