import { useEffect, useState } from 'react'
import type { CodePreviewBaseProps } from '~/models/types/components/codePreview/CodePreviewBaseProps'
import type {
  FileStructure,
  FolderOrFileStructure,
} from '~/services/GenerationStrategy.types'
import { findTreeNodeByFilePath, findTreeNodeById } from './CodePreview.utils'

type UseCodePreviewProps = Pick<CodePreviewBaseProps, 'filesTreeData'>

/**
 * Provides resources for `CodePreview` component.
 */
export const useCodePreview = (props: UseCodePreviewProps) => {
  const { filesTreeData } = props

  // States.
  const [selectedFile, setSelectedFile] = useState<FolderOrFileStructure>()

  // Lifecycle: reset or re-select the selected file.
  useEffect(() => {
    const fileItem = (selectedFile as FileStructure)?.file

    if (!!filesTreeData?.length && !!fileItem) {
      const { filePath } = fileItem || {}
      const file = findTreeNodeByFilePath(
        filesTreeData,
        filePath,
      ) as FileStructure
      setSelectedFile(file)
    }
  }, [filesTreeData])

  const handleFileClick = (fileId?: string) => {
    const file = findTreeNodeById(filesTreeData, fileId) as FileStructure
    setSelectedFile(file)
  }

  return {
    handleFileClick,
    selectedFile,
  }
}
