import { Box, styled } from '@mui/material'

export const ScrollableContainer = styled('div')(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[300],
    borderRadius: 3,
  },
}))

export const LoadingDots = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '& > div': {
    animation: 'pulse 1.4s infinite',
    fontSize: '0.5rem',
    '@keyframes pulse': {
      '0%, 100%': {
        opacity: 0.5,
      },
      '50%': {
        opacity: 1,
      },
    },
  },
}))
