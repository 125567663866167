import CloudIcon from '@mui/icons-material/Cloud'
import FolderIcon from '@mui/icons-material/Folder'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import type {
  FileStructure,
  FolderOrFileStructure,
} from '~/services/GenerationStrategy.types'

export type TreeItemLabelIconProps = { node: FolderOrFileStructure }

/**
 * The icon to be displayed in the
 * tree item label.
 */
export const TreeItemLabelIcon = (props: TreeItemLabelIconProps) => {
  const { node } = props

  const name = node.name
  const isFile = !!node.fileId || !!(node as FileStructure).file

  const iconProps = {
    className: 'text-base',
  }

  if (name === 'root') return <CloudIcon {...iconProps} />

  if (!isFile) return <FolderIcon {...iconProps} />

  return <InsertDriveFileOutlinedIcon {...iconProps} />
}
