import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import { ConfigureGitRepoFormEnum } from '~/models/enums/forms/ConfigureGitRepoFormEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import type { PayloadConfigureGitRepo } from '~/services/GenerationStrategy.types'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to configure a git repository.
 */
export const useConfigureGitRepo = () => {
  const { platformId } = useParams() || {}

  const queryClient = useQueryClient()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationKey: [QueryKeysEnum.LOAD_GIT_REPOSITORY],
    mutationFn: (fieldValues: FieldValues) => {
      const aggregateId = fieldValues[ConfigureGitRepoFormEnum.AGGREGATE_ID]
      const repositoryUrl = fieldValues[ConfigureGitRepoFormEnum.REPOSITORY_URL]
      const token = fieldValues[ConfigureGitRepoFormEnum.TOKEN]

      invariant(aggregateId, ERROR_MESSAGES.aggregateId)
      invariant(repositoryUrl, ERROR_MESSAGES.repositoryUrl)
      invariant(token, ERROR_MESSAGES.authenticationToken)

      const url = DevelopmentAggregateAPI.ConfigureGitRepo

      const postData: PayloadConfigureGitRepo = {
        aggregateId,
        repositoryUrl,
        token,
      }

      return serviceDevelopmentAggregate.post(url, postData)
    },
    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['boundedContexts', { platformId }],
      }),
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
