import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { RouterProvider } from 'react-router-dom'
import { FirstLoading } from '~/components/FirstLoading'
import { msalConfig } from '~/config/auth'
import { useRouter } from '~/config/routes'
import { useGlobalColorMode } from '~/hooks/useGlobalColorMode'
import AppPopperContextProvider from './providers/AppPopperContextProvider'
import AppSnackbarContextProvider from './providers/AppSnackbarContextProvider'
import ChatBotContextProvider from './providers/ChatBotContextProvider'
import ReactFlowStatesContextProvider from './providers/ReactFlowStatesContextProvider'

/**
 * The following mocking code was commented out:
 * - It was causing an issue on Windows machine, possibly because of the import path;
 * - Also, the condition was never really running due to the hardcoded `false` on the if statement.
 */
// // ! How to use mocked data
// // * Remove the "false" in the condition below
// // * Check ./mocks/handlers.js to see if the mocked data is what you need
// // ? In case it differs too much from what you need, create another json data and follow the folder structure
// // ? To verify it's running in the browser, the requests that are captured by MSW will appear as
// // ? MSW in the X-Powered-By header and also should show in the status code as "from service worker"
// // eslint-disable-next-line no-constant-condition
// if (import.meta.env.DEV && false) {
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   const msw = await import('./mocks/browser.ts')
//   msw.worker.start({
//     // quiet: true,
//     onUnhandledRequest: 'bypass',
//   })
// }

// Query Client (React Query)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: 'always',
    },
  },
})

// Public Client Application (MSAL)
const msalInstance = new PublicClientApplication(msalConfig)

// ! Msal config
// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] as AccountInfo)
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
msalInstance.addEventCallback((event: any) => {
  const account = event?.payload?.account
  if (account && event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(account)
  }
})

const clickPluginInstance = new ClickAnalyticsPlugin()
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
}

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=db0a9b0b-fed6-4dc7-b181-a3b60059aceb;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
    extensions: [clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
})

if (!import.meta.env.DEV) {
  appInsights.loadAppInsights()
  appInsights.trackPageView()
}

export function App() {
  const theme = useGlobalColorMode()
  const router = useRouter({ queryClient, msalInstance })

  console.log('environment mode:', import.meta.env.MODE)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <AppSnackbarContextProvider>
                <AppPopperContextProvider>
                  <ChatBotContextProvider>
                    <ReactFlowStatesContextProvider>
                      <RouterProvider
                        router={router}
                        fallbackElement={<FirstLoading />}
                      />

                      <ReactQueryDevtools
                        buttonPosition="bottom-right"
                        initialIsOpen={false}
                        position="bottom"
                      />
                    </ReactFlowStatesContextProvider>
                  </ChatBotContextProvider>
                </AppPopperContextProvider>
              </AppSnackbarContextProvider>
            </DndProvider>
          </QueryClientProvider>
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
