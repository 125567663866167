import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm, type FieldValues, type UseFormReturn } from 'react-hook-form'
import { usePublishArcMapResource } from '~/hooks/api/developer/usePublishArcMapResource'
import { useSelectGenerationStrategies } from '~/hooks/api/developer/useSelectGenerationStrategies'
import { useChatBotContext } from '~/hooks/contexts/useChatBotContext'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { useGetUserId } from '~/hooks/useGetUserId'
import { AiBlueprintPreviewFormEnum } from '~/models/enums/forms/AiBlueprintPreviewFormEnum'
import { PublishArcMapResourceFormEnum } from '~/models/enums/forms/PublishArcMapResourceFormEnum'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'
import { queryOrganisation } from '~/services/Discovery'

export type UseRepositoryProps = {
  /** Indicates if there are changes in the preview. */
  hasPreviewChanges: boolean
  /** The setValue method from the preview code form. */
  setPreviewFormValue: UseFormReturn['setValue']
  /** The watch method from the preview code form. */
  watchPreviewForm: UseFormReturn['watch']
}

/**
 * Provides utilities for handling:
 * - Form states;
 * - Mutation.
 */
export const useRepository = (props: UseRepositoryProps) => {
  const { hasPreviewChanges, setPreviewFormValue, watchPreviewForm } = props

  // Selected Arc Map Node and Blueprints.
  const selectedArcMapNode = watchPreviewForm(
    AiBlueprintPreviewFormEnum.ARC_MAP_NODE,
  ) as SelectedArcMapNodeInfo
  const {
    aggregateId,
    label: resourceLabel,
    id: resourceId,
    type: resourceType,
  } = selectedArcMapNode || {}

  const selectedBlueprints = watchPreviewForm(
    AiBlueprintPreviewFormEnum.BLUEPRINTS,
  )

  // User and Org data.
  const { userId } = useGetUserId()
  const { email } = useGetUserEmail()
  const { data: orgData } = useQuery({
    ...queryOrganisation(userId, email),
    select: (response) => response.data,
  })

  // Publish form.
  const { control, handleSubmit } = useForm({
    values: {
      [PublishArcMapResourceFormEnum.AGGREGATE_ID]: aggregateId,
      [PublishArcMapResourceFormEnum.BRANCH]: '',
      [PublishArcMapResourceFormEnum.COMMIT_MESSAGE]: `Generate code for ${resourceType} ${resourceLabel}`,
      [PublishArcMapResourceFormEnum.DEVELOPER_ID]: userId,
      [PublishArcMapResourceFormEnum.ECOSYSTEM_NAME]: orgData?.ecosystemName,
      [PublishArcMapResourceFormEnum.RESOURCE_ID]: resourceId,
      [PublishArcMapResourceFormEnum.RESOURCE_TYPE]: resourceType,
      [PublishArcMapResourceFormEnum.STRATEGY_TYPE]: 'Custom',
    },
  })

  // Select generation strategies.
  const {
    isPending: isSelectGenerationStrategiesPending,
    mutateAsync: mutateSelectGenerationStrategies,
  } = useSelectGenerationStrategies()

  // Publish code.
  const {
    isPending: isPublishArcMapResourcePending,
    mutateAsync: mutatePublishArcMapResourceAsync,
  } = usePublishArcMapResource()

  // Chat states.
  const { setLoadingState } = useChatBotContext()

  // Change chat loading state based on the publish loading.
  useEffect(() => {
    if (typeof isPublishArcMapResourcePending === 'boolean')
      setLoadingState(isPublishArcMapResourcePending)
  }, [isPublishArcMapResourcePending])

  // Handles the publish code submit.
  const handleFormSubmit = async (data: FieldValues) => {
    await mutateSelectGenerationStrategies({
      aggregateId,
      generationStrategyIds: selectedBlueprints,
    })

    const result = await mutatePublishArcMapResourceAsync(data)
    const commitSha = result?.data?.value?.commitSha
    if (hasPreviewChanges && !!commitSha)
      setPreviewFormValue(AiBlueprintPreviewFormEnum.COMMIT_SHA, commitSha)
  }

  const handleRepoConfigured = (repoUrl: string) => {
    setPreviewFormValue(AiBlueprintPreviewFormEnum.ARC_MAP_NODE, {
      ...selectedArcMapNode,
      gitRepositoryUrl: repoUrl,
    })
  }

  const handleBranchChange = (branch: string) => {
    setPreviewFormValue(AiBlueprintPreviewFormEnum.BRANCH, branch)
  }

  return {
    control,
    handleFormSubmit,
    handleRepoConfigured,
    handleSubmit,
    handleBranchChange,
    isLoading:
      isSelectGenerationStrategiesPending || isPublishArcMapResourcePending,
    selectedArcMapNode,
    selectedBlueprints,
  }
}
