/**
 * Enum with the form fields names
 * from `ai blueprint preview` form.
 */
export enum AiBlueprintPreviewFormEnum {
  /** The ArcMap node to preview. */
  ARC_MAP_NODE = 'arcMapNode',
  /** The name of the branch where the code will be committed. */
  BRANCH = 'branch',
  /** The selected blueprints to preview. */
  BLUEPRINTS = 'blueprints',
  /**
   * The current commit sha (if any):
   * this can be related to an ArcMap resource
   * or after publishing the code.
   */
  COMMIT_SHA = 'commitSha',
}
