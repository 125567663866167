import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { queryListBindingParameters } from '~/services/GenerationStrategy'
import { getOrganisation } from '~/utils/getOrganisation'

export const loaderCodeGenerationStrategyEdit =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const fetchOrganisation = await getOrganisation(queryClient, pca)

    const generationStrategyId = params.generationStrategyId
    invariant(generationStrategyId, 'Generation Strategy ID is required')

    const qParameters = queryListBindingParameters(generationStrategyId)
    const fetchBindingParameters =
      await queryClient.ensureQueryData(qParameters)

    return {
      organisation: fetchOrganisation.data,
      strategy: {},
      parameters: fetchBindingParameters,
    }
  }
