import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { usePreviewCodeGeneration } from '~/hooks/api/codeGenerationStrategy/usePreviewCodeGeneration'
import { useGetLatestCommitSha } from '~/hooks/api/developer/useGetLatestCommitSha'
import { UpdateTypeEnum } from '~/models/enums/aiBlueprint/UpdateTypeEnum'
import { AiBlueprintPreviewFormEnum } from '~/models/enums/forms/AiBlueprintPreviewFormEnum'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'
import type {
  FileStructure,
  FolderOrFileStructure,
} from '~/services/GenerationStrategy.types'
import { PlaceholderMsg } from '../components/PlaceholderMsg/PlaceholderMsg'
import { addFileLanguages } from '../utils/addFileLanguages'
import { getPreviewFor } from '../utils/getPreviewFor'
import { useManageDefaultSelectedItem } from './useManageDefaultSelectedItem'

/**
 * Hook to support the `AiBlueprintPreview` component.
 */
export const useAiBlueprintPreview = () => {
  // React Router Dom.
  const { generationStrategyId } = useParams() || {}

  // States.
  // Track if form was ever submitted (won't be reset)
  const [wasEverSubmitted, setWasEverSubmitted] = useState<boolean>(false)

  // Refs
  const selectAiBlueprintBtnRef = useRef<HTMLButtonElement>(null)
  const selectArcMapBtnRef = useRef<HTMLButtonElement>(null)
  const gitRepoUrlBtnRef = useRef<HTMLButtonElement>(null)
  const selectBranchBtnRef = useRef<HTMLButtonElement>(null)
  const publishBtnRef = useRef<HTMLButtonElement>(null)

  // Hooks.
  const { defaultSelected } = useManageDefaultSelectedItem()

  // React Hook Form.
  const {
    control,
    handleSubmit,
    reset,
    setValue: setFormValue,
    watch,
  } = useForm<FieldValues>({
    values: {
      [AiBlueprintPreviewFormEnum.ARC_MAP_NODE]: { ...defaultSelected },
      [AiBlueprintPreviewFormEnum.BLUEPRINTS]: !!generationStrategyId
        ? [generationStrategyId]
        : [],
      [AiBlueprintPreviewFormEnum.BRANCH]: '',
    },
  })

  const selectedArcMapNode = watch(
    AiBlueprintPreviewFormEnum.ARC_MAP_NODE,
  ) as SelectedArcMapNodeInfo

  const selectedBlueprints = watch(AiBlueprintPreviewFormEnum.BLUEPRINTS)
  const selectedBranch = watch(AiBlueprintPreviewFormEnum.BRANCH)

  // Hooks.
  const {
    data: previewCodeData,
    error: previewCodeError,
    isPending: isPreviewCodePending,
    mutateAsync: previewCodeMutationAsync,
  } = usePreviewCodeGeneration()

  // Constants.
  const disabledPreviewBtn =
    !selectedBlueprints || !selectedBlueprints.length || !selectedArcMapNode?.id

  const hasPreviewChanges = useMemo(
    () => !!previewCodeData?.data?.value?.hasChanges,
    [previewCodeData],
  )

  const previewData = useMemo(
    () => addFileLanguages(previewCodeData?.data?.value?.directoryItems),
    [previewCodeData],
  )

  // Get latest commit sha.
  // Only get when there are no preview changes.
  const { data: latestCommitShaData } = useGetLatestCommitSha({
    aggregateId: selectedArcMapNode?.aggregateId!,
    branch: selectedBranch,
    enabled: !!previewData && !hasPreviewChanges,
    previewFor: getPreviewFor(selectedArcMapNode?.type)!,
    previewForId: selectedArcMapNode?.id!,
  })

  // Set the latest commit sha as current one.
  useEffect(() => {
    if (!!latestCommitShaData?.commitSha)
      setFormValue(
        AiBlueprintPreviewFormEnum.COMMIT_SHA,
        latestCommitShaData?.commitSha,
      )
  }, [latestCommitShaData])

  // Methods.
  const onSubmitPreviewCode = useCallback(
    async (data: FieldValues) => {
      setWasEverSubmitted(true)

      const { arcMapNode, blueprints } = data
      const { aggregateId, id, type } = arcMapNode || {}

      await previewCodeMutationAsync({
        aggregateId,
        generationStrategyIds: blueprints,
        previewFor: getPreviewFor(type),
        previewForId: id,
        branch: selectedBranch,
      })

      // Reset the form's dirty state while keeping the values.
      reset(data, {
        keepValues: true,
      })
    },
    [previewCodeMutationAsync, reset, selectedBranch],
  )

  // Trigger submit when an ArcMap item is selected.
  useEffect(() => {
    if (
      !!selectedBlueprints &&
      !!selectedBlueprints.length &&
      !!selectedArcMapNode &&
      !!selectedBranch &&
      selectedArcMapNode.id
    )
      handleSubmit(onSubmitPreviewCode)()
  }, [
    handleSubmit,
    onSubmitPreviewCode,
    selectedArcMapNode,
    selectedBlueprints,
    selectedBranch,
  ])

  // Placeholder text to display when there is no file to preview.
  const placeholderMsg =
    (!selectedArcMapNode || !previewData) && !previewCodeError ? (
      <PlaceholderMsg />
    ) : !!previewCodeError ? (
      (previewCodeError as string)
    ) : undefined

  // Method that will determine when to highlight a tree item:
  // It provides a tailwind class.
  const highlightTreeItem = (node: FolderOrFileStructure) => {
    const file = (node as FileStructure)?.file
    const { result } = file || {}

    if (result === UpdateTypeEnum.CREATE) return 'text-green-600'
    if (result === UpdateTypeEnum.UPDATE) return 'text-yellow-600'
    return ''
  }

  return {
    control,
    disabledPreviewBtn,
    gitRepoUrlBtnRef,
    handleSubmit,
    hasPreviewChanges,
    highlightTreeItem,
    isPreviewCodePending,
    onSubmitPreviewCode,
    placeholderMsg,
    previewData,
    publishBtnRef,
    selectAiBlueprintBtnRef,
    selectArcMapBtnRef,
    selectBranchBtnRef,
    setFormValue,
    wasEverSubmitted,
    watch,
  }
}
