const GitIcon = ({ size = 25, color = 'currentColor', ...rest }) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M21.6206 11.7339L12.8882 3.00226C12.6548 2.76953 12.3328 2.62549 11.9767 2.62549C11.6206 2.62549 11.2985 2.76953 11.0651 3.00226L9.25593 4.81474L11.5559 7.11469C11.7026 7.06334 11.8713 7.034 12.0473 7.034C12.8936 7.034 13.5791 7.71951 13.5791 8.56574C13.5791 8.74512 13.5484 8.9165 13.4917 9.07654L13.4951 9.06587L15.7103 11.2825C15.8584 11.2298 16.0297 11.1991 16.2078 11.1991C17.0533 11.1991 17.7389 11.8846 17.7389 12.7302C17.7389 13.5757 17.0533 14.2613 16.2078 14.2613C15.3622 14.2613 14.6767 13.5757 14.6767 12.7302C14.6767 12.5215 14.7187 12.3227 14.7941 12.1414L14.7901 12.1514L12.7162 10.0881V15.5256C13.2257 15.7823 13.5691 16.3011 13.5691 16.9C13.5691 17.7469 12.8822 18.4337 12.0353 18.4337C11.1885 18.4337 10.5016 17.7469 10.5016 16.9C10.5016 16.4772 10.6723 16.0951 10.9491 15.8177C11.0898 15.677 11.2571 15.5629 11.4432 15.4829L11.4532 15.4789V9.98812C10.8917 9.75206 10.5049 9.20591 10.5049 8.57041C10.5049 8.35702 10.5483 8.1543 10.627 7.96958L10.623 7.97958L8.36303 5.70898L2.37477 11.6932C2.1427 11.9273 2 12.2494 2 12.6055C2 12.9616 2.14337 13.2837 2.37543 13.5177L11.1084 22.2481C11.3412 22.4814 11.6632 22.6255 12.0193 22.6255C12.3754 22.6255 12.6969 22.4814 12.9302 22.2481L21.6219 13.5564C21.8553 13.3237 22 13.0009 22 12.6455C22 12.2901 21.8553 11.9673 21.6219 11.7346L21.6206 11.7339Z"
      fill={color}
    />
  </svg>
)

export default GitIcon
