import { useState, type MouseEvent as ReactMouseEvent } from 'react'
import type { CodeGenerationStore } from '~/store'

type TreeViewContextMenuState = {
  mouseX: number
  mouseY: number
  id?: GUID
  label?: string
} | null

type UseManageTreeVIewRightClickProps = Pick<
  CodeGenerationStore,
  'selectedTreeItem' | 'setSelectedTreeItem'
>

/**
 * Manages the tree view context menu.
 */
export const useManageTreeViewContextMenu = (
  props: UseManageTreeVIewRightClickProps,
) => {
  const { selectedTreeItem, setSelectedTreeItem } = props

  const [treeViewContextMenu, setTreeViewContextMenu] =
    useState<TreeViewContextMenuState>(null)

  // Callback handler for `TreeView` context menu.
  const handleTreeViewContextMenu = (
    event: ReactMouseEvent<HTMLUListElement, MouseEvent>,
  ) => {
    event.preventDefault()

    // Get the clicked node's ID from the closest TreeItem.
    const target = (event.target as HTMLElement).closest('[role="treeitem"]')
    const clickedNodeId = target?.getAttribute('data-node-id')

    if (!clickedNodeId) return

    // Scenario 1: Single selection exists and clicking a different node.
    if (
      selectedTreeItem.length === 1 &&
      !selectedTreeItem.includes(clickedNodeId)
    ) {
      setSelectedTreeItem([clickedNodeId])
    }

    // Scenario 2: Multiple selection exists and clicking a non-selected node.
    else if (
      selectedTreeItem.length > 1 &&
      !selectedTreeItem.includes(clickedNodeId)
    ) {
      setSelectedTreeItem([clickedNodeId])
    }

    // Scenario 3: Multiple selection exists and clicking one of the selected nodes:
    // Just preserve the current selection.

    // Set context menu position.
    if (selectedTreeItem.length > 0) {
      setTreeViewContextMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      })
    }
  }

  return {
    handleTreeViewContextMenu,
    setTreeViewContextMenu,
    treeViewContextMenu,
  }
}
