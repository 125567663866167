import { DraggableTreeItem } from './DraggableTreeItem'
import { type TreeItemLabelProps } from './TreeItemLabel'

type RenderDraggableTreeFilePathProps = Partial<TreeItemLabelProps>

/**
 * Renders the file path `tree items`.
 */
export const RenderDraggableTreeFilePath = (
  props: RenderDraggableTreeFilePathProps,
) => {
  const { domainDictionaryParams, filteredValue, highlightCondition, node } =
    props

  const { children, id, name } = node || {}

  return (
    <DraggableTreeItem
      data-node-id={id}
      data-node-label={name}
      key={id}
      node={node}
      domainDictionaryParams={domainDictionaryParams}
      filteredValue={filteredValue}
      highlightCondition={highlightCondition}
    >
      {children?.map((currNode) => (
        <RenderDraggableTreeFilePath
          domainDictionaryParams={domainDictionaryParams}
          filteredValue={filteredValue}
          highlightCondition={highlightCondition}
          key={currNode.id}
          node={currNode}
        />
      ))}
    </DraggableTreeItem>
  )
}
