import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot message when it is not published yet
 * (missing a commit sha).
 */
export const getChatNoPublishedMessage = async (): Promise<ChatMessage> => {
  // Simulate network delay.
  await new Promise((resolve) => setTimeout(resolve, 500))

  return {
    id: ChatBotMessageIds.NOT_PUBLISHED + new Date(),
    type: MessageTypeEnum.ASSISTANT,
    content: {
      text: '# Your preview is ready! ✨\n\nI\'ve prepared a preview of the code as it will appear in your code repository. Review the proposed changes. \nIf everything looks good, click "Publish" to push these changes to your selected branch. 🚀',
      options: [
        {
          id: ChatBotMessageIds.NOT_PUBLISHED_BTN,
          type: 'button',
          label: 'Publish Changes',
        },
      ],
    },
    timestamp: new Date(),
  }
}
