import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'

/**
 * Provides the proper icon
 * based on the node type.
 * @param nodeType The node type.
 */
export const getIsSelectableItem = (nodeType?: ArcMapTreeNodeType) => {
  switch (nodeType) {
    case ArcMapTreeNodeType.AGGREGATE:
    case ArcMapTreeNodeType.COMMAND:
    case ArcMapTreeNodeType.REACTION:
    case ArcMapTreeNodeType.QUERY:
    case ArcMapTreeNodeType.TYPE:
      return true
    case ArcMapTreeNodeType.BOUNDED_CONTEXT:
    case ArcMapTreeNodeType.FOLDER:
    default:
      return false
  }
}
