import { Lightbulb } from '@phosphor-icons/react'

export const PlaceholderMsg = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center p-2">
      <Lightbulb size={24} />

      <p className="typography-body-medium-regular max-w-[175px] text-center text-secondary">
        Select a Blueprint and an ArcMap™ Element to start generating the files
      </p>
    </div>
  )
}
