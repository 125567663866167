import { Button, type ButtonProps } from '@mui/material'
import { Rocket } from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'

type ButtonArcPilot = Pick<ButtonProps, 'onClick'>

/**
 * The button to open the ArcPilot (ChatBot) drawer.
 */
export const ButtonArcPilot = (props: ButtonArcPilot) => (
  <Button
    className={twMerge(
      'absolute -right-[5px] bottom-[50px] gap-1 py-4',
      'group min-w-0 transition-all duration-300 hover:min-w-fit',
    )}
    variant="contained"
    {...props}
  >
    <Rocket size={24} />

    <span className="max-w-0 overflow-hidden opacity-0 transition-all duration-500 group-hover:max-w-xs group-hover:opacity-100">
      ArcPilot
    </span>
  </Button>
)
