import {
  Button,
  Menu,
  MenuItem,
  Tooltip as MuiTooltip,
  menuClasses,
  styled,
  tooltipClasses,
  type TooltipProps,
} from '@mui/material'
import { TextAa } from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'
import {
  useState,
  type MouseEvent,
  type RefObject,
  type SyntheticEvent,
} from 'react'
import { highlightFilteredValue } from '~/utils/strings/highlightFilteredValue'
import { getColorClass } from './ColoredParams.utils'

const CASE_OPTIONS = [
  'Pascal',
  'Camel',
  'Kebab',
  'Snake',
  'Type',
  'Lower',
  'Upper',
  'Raw',
  'PluralPascal',
  'PluralCamel',
  'PluralKebab',
  'PluralSnake',
  'PluralLower',
  'PluralUpper',
]

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    padding: 0,
  },
}))

export type HighlightedParamProps = {
  /**
   * Any filtered value by the user:
   * used to highlight the matching strings.
   */
  filteredValue?: string
  /** The class name applied to the highlighted containing node. */
  highlightClassName?: string
  /** The ref of the highlighted node. */
  highlightRef?: RefObject<HTMLElement>
  /** If true, the tooltip will not open. */
  noTooltip?: boolean
  /** The callback handler for the case change. */
  onCaseChange?: (newCase: string) => void
  /** The callback handler for the highlight node click. */
  onHighlightClick?: (
    e: SyntheticEvent,
    targetRef?: RefObject<HTMLElement>,
  ) => void
  /** Array of parameter strings to match against. */
  params: string[]
  /**
   * The string part to be checked if it matches with
   * one of the params.
   */
  stringPart: string
}

/**
 * Renders the highlighted string param from "colored param" component.
 */
export const HighlightedParam = (props: HighlightedParamProps) => {
  const {
    filteredValue,
    highlightClassName,
    highlightRef,
    noTooltip,
    onCaseChange,
    onHighlightClick,
    params,
    stringPart,
  } = props

  // States.
  // Get the case value to set as default state.
  const [path, caseValue] = stringPart.split(':').map((s) => s.trim())
  const [selectedCase, setSelectedCase] = useState<string>(
    caseValue || (CASE_OPTIONS[0] as string),
  )
  const [openTooltip, setOpenTooltip] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuAnchorEl)

  // Methods.
  const handleToggleMenuClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setMenuAnchorEl(e.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleCaseChange = (newCase: string) => {
    setSelectedCase(newCase)
    onCaseChange?.(newCase)
    handleMenuClose()
  }

  const handleMenuItemClick = (e: MouseEvent, option: string) => {
    e.stopPropagation()
    handleCaseChange(option)
  }

  // Find a param that partially matches the content inside curly braces.
  const cleanedPart = stringPart?.split(':')[0]?.trim()
  const matchedParam = params.find((param) => stringPart.includes(param))

  // If a match is found, render within Tooltip.
  if (matchedParam) {
    const classNames = getColorClass(matchedParam)
    const [backgroundColor] = classNames?.split(' ') || []

    const highlightedContent = (
      <span
        className={twMerge(
          'group relative mx-[1px] inline-block rounded px-1 py-0.5 font-mono text-black hover:z-20',
          backgroundColor,
          highlightClassName,
        )}
        onClick={(e) => {
          onHighlightClick?.(e, highlightRef)
        }}
        ref={highlightRef}
      >
        {highlightFilteredValue(`{${cleanedPart}}`, filteredValue)}
      </span>
    )

    if (noTooltip) return highlightedContent

    return (
      <>
        <Tooltip
          onClose={() => setOpenTooltip(false)}
          onOpen={() => setOpenTooltip(true)}
          open={openTooltip || openMenu}
          placement="bottom-end"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: { offset: [0, -12] },
              },
              {
                name: 'preventOverflow',
                options: {
                  altBoundary: true,
                  rootBoundary: 'document',
                },
              },
            ],
            sx: {
              position: 'absolute',
            },
          }}
          title={
            <Button
              className={twMerge(
                'text-weight-light cursor-pointer bg-white font-mono text-xs text-gray-700 ',
              )}
              id="open-menu-button"
              onClick={handleToggleMenuClick}
            >
              <TextAa
                className="mr-1 align-text-bottom"
                size={16}
                weight="bold"
              />

              {selectedCase}
            </Button>
          }
        >
          {highlightedContent}
        </Tooltip>

        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          id="options-menu"
          MenuListProps={{
            'aria-labelledby': 'open-menu-button',
          }}
          onClose={handleMenuClose}
          open={openMenu}
          sx={{
            [`& .${menuClasses.paper}`]: { mt: 1 },
            zIndex: 9999,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {CASE_OPTIONS.map((option) => (
            <MenuItem
              className="font-mono text-sm"
              key={option}
              onClick={(e) => handleMenuItemClick(e, option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  // If no matching param is found, render the content as plain text.
  return (
    <span data-value={stringPart}>
      {highlightFilteredValue(`{${cleanedPart}}`, filteredValue)}
    </span>
  )
}
