import { MessageTypeEnum } from '~/models/enums/components/chat/MessageTypeEnum'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Provides the chat bot feedback message for blueprint.
 */
export const getChatFeedbackForBlueprintMsg =
  async (): Promise<ChatMessage> => {
    // Simulate network delay.
    await new Promise((resolve) => setTimeout(resolve, 100))

    return {
      id: `${new Date()}`,
      type: MessageTypeEnum.ASSISTANT,
      content: {
        text: 'Thank you! We will use this to bootstrap your solution. ✨',
      },
      timestamp: new Date(),
    }
  }
