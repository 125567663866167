import { CircularProgress, Typography } from '@mui/material'
import { TreeView } from '@mui/x-tree-view'
import { CaretDown, CaretRight, TreeStructure } from '@phosphor-icons/react'
import { useGetBoundedContextByPlatformId } from '~/hooks/api/developer/useGetBoundedContextByPlatformId'
import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'
import TreeViewToolbar from '../TreeViewToolbar/TreeViewToolbar'
import ArcMapTreeItem from './ArcMapTreeItem/ArcMapTreeItem'
import {
  useArcMapTreeView,
  type UseArcMapTreeViewProps,
} from './useArcMapTreeView'
import { useFilterArcMapByName } from './useFilterArcMapByName'

export type ArcMapTreeViewProps = UseArcMapTreeViewProps & {
  /** The tree view title. */
  title?: string | JSX.Element
}

/**
 * Implements a `TreeView` specifically
 * for ArcMap elements:
 * Bounded Context > Aggregate > Command / Reaction / Type / Query.
 *
 * This components transforms the bounded context
 * data and converts it into a format that
 * will be used to build the tree.
 */
const ArcMapTreeView = (props: ArcMapTreeViewProps) => {
  const { defaultSelected, onNodeChange, selectedNode, title } = props

  const { data: boundedContextsData, isFetching } =
    useGetBoundedContextByPlatformId()

  const {
    filteredData,
    filterInputValue,
    handleClearFilterInputClick,
    handleFilterInputChange,
  } = useFilterArcMapByName({ boundedContextsData })

  const {
    expanded,
    handleToggleFoldersClick,
    handleTreeItemClick,
    handleTreeItemToggle,
    handleTreeViewNodeToggle,
    isTreeExpanded,
    selected,
  } = useArcMapTreeView({
    defaultSelected,
    filteredData,
    onNodeChange,
    selectedNode,
  })

  if (isFetching)
    return (
      <div className="flex justify-center p-3">
        <CircularProgress color="secondary" size={30} />
      </div>
    )

  // Nothing to be rendered.
  if (!boundedContextsData) return null

  return (
    <div className="flex-1 overflow-auto rounded-lg bg-slate-100 p-4 shadow-md">
      <Typography component="h4" className="mb-3 font-bold">
        {!!title ? (
          title
        ) : (
          <span className="flex items-center gap-2">
            <TreeStructure size={24} /> ArcMap
          </span>
        )}
      </Typography>

      <TreeViewToolbar
        filterInputValue={filterInputValue}
        isTreeExpanded={isTreeExpanded}
        noFileUpload
        noToggle={!filteredData}
        onClearFilterInputClick={handleClearFilterInputClick}
        onFilterInputChange={handleFilterInputChange}
        onToggleClick={handleToggleFoldersClick}
      />

      {!filteredData || !filteredData.length ? (
        <p>No results found</p>
      ) : (
        <TreeView
          defaultCollapseIcon={<CaretDown />}
          defaultExpandIcon={<CaretRight />}
          expanded={expanded}
          onNodeToggle={handleTreeViewNodeToggle}
          selected={selected?.id || null}
        >
          {filteredData.map((node) => (
            <ArcMapTreeItem
              filterInputValue={filterInputValue}
              key={node.id}
              node={node as ArcMapTreeViewData}
              onItemClick={handleTreeItemClick}
              onItemToggle={handleTreeItemToggle}
            />
          ))}
        </TreeView>
      )}
    </div>
  )
}

export default ArcMapTreeView
