import { LoadingButton } from '@mui/lab'
import { Button, Menu, TextField, Typography } from '@mui/material'
import { GithubLogo } from '@phosphor-icons/react'
import type { RefObject } from 'react'
import { Controller } from 'react-hook-form'
import GitIcon from '~/components/Icons/GitIcon'
import FormError from '~/components/form/FormError/FormError'
import { ConfigureGitRepoFormEnum } from '~/models/enums/forms/ConfigureGitRepoFormEnum'
import {
  useRepositoryConnection,
  type UseRepositoryConnectionProps,
} from './useRepositoryConnection'
import { getRepoName } from './utils/getRepoName'

type RepositoryConnectionProps = UseRepositoryConnectionProps & {
  /** Ref for the menu button. */
  buttonRef?: RefObject<HTMLButtonElement>
  /** Indicates if the button should be disabled. */
  disabled?: boolean
  /** The git repository URL. */
  gitRepositoryUrl?: string
}

/**
 * Repository connection form component.
 */
export const RepositoryConnection = (props: RepositoryConnectionProps) => {
  const {
    aggregateId,
    buttonRef,
    disabled,
    gitRepositoryUrl,
    onRepoConfigured,
  } = props

  const {
    anchorEl,
    control,
    error,
    handleClick,
    handleClose,
    handleFormSubmit,
    handleSubmit,
    isLoading,
    open,
  } = useRepositoryConnection({ aggregateId, onRepoConfigured })

  return (
    <>
      <Button
        aria-controls={open ? 'repository-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="enabled:border-secondary-200"
        disabled={disabled}
        color="secondary"
        id="repository-button"
        onClick={handleClick}
        ref={buttonRef}
        startIcon={<GitIcon size={24} />}
        variant="outlined"
      >
        {getRepoName(gitRepositoryUrl)}
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="repository-menu"
        MenuListProps={{
          'aria-labelledby': 'repository-button',
          className: 'w-[auto] p-0',
        }}
        onClose={handleClose}
        open={open}
      >
        <div className="flex flex-col p-2">
          <Typography
            className="mb-3 flex items-center gap-1"
            component="h4"
            variant="bodyMediumBold"
          >
            <GithubLogo size={20} /> Repository connection
          </Typography>

          <div className="flex gap-2">
            <Controller
              control={control}
              disabled={isLoading}
              name={ConfigureGitRepoFormEnum.REPOSITORY_URL}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Repository URL"
                  size="small"
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              disabled={isLoading}
              name={ConfigureGitRepoFormEnum.TOKEN}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Authentication Token"
                  size="small"
                  type="password"
                  {...field}
                />
              )}
            />

            <LoadingButton
              className="max-w-fit"
              color="secondary"
              loading={isLoading}
              onClick={handleSubmit?.(handleFormSubmit)} // On submit on the submit button to avoid triggering the submit on the parent component form.
              variant="outlined"
              type="submit"
            >
              Connect
            </LoadingButton>
          </div>

          <FormError className="mb-0.5 text-xs" error={error as string} />
        </div>
      </Menu>
    </>
  )
}
