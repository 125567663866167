import { RocketLaunch } from '@phosphor-icons/react'
import type { RefObject } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import ActionIconButton from '~/components/buttons/ActionIconButton/ActionIconButton'
import { RepositoryConnection } from '../RepositoryConnection/RepositoryConnection'
import { SelectBranch } from '../SelectBranch/SelectBranch'
import { useRepository, type UseRepositoryProps } from './useRepository'

type RepositoryProps = UseRepositoryProps & {
  /** Ref for the git repo url button. */
  gitRepoUrlBtnRef?: RefObject<HTMLButtonElement>
  /** Ref for the git repo url button. */
  publishBtnRef?: RefObject<HTMLButtonElement>
  /** Ref for the git repo url button. */
  selectBranchBtnRef?: RefObject<HTMLButtonElement>
}

/**
 * The current repository to publish code.
 */
export const Repository = (props: RepositoryProps) => {
  const { gitRepoUrlBtnRef, publishBtnRef, selectBranchBtnRef } = props

  const {
    control,
    handleFormSubmit,
    handleRepoConfigured,
    handleSubmit,
    isLoading,
    selectedArcMapNode,
    selectedBlueprints,
    handleBranchChange,
  } = useRepository(props)

  const {
    aggregateId,
    gitRepositoryUrl,
    id: resourceId,
  } = selectedArcMapNode || {}

  const blueprintsCount = selectedBlueprints.length

  return (
    <form
      className="flex items-center gap-2"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <RepositoryConnection
        buttonRef={gitRepoUrlBtnRef}
        aggregateId={aggregateId}
        disabled={!resourceId || !blueprintsCount}
        gitRepositoryUrl={gitRepositoryUrl}
        onRepoConfigured={handleRepoConfigured}
      />

      {!!gitRepositoryUrl && (
        <SelectBranch
          aggregateId={aggregateId}
          buttonRef={selectBranchBtnRef}
          control={control as unknown as UseFormReturn['control']}
          disabled={!aggregateId || !resourceId || !blueprintsCount}
          onBranchChange={handleBranchChange}
        />
      )}

      <ActionIconButton
        buttonProps={{ type: 'submit' }}
        className="p-[17px]"
        color="secondary"
        disabled={!resourceId || !blueprintsCount || !gitRepositoryUrl}
        Icon={RocketLaunch}
        iconProps={{ size: 25 }}
        loading={isLoading}
        ref={publishBtnRef}
      />
    </form>
  )
}
