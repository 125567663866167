import { ChatBotMessageIds } from '~/models/enums/aiBlueprint/ChatBotMessageIds'
import type { ChatMessage } from '~/models/types/components/chat/Chat'

/**
 * Helper to check if last message is an error message.
 * @param messages
 */
export const isLastMessageError = (messages: ChatMessage[]) =>
  messages.length > 0 &&
  messages[messages.length - 1]?.id?.includes(ChatBotMessageIds.GENERIC_ERROR)
