import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { createSearchParams, generatePath } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

export type UseGetLatestCommitShaProps =
  Partial<UndefinedInitialDataOptions> & {
    /**
     * The ID of the aggregate that have a configured
     * git repository associated with, from where
     * the commit will be get from.
     */
    aggregateId: GUID
    /** The name of the branch to get the commit from. */
    branch: string
    /** The ArcMap item which was used to generate a preview. */
    previewFor: number
    /** The ArcMap item ID. */
    previewForId: GUID
  }

/**
 * Hook that implements react query to get
 * the latest commit sha.
 */
export const useGetLatestCommitSha = (props: UseGetLatestCommitShaProps) => {
  const {
    aggregateId,
    branch,
    enabled = false,
    previewFor,
    previewForId,
  } = props

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    enabled,
    queryKey: [
      QueryKeysEnum.COMMIT_SHA,
      { aggregateId, previewFor, previewForId, branch },
    ],
    queryFn: async () => {
      invariant(aggregateId, ERROR_MESSAGES.aggregateId)

      const path = generatePath(DevelopmentAggregateAPI.GetLatestCommitSha, {
        aggregateId,
      })
      const search = createSearchParams({
        ...(typeof previewFor === 'number' && { previewFor: `${previewFor}` }),
        previewForId,
        branch,
      })
      const url = `${path}?${search}`
      return serviceDevelopmentAggregate.get(url)
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
