import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'
import { PublishArcMapResourceFormEnum } from '~/models/enums/forms/PublishArcMapResourceFormEnum'

/**
 * Provides the proper publish endpoint
 * based on the provided type.
 * @param type The type of the resource to be published.
 */
export const getPublishEndpoint = (type: ArcMapTreeNodeType | null) => {
  switch (type) {
    case ArcMapTreeNodeType.AGGREGATE:
      return 'PublishAggregate'
    case ArcMapTreeNodeType.COMMAND:
      return 'PublishCommand'
    case ArcMapTreeNodeType.REACTION:
      return 'PublishReaction'
    case ArcMapTreeNodeType.TYPE:
      return 'PublishType'
    case ArcMapTreeNodeType.QUERY:
      return 'PublishQuery'
    default:
      return ''
  }
}

/**
 * Provides additional form fields for the
 * publish code form:
 * The fields will be based on the resource type.
 * @param type The resource type.
 * @param resourceId the resource ID.
 */
export const getFormResourceIdParam = (
  type: ArcMapTreeNodeType | null,
  resourceId: GUID | null,
) => {
  switch (type) {
    case ArcMapTreeNodeType.COMMAND:
      return { [PublishArcMapResourceFormEnum.COMMAND_ID]: resourceId }
    case ArcMapTreeNodeType.REACTION:
      return { [PublishArcMapResourceFormEnum.REACTION_ID]: resourceId }
    case ArcMapTreeNodeType.TYPE:
      return { [PublishArcMapResourceFormEnum.TYPE_ID]: resourceId }
    case ArcMapTreeNodeType.QUERY:
      return { [PublishArcMapResourceFormEnum.QUERY_ID]: resourceId }
    default:
      return {}
  }
}
