import { CircularProgress } from '@mui/material'
import { Panel } from 'react-resizable-panels'
import { UpdateTypeEnum } from '~/models/enums/aiBlueprint/UpdateTypeEnum'
import { CodePreviewBaseProps } from '~/models/types/components/codePreview/CodePreviewBaseProps'
import type {
  FileStructure,
  FolderOrFileStructure,
} from '~/services/GenerationStrategy.types'
import { CodeEditor } from '../CodeEditor/CodeEditor'

export type FileDetailsPanelProps = Pick<
  CodePreviewBaseProps,
  'isLoading' | 'placeholderMsg'
> & {
  /** The selected folder or file item. */
  selectedFile?: FolderOrFileStructure
}

/**
 * The file details panel from
 * AI Blueprint Preview page.
 */
export const FileDetailsPanel = (props: FileDetailsPanelProps) => {
  const {
    isLoading,
    placeholderMsg = <p>Select a file to preview.</p>,
    selectedFile,
  } = props

  const file = (selectedFile as FileStructure)?.file
  const { content, filePath, language, originalContent, result } = file || {}

  const isDiff = result === UpdateTypeEnum.UPDATE

  return (
    <Panel className="flex flex-col" order={2}>
      {!!filePath && (
        <div className="mb-[10px] rounded-[4px] bg-feedback-success-50 px-[8px] py-[10px] font-mono text-sm font-bold text-neutral-high-200">
          {filePath}
        </div>
      )}

      <div className="flex-grow overflow-hidden bg-white shadow-md">
        {!content ? (
          <div className="flex h-full items-center justify-center">
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              placeholderMsg
            )}
          </div>
        ) : (
          <CodeEditor
            isDiffView={isDiff}
            language={language}
            modified={content}
            original={originalContent!}
            value={content}
          />
        )}
      </div>
    </Panel>
  )
}
