import { Accordion, styled } from '@mui/material'
import type { FeedbackType } from '~/models/types/components/chat/Chat'

export const FeedbackContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: FeedbackType }>(({ theme, type }) => {
  const colors = {
    info: theme.palette.info.light,
    success: theme.palette.success.light,
    warning: theme.palette.warning.light,
    error: theme.palette.error.light,
  }

  return {
    backgroundColor: colors[type],
  }
})

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  '&:before': {
    display: 'none',
  },
}))
