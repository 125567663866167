import {
  useQuery,
  type UndefinedInitialDataOptions,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query
 * to get bounded contexts by platform ID.
 */
export const useGetBoundedContextByPlatformId = (
  props?: Partial<UndefinedInitialDataOptions>,
) => {
  const { enabled = true } = props || {}

  const params = useParams()
  const { platformId } = params

  invariant(platformId, 'platformId is required')

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    enabled,
    queryKey: ['boundedContexts', { platformId }],
    queryFn: async () => {
      const url = DevelopmentAggregateAPI.getByPlatformId(platformId)
      return serviceDevelopmentAggregate.get(url)
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
