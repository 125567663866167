import {
  DiffEditor as MonacoDiffEditor,
  type DiffEditorProps,
} from '@monaco-editor/react'
import { EDITOR_DEFAULT_OPTIONS } from '../CodeEditor.constants'

/**
 * The diff code editor component:
 * based on the "monaco diff editor".
 */
export const DiffEditor = (props: Partial<DiffEditorProps>) => {
  const {
    language = 'javascript',
    modified,
    options = {
      ...(EDITOR_DEFAULT_OPTIONS as DiffEditorProps['options']),
      renderSideBySide: true,
    },
    original,
    ...restProps
  } = props

  return (
    <div className="h-full min-h-[200px]">
      <MonacoDiffEditor
        height="100%"
        language={language}
        original={original}
        modified={modified}
        options={options}
        {...restProps}
      />
    </div>
  )
}
