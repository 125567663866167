import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'
import type { BotResponseContent } from '~/models/types/components/chat/Chat'
import { FeedbackContainer, StyledAccordion } from './BotResponse.styles'
import { getFeedbackIcon, getProgressColor } from './BotResponse.utils'

interface BotResponseProps {
  content: BotResponseContent
  onOptionSelect?: (optionId: string, optionLabel: string) => void
}

interface CodeProps {
  node?: any
  inline?: boolean
  className?: string
  children?: React.ReactNode
}

/**
 *
 */
export const BotResponse = (props: BotResponseProps) => {
  const { content, onOptionSelect } = props

  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const handleCheckboxChange = (
    optionId: string,
    optionLabel: string,
    checked: boolean,
  ) => {
    setSelectedOptions((prev) =>
      checked ? [...prev, optionId] : prev.filter((id) => id !== optionId),
    )
    if (checked && onOptionSelect) {
      onOptionSelect(optionId, optionLabel)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Box sx={{ fontSize: '0.875rem' }}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, inline, className, children, ...props }: CodeProps) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  style={oneDark}
                  language={match[1]}
                  PreTag="div"
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              )
            },
            p: ({ children }) => <p className="m-0">{children}</p>,
          }}
        >
          {content.text}
        </ReactMarkdown>
      </Box>

      {content.feedback && (
        <FeedbackContainer
          className="flex items-start gap-2 rounded p-4"
          type={content.feedback.type}
        >
          {getFeedbackIcon(content.feedback.type)}
          <Typography variant="body2">{content.feedback.message}</Typography>
        </FeedbackContainer>
      )}

      {content.progress && (
        <div className="mt-4 flex flex-col gap-2">
          <LinearProgress
            variant="determinate"
            value={
              (content.progress.steps.filter((s) => s.status === 'completed')
                .length /
                content.progress.steps.length) *
              100
            }
          />

          <div className="mt-4 flex flex-col gap-1">
            {content.progress.steps.map((step) => (
              <div
                key={step.id}
                className={`flex items-center gap-2`}
                style={{
                  color: `${getProgressColor(step.status)}.main`,
                }}
              >
                <Typography variant="caption">{step.label}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      {content?.sections?.map((section: any) => (
        <StyledAccordion key={section.id} defaultExpanded={section.expanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">{section.title}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {section.content}
            </ReactMarkdown>
          </AccordionDetails>
        </StyledAccordion>
      ))}

      {content.options && content.options.length > 0 && (
        <div className="mt-4 flex flex-col gap-2">
          {content.options.map((option: any) => {
            switch (option.type) {
              case 'checkbox':
                return (
                  <FormControlLabel
                    key={option.id}
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(option.id)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            option.id,
                            option.label,
                            e.target.checked,
                          )
                        }
                        size="small"
                      />
                    }
                    label={option.label}
                    sx={{
                      '& .MuiFormControlLabel-label': { fontSize: '0.875rem' },
                    }}
                  />
                )
              case 'button':
                return (
                  <Button
                    key={option.id}
                    variant="outlined"
                    size="small"
                    onClick={() => onOptionSelect?.(option.id, option.label)}
                    fullWidth
                  >
                    {option.label}
                  </Button>
                )
              case 'link':
                return (
                  <Button
                    key={option.id}
                    variant="text"
                    size="small"
                    component="a"
                    href={option.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ padding: 0, justifyContent: 'flex-start' }}
                  >
                    {option.label}
                  </Button>
                )
              default:
                return null
            }
          })}
        </div>
      )}
    </div>
  )
}
