import { TreeItem } from '~/components/treeView/TreeView.styles'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { TreeItemLabel, type TreeItemLabelProps } from './TreeItemLabel'

type RenderTreeFilePathProps = Partial<TreeItemLabelProps>

/**
 * Renders the file path `tree items`.
 */
export const RenderTreeFilePath = (props: RenderTreeFilePathProps) => {
  const { domainDictionaryParams, filteredValue, highlightCondition, node } =
    props
  const { children, id, name } = node || {}

  return (
    <TreeItem
      data-node-id={id}
      data-node-label={name}
      key={id}
      label={
        <TreeItemLabel
          domainDictionaryParams={domainDictionaryParams as string[]}
          filteredValue={filteredValue as string}
          highlightCondition={highlightCondition}
          node={node as FolderOrFileStructure}
        />
      }
      nodeId={id as string}
      size="small"
    >
      {children?.map((currNode) => (
        <RenderTreeFilePath
          domainDictionaryParams={domainDictionaryParams}
          filteredValue={filteredValue}
          highlightCondition={highlightCondition}
          key={currNode.id}
          node={currNode}
        />
      ))}
    </TreeItem>
  )
}
