/**
 * Enum with the form fields names
 * from `delete files` / `delete file path` form.
 */
export enum DeleteFolderFilesFormEnum {
  /** The folder/file path. */
  FILE_PATH = 'filePath',
  /** The array of file IDs. */
  FILE_IDS = 'fileIds',
}
