import { useMutation } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { PayloadSelectGenerationStrategies } from '~/services/Development.types'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to select generation strategies.
 */
export const useSelectGenerationStrategies = () => {
  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationKey: [QueryKeysEnum.LOAD_GIT_REPOSITORY],
    mutationFn: (fieldValues: FieldValues) => {
      const { aggregateId, generationStrategyIds = [] } = fieldValues

      const url = DevelopmentAggregateAPI.SelectGenerationStrategies

      const postData: PayloadSelectGenerationStrategies = {
        aggregateId,
        generationStrategyIds,
      }

      return serviceDevelopmentAggregate.post(url, postData)
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
