import { AxiosResponse } from 'axios'
import invariant from 'tiny-invariant'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import {
  ResponseListGenerationStrategies,
  ResponseListParametersByGenerationId,
} from '~/services/GenerationStrategy.types'

export const queryKeyStrategiesByOrganisationId = (organisationId?: GUID) => [
  'generation-strategy',
  'by-organisation-id',
  { organisationId },
]
export const queryStrategiesByOrganisationId = <
  T = ResponseListGenerationStrategies[],
>(
  organisationId?: GUID,
) => {
  invariant(organisationId, 'Organisation ID is required')

  return {
    queryKey: queryKeyStrategiesByOrganisationId(organisationId),
    queryFn: async () => {
      const url =
        GenerationStrategyAPI.listStrategiesByOrganisationId(organisationId)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const queryKeyStrategiesForPublish = (organisationId?: GUID) => [
  'generation-strategy',
  'by-organisation-id',
  { organisationId },
]
export const queryStrategiesForPublish = <
  T = ResponseListGenerationStrategies[],
>(
  organisationId?: GUID,
) => {
  invariant(organisationId, 'Organisation ID is required')

  return {
    queryKey: queryKeyStrategiesForPublish(organisationId),
    queryFn: async () => {
      const url = GenerationStrategyAPI.listStrategiesForPublish(organisationId)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const mutationConvertFileToTemplate = async (fileId: string) => {
  const url = GenerationStrategyAPI.ConvertFileToTemplate

  const postData = {
    fileId,
  }

  return serviceGenerationStrategy.post(url, postData)
}

export const mutationReduceFileContent = async (fileId: string) => {
  const url = GenerationStrategyAPI.ReduceFileContent

  const postData = {
    fileId,
  }

  return serviceGenerationStrategy.post(url, postData)
}

export const mutationGenerateBoilerplate = async (fileId: string) => {
  const url = GenerationStrategyAPI.GenerateBoilerplate

  const postData = {
    fileId,
  }

  return serviceGenerationStrategy.post(url, postData)
}

export const queryKeyStrategy = (id: GUID) => [
  QueryKeysEnum.GENERATION_STRATEGY_BY_ID,
  id,
]

export const queryKeyBindingParameters = (id: GUID) => [
  'generation-strategy',
  'binding-options',
  { id },
]
export const queryListBindingParameters = <
  T = ResponseListParametersByGenerationId[],
>(
  id?: GUID,
) => {
  invariant(id, 'Generation Strategy ID is required')

  return {
    queryKey: queryKeyBindingParameters(id),
    queryFn: async () => {
      const url = GenerationStrategyAPI.ListBindingParameters(id)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export async function renameFileOrFolder({
  generationStrategyId,
  currentPath,
  newName,
}: {
  generationStrategyId: string
  currentPath: string
  currentName: string
  newName: string
}) {
  // Split the path into segments and replace the last segment
  const pathSegments = currentPath.split('/')
  pathSegments[pathSegments.length - 1] = newName

  // Join the segments back together to form the new path
  const newPath = pathSegments.join('/')

  return await serviceGenerationStrategy.post(`/movefiles`, {
    generationStrategyId,
    fromPath: currentPath,
    toPath: newPath,
  })
}
