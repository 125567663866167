import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { transformToTree } from '~/components/treeView/ArcMapTreeView/utils/transformToTree'
import { useGetBoundedContextByPlatformId } from '~/hooks/api/developer/useGetBoundedContextByPlatformId'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'
import { getFullArcMapNode } from '../utils/getFullArcMapNode'

/**
 * Manages the default selected ArcMap item.
 */
export const useManageDefaultSelectedItem = () => {
  // States.
  const [defaultSelected, setDefaultSelected] = useState<
    SelectedArcMapNodeInfo | {}
  >({})

  // React Router Dom.
  const params = useParams()
  const { aggregateId, eventId } = params

  const defaultSelectedId = useMemo(
    () => eventId || aggregateId,
    [aggregateId, eventId],
  )

  // Fetch hook.
  const { data: boundedContextsData } = useGetBoundedContextByPlatformId({
    enabled: !!defaultSelectedId,
  })

  const arcMapTree = useMemo(
    () => transformToTree(boundedContextsData),
    [boundedContextsData],
  )

  // Set default selected node.
  useEffect(() => {
    if (!!defaultSelectedId && !!arcMapTree) {
      const data = getFullArcMapNode(defaultSelectedId, arcMapTree)
      if (!!data) setDefaultSelected(data)
    }
  }, [defaultSelectedId, arcMapTree])

  return { defaultSelected }
}
